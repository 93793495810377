<template>
<div id="slidingPuzzleContainer">
  <div id="puzzleContainer">
    <MinigameHeader @giveUp="giveUp()" ref="puzzleHeader"
    :language="this.language" id="puzzleHeader"/>
    <div id="boardContainer">
      <div class="puzzle">
        <img width="426" height="426"
          class="puzzle-image" src="../assets/images/puzzles/postit_426x426.png">
      </div>
    </div>
    <img id="boardCover" src="../assets/images/puzzles/postit_complete_426x426.png"/>
  </div>
  <div id="puzzleSizer"> </div>
</div>
</template>

<script>
import MinigameHeader from '@/components/MinigameHeader.vue';

/* eslint-disable-next-line */
const $ = window.$;

export default {
  name: 'SlidingPuzzle',
  components: {
    MinigameHeader,
  },
  data() {
    return {
      bool: false,
    };
  },
  props: {
    language: String,
  },
  mounted() {
    $('div.puzzle, p').puzzle(142);
    this.$refs.puzzleHeader.start();
    this.$refs.puzzleHeader.giveUpTime = 10;
    document.addEventListener('complete', this.completePuzzle);
  },
  methods: {
    getImageSrc() {
      const images = require.context('../assets/images/puzzles', false, /\.png$/);
      return images('./postIt.png');
    },
    completePuzzle() {
      const boardCover = document.getElementById('boardCover');
      boardCover.classList.add('showCover');
      this.$refs.puzzleHeader.stop();
      const elapsedTime = this.$refs.puzzleHeader.getTimeElapsed();
      let finalScore;
      if (elapsedTime < 60) {
        finalScore = 100;
      } else if (elapsedTime < 120) {
        finalScore = 50;
      } else {
        finalScore = 25;
      }
      setTimeout(() => this.$emit('completePuzzle', finalScore), 1200);
    },
    giveUp() {
      const boardCover = document.getElementById('boardCover');
      boardCover.classList.add('showCover');
      setTimeout(() => this.$emit('completePuzzle', 0), 1200);
    },
  },
};
</script>

<style scoped lang="scss">
  .puzzle {
    margin: auto;
    top: 37px;
    border: none !important;
  }

  #slidingPuzzleContainer {
    overflow-y: hidden;
    overflow-x: hidden;
  }
  #puzzleBoard {
    height: 400px;
    width: 400px;
    margin-left: 50px;
    margin-top: 50px;
  }
  #puzzleBoard:focus {
    outline:none;
  }
  #boardCover {
    width: 426px;
    height: 426px;
    margin-top: 37px;
    position: absolute;
    left: 50%;
    margin-left: -213px;
    visibility: hidden;
    background-color: white;
  }
  #boardContainer {
    border-radius: 10px;
    width: 500px;
    height: 500px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    margin-left: -250px;
    background-color: white;
  }
  .showCover {
    visibility: visible !important;
  }
  #puzzleHeader {
  width: 500px;
  margin: 0 auto;
  }
</style>
