<template>
<div :id="cardNumber" class="flip-card">
  <div class="flip-card-inner">
    <div class="flip-card-front" @click="flipCard()">
      <img class="cardFront" src="../assets/images/icons/green_button.png" />
    </div>
    <div class="flip-card-back">
      <img class="cardBack" :src="getCardImage(cardValue)"/>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'MemoryCard',
  props: {
    initialValue: Number,
    cardNumber: Number,
  },
  data() {
    return {
      cardValue: this.initialValue,
      isActive: true,
    };
  },
  methods: {
    flipCard() {
      if (this.$parent.introActive === true) {
        return;
      }
      const card = document.getElementById(this.cardNumber);
      card.classList.remove('un.flipping-card');
      card.classList.add('flipping-card');
      setTimeout(() => {
        card.classList.add('flipping-card-ie');
      }, 272);
      this.$parent.handleFlip(this.cardValue, this.cardNumber);
    },
    unflipCard() {
      const card = document.getElementById(this.cardNumber);
      card.classList.remove('flipping-card');
      card.classList.add('unflipping-card');
      setTimeout(() => {
        card.classList.remove('flipping-card-ie');
      }, 290);
      // this.$parent.handleUnflip(this.cardValue);
    },
    introFlip() {
      const card = document.getElementById(this.cardNumber);
      card.classList.remove('unflipping-card');
      card.classList.add('flipping-card');
      setTimeout(() => {
        card.classList.add('flipping-card-ie');
      }, 272);
      setTimeout(() => this.unflipCard(), 6800);
    },
    setComplete() {
      this.isActive = false;
      const card = document.getElementById(this.cardNumber);
      card.classList.add('fade-card');
    },
    getValue() {
      return this.cardValue;
    },
    setValue(val) {
      this.cardValue = val;
    },
    getCardImage(cardValue) {
      const images = require.context('../assets/images/cards', false, /\.png$/);
      return images(`./${cardValue}.png`);
    },
  },
};
</script>

<style scoped lang="scss">
.flip-card {
  background-color: transparent;
  width: 100px;
  height: 100px;
  perspective: 1000px;
}

.cardBack {
  width: 100px;
  height: 100px;
  border-radius: 8px;
}

.cardFront {
  width: 100px;
  height: 100px;
}

.fade-card {
  opacity: 0;
  transition: opacity 0.6s;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s ease-out;
  transform-style: preserve-3d;
}

// Flip animation
.flipping-card .flip-card-inner {
  transform: rotateY(180deg);
}

.flipping-card-ie .flip-card-inner {
  *::-ms-backdrop, .flip-card-front, .flip-card-back {
    backface-visibility: visible !important;
  }
}

// Unflip animation
.unflipping-card {
  transform: rotateY(0deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  color: black;
}

.flip-card-back {
  transform: rotateY(180deg);
  box-shadow: 1px 2px 2px #bbb;
}
</style>
