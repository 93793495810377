<template>
  <div class="clue-container">
    <div :class="needsRow ? 'desk' : 'content-container'">
      <div class="text">
        <div v-if="currentItem.title !== ''" class="title" v-html="getTitle"></div>
        <div v-if="repeated && !isMinigame && !isPostGame" class="description">
          {{getRepeated}}
        </div>
        <div class="description"
          v-for="(paragraph, index) in getParagraphs" :key="index" v-html="paragraph"
          :class="{'italics': item === 'postGame4'}">
        </div>
      </div>
      <div v-if="currentItem.image" class="image"
        :class="{'postBreaker': item === 'postBreaker',
        'large-image': largeImage,
        'large-image2': item === 'postGame4',
        'image-spacing' : needsSpacing
      }">
        <img :key="item" :src="require(`../assets/images/${getImage}.png`)" >
      </div>
    </div>

    <div v-if="item === 'drive'" @click="toConclusion" class="button">
      {{
        french ? 'Branchez la clé USB' :
        ( german ? 'USB-Laufwerk anschließen' : 'Plug in Thumb Drive' )
      }}
    </div>

    <div v-else-if="isMinigame" class="button minigame" @click="toMinigame">
      {{getMinigameText}}
    </div>

    <div v-else class="button minigame" @click="continueHandler">
      {{
        french ? 'Continuer' : ( german ? 'Weiter' : 'Continue' )
      }}
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'ClueComponent',
  props: {
    item: String,
    repeated: Boolean,
    language: String,
  },
  data() {
    return {
      username: 'margdiamond@gmail.com',
      password: '$hineBr!ghtLik3ADiam0nd',
      inputUser: null,
      inputPass: null,
      inputType: 'password',
      success: false,
      repeatedText: {
        english: 'This item has no further use to you in this case, but let’s observe again for reference.',
        french: 'Cet élément n’a plus d’utilité dans ce cas, mais observons-le à nouveau à titre de référence.',
        german: 'Dieser Gegenstand hat für dich in diesem Fall keinen weiteren Nutzen. Aber betrachten wir den Hinweis noch einmal.',
      },
      data: {
        repeated: {
          title: '',
          paragraphs: [
            'After examining this object again, you realize it has nothing left to offer you.',
          ],
          frenchParas: [
            'Cet élément n’a plus d’utilité dans ce cas, mais observons-le à nouveau à titre de référence.',
          ],
          germanParas: [
            'Nach erneuter Untersuchung stellst du fest, dass er dich nicht weiterbringt.',
          ],
        },
        error: {
          title: 'Not yet!',
          frenchTitle: 'Pas encore !',
          germanTitle: 'Noch nicht ganz!',
          paragraphs: [
            'You’ve got a good eye, but you’ll need to find another clue before you can access this one. Keep searching!',
          ],
          frenchParas: [
            'Vous avez le flair, mais il vous faudra trouver un autre indice avant de pouvoir accéder à celui-ci.  Continuez à chercher !',
          ],
          germanParas: [
            'Du hast ein gutes Auge, aber es fehlt dir noch eine Spur, damit du Zugriff erhältst. Suche weiter!',
          ],
        },
        roomEnd1: {
          game: 'simonSays',
          paragraphs: [
            'You found everything you can in this room, but you must keep moving quickly. Just like how Chromebooks start fast, they stay fast over time. Each Chromebook <b class="blue">updates automatically in the background</b> every six to eight weeks so that your device gets the latest software without interrupting you.',
            'Race against the clock and complete these speedy challenges at Chromebook speeds.',
          ],
          frenchParas: [
            'Vous avez trouvé tout ce que vous pouvez dans cette pièce, mais vous devez continuer à avancer rapidement. Tout comme les Chromebooks, qui démarrent rapidement et restent rapides au fil du temps. Chaque Chromebook <b class="blue">se met automatiquement à jour en arrière-plan</b> toutes les six à huit semaines afin que les derniers logiciels soient installés sur votre appareil sans vous interrompre.',
            'Faites la course contre la montre et relevez ces défis vitesse à la vitesse de Chromebook',
          ],
          germanParas: [
            'In diesem Zimmer gibt es nichts mehr zu finden, trotzdem musst du dich beeilen. Genauso wie Chromebooks – <b class="blue">sie fahren nicht nur schnell hoch, sie bleiben auch schnell. Jedes Chromebook wird automatisch im Hintergrund</b> aktualisiert – und zwar alle sechs bis acht Wochen. So wird die neueste Software auf dein Gerät geladen, ohne dass du es merkst.',
            'Nimm den Kampf gegen die Zeit auf und erledige alle Aufgaben in Chromebook-Geschwindigkeit.',
          ],
        },
        roomEnd2: {
          game: 'memoryMatch',
          paragraphs: [
            'It makes a lot of sense why Margaret bought a Chromebook. Before you continue, play this game to see how there’s a Chromebook for everyone.',
          ],
          frenchParas: [
            'C’est logique que Margaret ait acheté un Chromebook. Avant de continuer, jouez à ce jeu pour voir dans quelle mesure le Chromebook convient à tous.',
          ],
          germanParas: [
            'Es macht sehr viel Sinn, warum Margaret ein Chromebook gekauft hat. Bevor Sie fortfahren, spielen Sie dieses Spiel, um zu sehen, wie es für alle ein Chromebook gibt.',
          ],
        },
        landing1: {
          title: 'The Study',
          frenchTitle: 'L’enquête',
          germanTitle: 'Das Arbeitszimmer',
          paragraphs: [
            'You must search Margaret’s house to find the truth about the Red Diamond.',
            'Tap and drag any part of the screen to look around the room.',
            'Tap colored objects to discover various hints and clues.',
            'To get started <b class="blue">check the desk.</b>',
          ],
          frenchParas: [
            'Vous devez passer la maison de Margaret au peigne fin pour découvrir la vérité autour du Diamant Rouge.',
            'Touchez et faites glisser n’importe quelle partie de l’écran pour balayer la pièce des yeux.',
            'Touchez les objets de couleur pour découvrir divers indices.',
            'Pour commencer, fouillez le bureau.',
          ],
          germanParas: [
            'Du musst Margarets Haus durchsuchen, um die Wahrheit über den roten Diamanten herauszufinden.',
            'Tippe auf jede beliebige Stelle im Display und bewege deinen Finger, um dich im Zimmer umzusehen.',
            'Tippe auf farbige Objekte, um verschiedene Hinweise und Spuren zu entdecken.',
            'Am besten fängst du mit dem Schreibtisch an.',
          ],
        },
        landing2: {
          title: 'Living Room',
          frenchTitle: 'Le Salon',
          germanTitle: 'Wohnzimmer',
          paragraphs: [
            'You have Wi-Fi but you are still missing the Chromebook and Google Account details. As you search for those, maybe learning more about Margaret will help you down the road. Pay close attention to what you find.',
          ],
          frenchParas: [
            'Votre Wi-Fi est activé, mais il vous manque encore les informations de votre compte Chromebook et Google. Pendant que vous les recherchez, peut-être qu’en savoir plus sur Margaret vous mettra sur la bonne voie. Faites bien attention à ce que vous trouvez.',
          ],
          germanParas: [
            'Du hast jetzt WLAN, jetzt fehlen noch das Chromebook und die Daten für das Google-Konto. Vielleicht hilft es dir bei deiner Suche, etwas mehr über Margaret zu erfahren. Achte sorgfältig darauf, was du findest.',
          ],
        },
        landing3: {
          title: 'The Cellar',
          frenchTitle: 'La Cave',
          germanTitle: 'Der Keller',
          paragraphs: [
            'You now have Wi-Fi and Margaret’s Google Account details. The last thing you need is the Chromebook to access her files and find the truth.',
          ],
          frenchParas: [
            'Vous avez maintenant le Wi-Fi et les informations du compte Google de Margaret. La dernière chose dont vous avez besoin est le Chromebook pour accéder à ses fichiers et faire toute la lumière et résoudre l’énigme.',
          ],
          germanParas: [
            'Du hast jetzt also eine aktive WLAN-Verbindung und die Daten für Margarets Google-Konto gefunden. Jetzt fehlt dir nur noch das Chromebook, um auf ihre Dateien zuzugreifen und die Wahrheit herauszufinden.',
          ],
        },
        postGame1: {
          title: '',
          paragraphs: [
            'Success! That was Chromebook fast.',
            'Click <b>Continue</b> to start your search in the Living Room',
          ],
          frenchParas: [
            'Très bien ! C’était aussi rapide qu’un Chromebook.',
            'Cliquez sur <b>Continuer</b> pour commencer votre enquête dans le salon.',
          ],
          germanParas: [
            'Erledigt! Das nenne ich Chromebook-Geschwindigkeit.',
            'Klicke auf <b>Weiter</b>, um jetzt das Wohnzimmer zu durchsuchen.',
          ],
        },
        postGame2: {
          title: '<span class="red">Sticky Note</span>',
          frenchTitle: '<span class="red">Notes</span>',
          germanTitle: '<span class="red">Haftnotiz</span>',
          paragraphs: [
            'You found Margaret’s Google Account details! Now, all you are missing is her Chromebook.',
          ],
          frenchParas: [
            'Vous avez trouvé les informations du compte Google de Margaret. Maintenant, il ne vous reste plus qu’à trouver son Chromebook.',
          ],
          germanParas: [
            'Du hast die Daten für Margarets Google-Konto gefunden! Jetzt fehlt nur noch das Chromebook.',
          ],
          image: 'clue_completed_note',
        },
        postGame3: {
          title: '',
          paragraphs: [
            'It’s a match! Customers can even enjoy four additional benefits when they upgrade to premium: <b class="green">Intel© Core™ Processing, increased memory, larger, faster storage,</b> and <b class="green">fit and finish for anything.</b>',
          ],
          frenchParas: [
            'Bravo ! Les clients peuvent même profiter de quatre avantages supplémentaires lorsqu’ils passent à la version Premium : <b class="green">Processeur Intel© Core™, davantage de mémoire, stockage plus important et plus rapide, et une finition parfaite.</b>',
          ],
          germanParas: [
            'Das passt genau! Bei einem Upgrade auf ein Premium-Gerät profitieren Kunden von vier zusätzlichen Vorteilen: <b class="green">Intel© Core™-Prozessoren, mehr Arbeitsspeicher, größerer, schnellerer Speicher und Ausstattungen für jeden Bedarf.</b>',
          ],
        },
        postGame4: {
          title: '',
          paragraphs: [
            'How interesting! It appears that an authentication company determined the Red Diamond was forged, but Margaret tampered with the document* to make it seem as if the diamond is real. This is exactly the evidence you needed. Good work, Sherlock Chromes!',
            'But what about Molly? Did she know about the diamond? Is she an accomplice in the forgery?',
            'Check the thumb drive for the final clue.',
            '*Google services cannot be used for fraudulent activity. You must abide by the terms of service to use Google products and services.',
          ],
          frenchParas: [
            'Comme c’est intéressant ! Il semble qu’une société d’authentification ait déterminé que le diamant rouge était un faux, mais Margaret a falsifié le document* pour faire croire que le diamant était un vrai. C’est exactement la preuve dont vous aviez besoin. Bien joué, Sherlock Chromes !',
            'Mais qu’en est-il de Molly ? Était-elle au courant du diamant ? Est-elle complice de la falsification ?',
            '<b class="blue">Vérifiez la clé USB pour trouver le dernier indice.</b>',
            '*Sherlock Chromes est un jeu de société. N’essayez pas de le reproduire à la maison. Les services Google ne peuvent pas être utilisés pour réaliser des activités frauduleuses. Vous devez respecter les conditions d’utilisation pour utiliser les produits et services Google.',
          ],
          germanParas: [
            'Interessant! Es sieht so aus, als hätte eine Sachverständigen Firma festgestellt, dass der rote Diamant gefälscht ist, aber Margaret das Dokument* manipuliert hat, um den Anschein zu erwecken, dass der Diamant echt ist. Das ist genau der Beweis, den du gebraucht hast. Gute Arbeit, Sherlock Chromes!',
            'Aber was ist mit Molly? Wusste sie von dem Diamanten? War sie an der Fälschung beteiligt?',
            '<b class="blue">Durchsuche das USB-Laufwerk nach dem letzten Hinweis.</b>',
            '* Google-Dienste dürfen nicht für betrügerische Aktivitäten genutzt werden. Die Bedingungen für die Nutzung von Google-Produkten und -Diensten müssen eingehalten werden.',
          ],
          image: 'checklist_6',
          frenchImage: 'checklist_6_french',
          germanImage: 'checklist_6_ger',
        },
        // ----------------- THE STUDY -------------------
        desk: {
          title: '<span class="blue">Desk</span>',
          frenchTitle: '<span class="blue">Bureau</span>',
          germanTitle: '<span class="blue">Schreibtisch</span>',
          paragraphs: [
            'The list indicates that Margaret has purchased a new Chromebook, backed up the files on her old computer, and is ready to switch over. Perhaps the truth about the Red Diamond can be found within her old files.',
            'Find Margaret’s <b class="blue">Chromebook</b>, follow the remaining steps on her checklist: ensure there is a <b class="blue">Wi-Fi connection</b>, and obtain her <b class="blue">Google Account details</b>, and finish <b class="blue">making the switch</b> to successfully access her files.',
          ],
          frenchParas: [
            'La liste indique que Margaret a acheté un nouveau Chromebook, qu’elle a sauvegardé les fichiers sur son ancien ordinateur et qu’elle est prête à passer à un autre. La vérité sur le diamant rouge se trouve peut-être dans ses anciens fichiers.',
            'Trouvez le <b class="blue">Chromebook</b> de Margaret, suivez les étapes restantes de sa liste de contrôle ; assurez-vous qu’une <b class="blue">connexion Wi-Fi</b> est disponible, obtenez les <b class="blue">informations de son compte Google</b>, et terminez <b class="blue">d’effectuer la transition</b> pour accéder à ses fichiers avec succès.',
          ],
          germanParas: [
            'Glaubt man der Liste, hat Margaret ein neues Chromebook gekauft, die Dateien auf ihrem alten Computer gesichert und ist bereit für den Umstieg. Vielleicht findet sich die Wahrheit über den roten Diamanten in ihren alten Dateien.',
            'Suche Margarets Chromebook und führe die verbleibenden Schritte auf ihrer Checkliste aus: sicherstellen, dass eine <b class="blue">WLAN-Verbindung vorhanden ist</b>, die Daten ihres <b class="blue">Google-Kontos besorgen und den Umstieg abschließen</b>, um auf ihre Dateien zugreifen zu können.',
          ],
          image: 'checklist_desk',
          frenchImage: 'checklist_desk_french',
          germanImage: 'checklist_desk_ger',
        },
        lamp: {
          title: '<span class="green">Lamp</span>',
          frenchTitle: '<span class="green">Lampe</span>',
          germanTitle: '<span class="green">Lampe</span>',
          paragraphs: [
            'After turning on the light, you discover Margaret Diamond’s Wi-Fi router, powering the internet in the whole house!',
            'Although Wi-Fi is required to set up a Chromebook, <b class="green">many Chromebook apps and functions can work offline</b>, so you can stay productive and entertained anywhere.',
          ],
          frenchParas: [
            'Après avoir allumé la lumière, vous découvrez le routeur Wi-Fi de Margaret Diamond, qui fournit l’Internet à toute la maison !',
            'Bien que le Wi-Fi soit nécessaire pour configurer un Chromebook, <b class="green">de nombreuses applications et fonctions de Chromebook peuvent fonctionner hors connexion</b>, ce qui vous permet de rester productif et de vous divertir partout.',
          ],
          germanParas: [
            'Du schaltest das Licht ein und entdeckst den WLAN-Router von Margaret, der im gesamten Haus für Internetzugang sorgt!',
            'Zum Einrichten <b class="green">des Chromebooks ist WLAN zwar erforderlich, aber viele Apps und Funktionen für Chromebooks funktionieren auch offline</b>, damit du überall arbeiten und Unterhaltung genießen kannst.',
          ],
          image: 'clue_router',
        },
        postLamp: {
          title: '',
          paragraphs: [
            'You have Wi-Fi, now you just need Margaret’s Google Account details and her Chromebook.',
          ],
          frenchParas: [
            'Vous avez le Wi-Fi, il ne vous reste plus qu’à obtenir les informations du compte Google de Margaret et son Chromebook.',
          ],
          germanParas: [
            'Du hast also WLAN, jetzt fehlen nur noch die Daten für Margarets Google-Konto und ihr Chromebook.',
          ],
          image: 'checklist_2',
          frenchImage: 'checklist_2_french',
          germanImage: 'checklist_2_ger',
        },
        clock: {
          title: '<span class="yellow">Clock</span>',
          frenchTitle: '<span class="yellow">Horloge</span>',
          germanTitle: '<span class="yellow">Uhr</span>',
          paragraphs: [
            'You quickly glance at the clock and see that there are only ten minutes left until sundown. You must hurry! Thankfully, once you find it, Chromebooks <b class="yellow">start as fast as six seconds!</b>',
          ],
          frenchParas: [
            'Vous regardez rapidement l’horloge et vous voyez qu’il ne reste que 10 minutes avant le coucher du soleil. Vous devez vous dépêcher! Heureusement, une fois que vous l’avez trouvé, le Chromebook <b class="yellow">démarre en six secondes !</b>',
          ],
          germanParas: [
            'Du wirfst einen kurzen Blick auf die Uhr und stellst fest, dass die Sonne in nur zehn Minuten untergeht. Du musst dich beeilen! Zum Glück fährt das Chromebook, sobald du es gefunden hast, innerhalb von <b class="yellow">unter 10 Sekunden hoch!</b>',
          ],
          image: 'clue_sunset',
        },
        safe: {
          title: '<span class="red">Safe</span>',
          frenchTitle: '<span class="red">Coffre-fort</span>',
          germanTitle: '<span class="red">Safe</span>',
          paragraphs: [
            'The safe is locked and not even the great Sherlock Chromes can bypass its security.',
            'Just like this safe, each Chromebook is secure, with <b class="red">built-in security protection</b> so you’re safe from the moment you open a new Chromebook.',
            'Chromebooks come with four layers of security: <b class="red">Verified Boot, Sandboxing, Automatic Updates, and Data Encryption.</b>',
          ],
          frenchParas: [
            'Le coffre-fort est verrouillé et même le grand Sherlock Chromes ne peut pas contourner sa sécurité.',
            'À l’image de ce coffre-fort, chaque Chromebook est sécurisé, avec une <b class="red">protection de sécurité intégrée,</b> de sorte que vous soyez en sécurité dès l’ouverture d’un nouveau Chromebook.',
            'Chromebook est fourni avec quatre niveaux de sécurité : <b class="red">le Verified Boot, le sandboxing, les mises à jour automatiques et le chiffrement des données</b>',
          ],
          germanParas: [
            'Der Safe ist sicher verschlossen und nicht einmal der große Sherlock Chromes kann ihn knacken.',
            'Genau wie dieser Safe ist jedes Chromebook dank integrierter <b class="red">Sicherheitsfunktionen gesichert,</b> damit du ab dem ersten Öffnen eines neuen Chromebooks geschützt bist.',
            'Chromebooks sind durch vier Sicherheitsebenen gesichert: <b class="red">Verifizierter Bootmodus, Sandboxing, Automatische Updates und Datenverschlüsselung.</b>',
          ],
          image: 'clue_lock',
        },
        // ----------------- THE LIVING ROOM -------------------
        note: {
          title: '<span class="red">Sticky Note</span>',
          frenchTitle: '<span class="red">Notes</span>',
          germanTitle: '<span class="red">Haftnotiz</span>',
          game: 'slidingPuzzle',
          paragraphs: [
            'Margaret’s notes are scattered everywhere, no wonder she got a new Chromebook. She can keep herself organized by using <b class="red">productivity apps</b> on the Google Play Store, like Evernote and Microsoft Office.',
            'But wait, there’s writing on this ripped note. Put the pieces back together to see what it says.',
          ],
          frenchParas: [
            'Les notes de Margaret sont éparpillées partout, pas étonnant qu’elle ait reçu un nouveau Chromebook. Elle peut s’organiser en utilisant les <b class="red">applications de productivité</b> du Google Play Store, comme Evernote et Microsoft Office.',
            'Mais attendez, il y a des écrits sur cette note déchirée. Recollez les morceaux pour voir ce qui y est écrit.',
          ],
          germanParas: [
            'Die Notizen von Margaret sind überall verteilt. Kein Wunder, dass sie sich ein neues Chromebook angeschafft hat. <b class="red">Die Produktivitäts-Apps</b> im Google Play Store, wie Evernote und Google Keep, helfen ihr den Überblick zu behalten.',
            'Moment mal, auf diesem zerrissenen Zettel steht etwas. Füge die Einzelteile wieder zusammen, um es zu lesen.',
          ],
          image: 'clue_ripped_note',
        },
        art: {
          title: '<span class="yellow">Artwork</span>',
          frenchTitle: '<span class="yellow">Œuvre d’art</span>',
          germanTitle: '<span class="yellow">Kunst</span>',
          paragraphs: [
            'A beautiful piece of art done by Margaret catches your eye. The Google Play store has her covered with <b class="yellow">creativity apps</b> like Infinite Painter, Canvas, and Adobe Lightroom.',
          ],
          frenchParas: [
            'Une belle œuvre d’art réalisée par Margaret attire votre attention. Le Google Play Store la comble <b class="yellow">d’applications de créativité</b> comme Infinite Painter, Canvas et Adobe Lightroom.',
          ],
          germanParas: [
            'Ein wunderschönes Kunstwerk von Margaret fällt dir ins Auge. Dafür findet sie im Google Play Store <b class="yellow">Kreativitäts-Apps</b> wie Infinite Painter, Canvas und Adobe Lightroom.',
          ],
          image: 'clue_signature',
        },
        speaker: {
          title: '<span class="blue">Speakers</span>',
          frenchTitle: '<span class="blue">Haut-parleur</span>',
          germanTitle: '<span class="blue">Lautsprecher</span>',
          paragraphs: [
            'Margaret has an extensive music collection. She can continue listening to her favorites wherever she goes with <b class="blue">music apps</b> like YouTube Music and Spotify. She doesn’t even need to worry about Wi-Fi because she can download songs to listen offline.',
          ],
          frenchParas: [
            'Margaret possède une vaste collection de musique. Elle peut continuer à écouter ses morceaux préférés partout où elle va grâce à des <b class="blue">applications musicales</b> comme YouTube Music et Spotify. Elle n’a même pas besoin de s’en faire pour le Wi-Fi puisqu’elle peut télécharger des chansons et les écouter hors ligne.',
          ],
          germanParas: [
            'Margaret hat eine umfangreiche Musiksammlung. Mit <b class="blue">Musik-Apps</b> wie YouTube Music und Spotify kann sie ihre Lieblingssongs überall hören. Sie muss sich noch nicht einmal Gedanken über das WLAN machen, da sie die Titel herunterladen und offline hören kann.',
          ],
          image: 'clue_records',
        },
        book: {
          title: '<span class="green">Book</span>',
          frenchTitle: '<span class="green">Livre</span>',
          germanTitle: '<span class="green">Buch</span>',
          paragraphs: [
            'It looks like Molly and Margaret play a lot of games together. With Margaret’s new Chromebook, they can turn their game nights virtual with the <b class="green">thousands of games</b> on the Google Play Store like Asphalt 8 or 9, PUBG, or classics like Sonic the Hedgehog.',
          ],
          frenchParas: [
            'Il semble que Molly et Margaret jouent à beaucoup de jeux ensemble. Avec le nouveau Chromebook de Margaret, elles peuvent rendre leurs soirées de jeux virtuelles; grâce aux <b class="green">milliers de jeux</b> disponibles sur Google Play Store comme Asphalt 8 ou 9, PUBG, ou des classiques comme Sonic the Hedgehog.',
          ],
          germanParas: [
            'Wie es aussieht, spielen Molly und Margaret häufig Spiele zusammen. Mit Margarets neuem Chromebook können sie ihre Spieleabende jetzt auch virtuell abhalten, dank <b class="green">Tausender Spiele</b> im Google Play Store, wie Asphalt 8 oder 9, PUBG oder Klassikern wie Sonic the Hedgehog.',
          ],
          image: 'clue_scorecard',
        },
        // ----------------- THE CELLAR -------------------
        barrel: {
          title: '<span class="red">Wine Barrel</span>',
          frenchTitle: '<span class="red">Tonneau de Vin</span>',
          germanTitle: '<span class="red">Weinfass</span>',
          paragraphs: [
            'This wine barrel is completely dry! But your luck is flowing.',
            'There in the empty barrel is the Chromebook. Wait a minute...the power just turned off! Pretty spooky.',
            'Quickly turn the lights back on, so you can finish up before Margaret gets home!',
          ],
          frenchParas: [
            'Ce tonneau de vin est complètement vide ! Mais la chance vous sourit.',
            'Dans le tonneau vide se trouve le Chromebook Attendez une minute... le courant vient d’être coupé ! Plutôt effrayant.',
            'Rallumez vite les lumières, pour pouvoir finir avant que Margaret ne rentre à la maison !',
          ],
          germanParas: [
            'Dieses Weinfass ist komplett trocken! Du hast Glück.',
            'Denn in dem leeren Fass steckt das Chromebook. Einen Augenblick ...der Strom ist eben ausgefallen! Ganz schön unheimlich.',
            'Schalte das Licht schnell wieder ein, damit du fertig bist, bevor Margaret nach Hause kommt!',
          ],
          image: 'clue_chromebook',
        },
        breaker: {
          title: '<span class="yellow">Breaker Box</span>',
          frenchTitle: '<span class="yellow">Boîte de Disjoncteurs</span>',
          germanTitle: '<span class="yellow">Sicherungskasten</span>',
          paragraphs: [
            'Phew! You’ve reset the power and the lights are back on!  Now you have Wi-Fi, the Google Account details, and the Chromebook but has Margaret finished setting it up yet? Can you even access her files?',
            'Keeping your data when switching to Chromebook is <b class="yellow">as easy as 1, 2, 3.</b>',
            '<b class="yellow">STEP 1:</b> Sign in to your Chrome browser on your old device.',
            '<b class="yellow">STEP 2:</b> Download Backup and Sync and sign in with a Google Account. Upload all files from the old device to be safely stored in Google Drive.',
            '<b class="yellow">STEP 3:</b> Sign in to your new Chromebook using the same Google Account',
            'Margaret has already backed up her files on her old computer using the Backup and Sync application. All you need to do is finish setting up the Chromebook and access her content on Google Drive.',
          ],
          frenchParas: [
            'Ouf ! Vous avez rétabli le courant et les lumières sont de nouveau allumées ! Maintenant, vous avez le Wi-Fi, les informations du compte Google et le Chromebook, mais Margaret a-t-elle déjà fini de le configurer ? Pouvez-vous accéder à ses fichiers ?',
            'Conservez vos données lorsque vous <b class="yellow">passez à Chromebook est aussi simple que bonjour.</b>',
            '<b class="yellow">ÉTAPE 1 :</b> Connectez-vous au navigateur Chrome sur votre ancien appareil.',
            '<b class="yellow">ÉTAPE 2 :</b> Téléchargez Sauvegarde et synchronisation, puis connectez-vous avec un compte Google. Importez tous les fichiers de l’ancien appareil pour les stocker en toute sécurité dans Google Drive.',
            '<b class="yellow">ÉTAPE 3 :</b> Connectez-vous à votre nouveau Chromebook en utilisant le même compte Google.',
            'Margaret a déjà sauvegardé ses fichiers sur son ancien ordinateur à l’aide de l’application Sauvegarde et synchronisation. Il vous suffit de terminer la configuration du Chromebook et d’accéder à son contenu sur Google Drive',
          ],
          germanParas: [
            'Puh! Der Strom ist wieder da und die Lichter brennen wieder! Jetzt hast du eine aktive WLAN-Verbindung, die Daten für das Google-Konto und das Chromebook – aber ist Margaret mit dem Einrichten schon fertig? Und kannst du überhaupt auf ihre Dateien zugreifen?',
            'Es ist superleicht, deine Daten beim Umzug auf <b class="yellow">dein Chromebook mitzunehmen.</b>',
            '<b class="yellow">SCHRITT 2</b> Lade Backup and Sync herunter und melde dich mit einem Google-Konto an. Lade alle Dateien von dem alten Gerät zur sicheren Speicherung in Google Drive hoch.',
            `<b class="yellow">SCHRITT 3</b> Melde dich mit demselben Google-Konto bei deinem neuen Chromebook an.
              Margaret hat ihre Dateien auf dem alten Computer bereits mit der App Backup and Sync gesichert. Du musst das Setup des Chromebooks nur noch beenden und auf ihre Inhalte auf Google Drive zugreifen.`, 'Das war ja ganz einfach! Sieht aus, als hätte Margaret ihr Chromebook mit den Daten für ihr Google-Konto bereits eingerichtet. Melde dich mit dem Passwort auf der Haftnotiz an.',
          ],
        },
        postBreaker: {
          title: '',
          game: 'docDetective',
          paragraphs: [
            'Well, that was easy! Looks like Margaret has already set up her Chromebook using her Google Account details. Log in using the password on the sticky note.',
          ],
          frenchParas: [
            'Eh bien, c’était facile ! Il semble que Margaret ait déjà configuré son Chromebook en utilisant les informations de son compte Google. Connectez-vous à l’aide du mot de passe figurant sur la note adhésive.',
          ],
          germanParas: [
            'Das war ja ganz einfach! Sieht aus, als hätte Margaret ihr Chromebook mit den Daten für ihr Google-Konto bereits eingerichtet. Melde dich mit dem Passwort auf der Haftnotiz an.',
          ],
          image: 'clue_chromebook_login2',
        },
        drive: {
          title: '<span class="blue">Thumb Drive</span>',
          frenchTitle: '<span class="blue">Clé USB</span>',
          germanTitle: '<span class="blue">USB-Laufwerk</span>',
          paragraphs: [
            'All of the answers are stored right here in the cellar.',
            'In addition to the local storage on your Chromebook, <b class="blue">all Google Accounts come with 15 GB of free Google Drive storage.</b> And, when you purchase a new Chromebook, you get <b class="blue">12 months of Google One with 100 GB of storage</b> and other exclusive benefits.',
            'But that’s not all! You can also use external hard drives to store additional files. Plug in the thumb drive to see...',
          ],
          frenchParas: [
            'Toutes les réponses sont conservées ici, dans la cave.',
            'En plus du stockage local sur votre Chromebook, <b class="blue">tous les comptes Google offrent 15 Go de stockage gratuit</b> sur Google Drive. Et, lorsque vous achetez un nouveau Chromebook, vous bénéficiez de 12 mois de <b class="blue">Google One avec 100 Go de stockage</b> et d’autres avantages exclusifs.',
            'Mais ce n’est pas tout ! Vous pouvez également utiliser des disques durs externes pour stocker des fichiers supplémentaires. Branchez la clé USB pour voir...',
          ],
          germanParas: [
            'Alle Antworten befinden sich genau hier im Keller.',
            'Zusätzlich zum lokalen Speicher auf dem Chromebook bietet <b class="blue">jedes Google-Konto 15 GB kostenlosen Speicherplatz</b> auf Google Drive. Außerdem erhältst du aktuell beim Kauf eines neuen Chromebooks 12 Monate <b class="blue">Google One mit 100 GB Speicherplatz</b> und weitere exklusive Vorteile.',
            'Das ist aber noch nicht alles! Du kannst darüber hinaus weitere Dateien auf externen Festplatten speichern. Schließe das USB-Laufwerk an und überzeuge dich selbst.',
          ],
          image: 'clue_chromebook_drive',
        },
      },
    };
  },
  methods: {
    continueHandler() {
      if (this.isEndScreen) {
        this.$emit('toNextRoom', this.item);
      } else {
        this.$emit('closeLanding');
      }
    },
    toConclusion() {
      this.$emit('toConclusion');
    },
    toMinigame() {
      this.$emit('toMinigame', this.currentItem.game);
    },
    fillPass() {
      Vue.set(this, 'inputUser', this.username);
      Vue.set(this, 'inputPass', this.password);
      Vue.set(this, 'success', true);
    },
    togglePass() {
      if (this.inputType === 'password') {
        Vue.set(this, 'inputType', 'text');
      } else {
        Vue.set(this, 'inputType', 'password');
      }
    },
  },
  computed: {
    getMinigameText() {
      if (this.french) return (this.item === 'postBreaker' ? 'Connexion' : 'Démarrer le jeu');
      if (this.german) return (this.item === 'postBreaker' ? 'Anmelden' : 'Spiel starten');
      return (this.item === 'postBreaker' ? 'Login' : 'Start game');
    },

    getRepeated() {
      if (this.french) return this.repeatedText.french;
      if (this.german) return this.repeatedText.german;
      return this.repeatedText.english;
    },

    getTitle() {
      if (this.french) return this.currentItem.frenchTitle;
      if (this.german) return this.currentItem.germanTitle;
      return this.currentItem.title;
    },

    getParagraphs() {
      if (this.french) return this.currentItem.frenchParas;
      if (this.german) return this.currentItem.germanParas;
      return this.currentItem.paragraphs;
    },

    getImage() {
      if (this.french && this.currentItem.frenchImage) {
        return this.currentItem.frenchImage;
      }
      if (this.german && this.currentItem.germanImage) {
        return this.currentItem.germanImage;
      }
      return this.currentItem.image;
    },

    french() {
      return (this.language === 'french');
    },

    german() {
      return (this.language === 'german');
    },

    currentItem() {
      return this.data[this.item];
    },

    largeImage() {
      switch (this.item) {
        case 'postLamp':
        case 'postGame2':
          return true;
        default:
          return false;
      }
    },

    needsSpacing() {
      switch (this.item) {
        case 'drive':
        case 'art':
        case 'book':
        case 'clock':
        case 'postGame4':
          return true;
        default:
          return false;
      }
    },

    needsRow() {
      return (this.item === 'desk');
    },

    isEndScreen() {
      switch (this.item) {
        case 'postGame1':
        case 'postGame3':
          return true;
        default:
          return false;
      }
    },

    isPostGame() {
      switch (this.item) {
        case 'postGame1':
        case 'postGame2':
        case 'postGame3':
        case 'postGame4':
          return true;
        default:
          return false;
      }
    },

    isMinigame() {
      switch (this.item) {
        case 'note':
        case 'postBreaker':
        case 'roomEnd1':
        case 'roomEnd2':
          return true;
        default:
          return false;
      }
    },
  },
};
</script>

<style lang="scss">
  .blue {
    color: #4885ED;
  }
  .yellow {
    color: #F4C20D;
  }
  .red {
    color: #DB3236;
  }
  .green {
    color: #3CBA54;
  }

  .italics {
    line-height: 21px !important;
  }

  .italics:last-child  {
    font-size: 12px !important;
    font-style: italic;
    line-height: 17px !important;
  }
</style>

<style scoped lang="scss">
.clue-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 13px #0000000D;
  border: 1px solid #9A9FA44D;
  opacity: 1;
  padding: 20px;

  .text {
    width: 400px;
  }

  .router {
    width: 130px;
  }

  .image-spacing {
    margin-top: 15px;
  }

  .postBreaker {
    width: 350px !important;
    height: 235px !important;
  }

  .desk {
    display: flex;
    flex-direction: row !important;
    width: 100%;
    height: 100%;
    .description {
      max-width: 370px;
    }
    .image {
      align-self: center;
      width: 280px;
      height: 362px;
      img {
        max-width: 100%;
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    .image {
      width: 250px;
      height: 200px;
      align-self: center;
      img {
        position: relative;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .large-image {
      width: 330px !important;
      height: 300px !important;
    }

    .large-image2 {
      width: 297px !important;
      height: 258px !important;
    }
  }

  .button {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    opacity: 1;
    letter-spacing: 0px;
    background-color: #757575;
    color: white;
    padding: 0 10px;
    margin-top: 0px;
    padding: 0 20px;
    cursor: pointer;
  }

  .button:hover {
    background: #4885ED 0% 0% no-repeat padding-box;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    align-self: center;
    padding-top: 15px;
    width: 80%;

    .pass-icon {
      width: 25px;
      position: absolute;
      bottom: 109px;
      right: 71px;
    }

    input {
      border: 2px solid #9A9FA44D;
      font-size: 14px;
      margin: 4px 0;
      border-radius: 8px;
      background: white;
      height: 39px;
      padding: 0 0 0 16px;
      letter-spacing: 0px;
      color: #757575;
      outline: none;
    }
  }

  .completion {
    padding-top: 15px;
    width: 100%;
    height: 52px;
    .button {
      position: relative;
      transform: translate(-50%, 0);
      left: 50%;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      opacity: 1;
      width: 98px;
      letter-spacing: 0px;
      background: #757575 0% 0% no-repeat padding-box;
      color: #FFFFFF;
    }
  }

  .title {
    text-align: left;
    align-self: flex-start;
    font: bold 32px 'Google Sans';
    color: #757575;
    letter-spacing: -0.48px;
    opacity: 1;
    padding-bottom: 22px;
  }

  .description {
    color: #757575;
    text-align: left;
    font: 16px 'Google Sans';
    letter-spacing: 0px;
    color: #757575;
    opacity: 1;
    line-height: 18px
  }

  .description:not(:last-child) {
    margin-bottom: 5px;
  }
}
</style>
