<template>
    <div id="headerContainer">
        <div id="chromebookHeader">
        </div>
        <div id="minigameArea">
          <div id="giveUpContainer">
            <div v-if="!disableGiveUp && showTimer && elapsedTime / 1000 > giveUpTime"
            @click="giveUp()" id="giveUpButton">
              {{ isFrench() ? "Abandonner" :
              ( isGerman() ? "Gib auf!" : "Give up" )}}
            </div>
          </div>
          <div v-if="showTimer && !hideElapsedTime" id="minigameTimer">
            {{
              isFrench() ? "Temps écoulé" : ( isGerman() ? "Benötigte Zeit" : "Time elapsed:" )
            }}
            {{elapsedTime / 1000}}
            {{
              isFrench() ? "secondes" : ( isGerman() ? "Sekunden" : "Seconds" )
            }}
          </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'MinigameHeader',
  data() {
    return {
      remainingTime: 0,
      timer: undefined,
      showTimer: false,
      elapsedTime: 0,
      hideElapsedTime: false,
      disableGiveUp: false,
      giveUpTime: 90,
    };
  },
  props: {
    language: String,
  },
  methods: {
    start() {
      this.showTimer = true;
      this.timer = setInterval(() => {
        this.elapsedTime += 1000;
      }, 1000);
    },
    stop() {
      clearInterval(this.timer);
    },
    reset() {
      this.elapsedTime = 0;
    },
    getTimeElapsed() {
      return this.elapsedTime / 1000;
    },
    giveUp() {
      this.$emit('giveUp');
    },
    isFrench() {
      return (this.language === 'french');
    },
    isGerman() {
      return (this.language === 'german');
    },
  },
};
</script>
<style scoped>
 #formattedElapsedTime {
  margin: 0px;
 }

 #minigameArea {
   width: 100%;
   height: 60px;
   margin: 0 auto;
   display: flex;
   -webkit-box-pack: justify;
   -webkit-justify-content: space-between;
   -ms-flex-pack: justify;
   justify-content: space-between;
 }
 #minigameTimer {
   color: #FFFFFF;
   display: block;
   width: 225px;
   line-height: 40px;
   position: relative;
   top: 8px;
 }
 #giveUpContainer {
   height: 40px;
   width: 110px;
   margin-top: 15px;
   margin-bottom: 15px;
 }
 #giveUpButton {
   background-color: grey;
   color: #FFFFFF;
   width: 110px;
   height: 40px;
   line-height: 40px;
   text-align: center;
   border-radius: 5px;
   display: block;
   position: relative;
   top: -5px;
 }
 #giveUpButton:hover {
  background: #4885ED 0% 0% no-repeat padding-box;;
  cursor: pointer;
}
</style>
