<template>
<div id="simonGameContainer">
  <MinigameHeader ref="simonHeader" id="simonHeader"
  :language="this.language" @giveUp="giveUp()"/>
  <SimonTimer ref="simonTimer" id="simonTimer"
  :language="this.language" @timeUp="timeUp()"/>
  <div id="simonSaysContainer">
    <div v-if="currentType === 'Identify'" class="simonGameContainer">
        <h2 class="currentTypeTitle">
          {{ isFrench() ? "Identifiez !" :
          (isGerman() ? "Richtig auswählen!" : "Identify!")}}
        </h2>
        <div class="simonInstruction">
          {{ isFrench() ? "Choisissez le nom approprié pour l’application Google !"
          : (isGerman() ? "Wähle den richtigen Namen der Google App!"
          : "Select the correct name for the Google app!")}}
        </div>
        <div id="identifyFade">
          <img id="identifyImage" ref="identifyImage"
          :src="getImageUrl(this.identifyGameRound.file)"/>
          <div id="identifyButtonContainer" v-if="isGerman()">
            <div class="identifyBtn"
            @click="checkIdentifiedGer(identifyButtonsGer[0].toLowerCase())">
            {{identifyButtonsGer[0]}}</div>
            <div class="identifyBtn"
            @click="checkIdentifiedGer(identifyButtonsGer[1].toLowerCase())">
            {{identifyButtonsGer[1]}}</div>
            <div class="identifyBtn"
            @click="checkIdentifiedGer(identifyButtonsGer[2].toLowerCase())">
            {{identifyButtonsGer[2]}}</div>
            <div class="identifyBtn"
            @click="checkIdentifiedGer(identifyButtonsGer[3].toLowerCase())">
            {{identifyButtonsGer[3]}}</div>
          </div>
          <div id="identifyButtonContainer" v-else>
            <div class="identifyBtn"
            @click="checkIdentified(identifyButtons[0].toLowerCase())">
            {{identifyButtons[0]}}</div>
            <div class="identifyBtn"
            @click="checkIdentified(identifyButtons[1].toLowerCase())">
            {{identifyButtons[1]}}</div>
            <div class="identifyBtn"
            @click="checkIdentified(identifyButtons[2].toLowerCase())">
            {{identifyButtons[2]}}</div>
            <div class="identifyBtn"
            @click="checkIdentified(identifyButtons[3].toLowerCase())">
            {{identifyButtons[3]}}</div>
          </div>
        </div>
    </div>
    <div v-if="currentType === 'Upload'" class="simonGameContainer">
      <h2 class="currentTypeTitle">
        {{ isFrench() ? "Chargez !" :
        (isGerman() ? "Richtig auswählen!" : "Upload!")}}
      </h2>
      <div class="simonInstruction">
          {{ isFrench() ?
          "Faites glisser et déposez les fichiers qui se trouvent sur Google Drive !"
          : (isGerman() ?
          "Wähle die Dateien, die auf Google Drive sind, und ziehe diese in das Feld!"
          : "Drag and drop the files that live on Google Drive!")}}
      </div>
      <div class="uploadItemContainer">
        <div class="uploadItemCol">
          <div class="imageContainer" v-if="isGerman()">
            <img class="draggableItemGer draggableStatic" @mouseup="elementReleased"
            v-draggable="draggableValue" v-if="uploadIcons.slides" id="slides"
            src="../assets/images/simonAppsLabeled/ger_slides.png"/>
          </div>
          <div class="imageContainer" v-else>
            <img class="draggableItem draggableStatic" @mouseup="elementReleased"
            v-draggable="draggableValue" v-if="uploadIcons.slides" id="slides"
            src="../assets/images/simonAppsLabeled/slides.png"/>
          </div>
          <div class="imageContainer" v-if="isGerman()">
            <img class="draggableItem draggableStatic" @mouseup="elementReleased"
            v-draggable="draggableValue" v-if="uploadIcons.sheets" id="sheets"
            src="../assets/images/simonAppsLabeled/ger_sheets.png"/>
          </div>
          <div class="imageContainer" v-else>
            <img class="draggableItem draggableStatic" @mouseup="elementReleased"
            v-draggable="draggableValue" v-if="uploadIcons.sheets" id="sheets"
            src="../assets/images/simonAppsLabeled/sheets.png"/>
          </div>
          <div class="imageContainer" v-if="isGerman()">
            <img class="draggableItem draggableStatic" @mouseup="elementReleased"
            v-draggable="draggableValue" v-if="uploadIcons.photo" id="photo"
            src="../assets/images/simonAppsLabeled/ger_photos.png"/>
          </div>
          <div class="imageContainer" v-else>
            <img class="draggableItem draggableStatic" @mouseup="elementReleased"
            v-draggable="draggableValue" v-if="uploadIcons.photo" id="photo"
            src="../assets/images/simonAppsLabeled/google_photos.png"/>
          </div>
        </div>

        <img id="dragGoal" src="../assets/images/drivelogo_transparent.png" ref='dragGoal'/>
        <img id="dragGoalOverlay" class="dragScore0" src="../assets/images/drivelogo.png"/>

        <div class="uploadItemCol">
          <div class="imageContainer">
            <img class="draggableItem draggableStatic" @mouseup="elementReleased"
            v-draggable="draggableValue" v-if="uploadIcons.docs" id="docs"
            src="../assets/images/simonAppsLabeled/docs.png"/>
          </div>
          <div class="imageContainer">
            <img class="draggableItem draggableStatic" @mouseup="elementReleased"
            v-draggable="draggableValue" v-if="uploadIcons.forms" id="forms"
            src="../assets/images/simonAppsLabeled/forms.png"/>
          </div>
        </div>
      </div>
    </div>
    <div v-if="currentType === 'Share'" class="simonGameContainer">
        <h2 class="currentTypeTitle">
          {{isFrench() ? "Partagez !" :
          (isGerman() ? "Richtig auswählen!" : "Share!")}}
        </h2>
        <div class="simonInstruction">
          {{isFrench() ? "Faites glisser l’application la plus utile à l’utilisateur !"
          : (isGerman() ?
          "Wähle die nützlichste App für den Nutzer!"
           :
           `${isIE11 ? 'Select' : 'Drag'} the most useful app ${isIE11 ? 'for' : 'to'} the user!`)}}
        </div>
      <div id="shareContainer">
        <div class="shareItemCol">
          <div class="imageContainer" v-if="isGerman()">
            <img @click="shareGameIE11Click($event, 'sheets')"
            class="draggableItemShare draggableStatic"
            @mouseup="shareElementReleased" ref="sheets"
            v-draggable="draggableValueShare" v-if="shareIcons.sheets"
            src="../assets/images/simonAppsLabeled/ger_sheets.png"/>
          </div>
          <div class="imageContainer" v-else>
            <img @click="shareGameIE11Click($event, 'sheets')"
            class="draggableItemShare draggableStatic"
            @mouseup="shareElementReleased" ref="sheets"
            v-draggable="draggableValueShare" v-if="shareIcons.sheets"
            src="../assets/images/simonAppsLabeled/sheets.png"/>
          </div>
          <div class="imageContainer">
            <img @click="shareGameIE11Click($event, 'forms')"
            class="draggableItemShare draggableStatic"
            @mouseup="shareElementReleased" ref="forms"
            v-draggable="draggableValueShare" v-if="shareIcons.forms"
            src="../assets/images/simonAppsLabeled/forms.png"/>
          </div>
          <div class="imageContainer">
            <img @click="shareGameIE11Click($event, 'docs')"
            class="draggableItemShare draggableStatic"
            @mouseup="shareElementReleased" ref="docs"
            v-draggable="draggableValueShare" v-if="shareIcons.docs"
            src="../assets/images/simonAppsLabeled/docs.png"/>
          </div>
          <div @click="shareGameIE11Click($event, 'slides')"
            class="imageContainer" v-if="isGerman()">
            <img class="draggableItemShareGer draggableStatic"
            @mouseup="shareElementReleased" ref="slides"
            v-draggable="draggableValueShare" v-if="shareIcons.slides"
            src="../assets/images/simonAppsLabeled/ger_slides.png"/>
          </div>
          <div @click="shareGameIE11Click($event, 'slides')"
            class="imageContainer" v-else>
            <img class="draggableItemShare draggableStatic"
            @mouseup="shareElementReleased" ref="slides"
            v-draggable="draggableValueShare" v-if="shareIcons.slides"
            src="../assets/images/simonAppsLabeled/slides.png"/>
          </div>
        </div>
        <div id="shareUser">
          <span id="shareText">
            {{isFrench() ? shareGameRound.frenchText :
            (isGerman() ? shareGameRound.germanText : shareGameRound.text)}}
          </span>
          <Girl ref="shareDragGoal" id="girl"/>
        </div>
      </div>
    </div>
    <div id="incorrectCorrect" v-if="showCorrectIcon || showIncorrectIcon">
      <div id="incorrectCorrectPane"></div>
      <img id="simonCorrectIcon" v-if="showCorrectIcon && !showIncorrectIcon"
      src="../assets/images/icons/answer_right.png"/>

      <img id="simonIncorrectIcon" v-if="showIncorrectIcon && !showCorrectIcon"
      src="../assets/images/icons/answer_wrong.png"/>
    </div>
    <img class="preloaded" src="../assets/images/puzzles/slidesIdentify.png"/>
    <img class="preloaded" src="../assets/images/puzzles/sheetsIdentify.png"/>
    <img class="preloaded" src="../assets/images/puzzles/formsIdentify.png"/>
    <img class="preloaded" src="../assets/images/puzzles/docsIdentify.png"/>
  </div>
</div>
</template>

<script>
import { Draggable } from 'draggable-vue-directive';
import MinigameHeader from '@/components/MinigameHeader.vue';
import SimonTimer from '@/components/SimonTimer.vue';
import Girl from '@/assets/images/girl.svg';
import simonData from '../assets/data/simon.json';

export default {
  name: 'SimonSays',
  components: {
    MinigameHeader,
    SimonTimer,
    Girl,
  },
  props: {
    language: String,
  },
  directives: {
    Draggable,
  },
  data() {
    return {
      draggableValue: {
        onDragEnd: this.onUploadDragFinish,
        onDragStart: this.onUploadDragStart,
        stopDragging: false,
        resetInitialPos: false,
        forceFallback: true,
      },
      draggableValueShare: {
        onDragEnd: this.onShareDragFinish,
        onDragStart: this.onShareDragStart,
        stopDragging: false,
        resetInitialPos: false,
        forceFallback: true,
      },
      currentType: '',
      currentGameData: {},
      shareGameRound: {},
      identifyButtons: [
        'Slides',
        'Docs',
        'Forms',
        'Sheets',
      ],
      identifyButtonsGer: [
        'Präsentationen',
        'Docs',
        'Forms',
        'Tabellen',
      ],
      uploadIcons: {
        slides: true,
        sheets: true,
        docs: true,
        forms: true,
        photo: true,
      },
      shareIcons: {
        sheets: true,
        slides: true,
        docs: true,
        forms: true,
      },
      score: 0,
      mistakes: 0,
      completedTasks: 0,
      finalScore: -1,
      showCorrectIcon: false,
      showIncorrectIcon: false,
      gameTypeId: 0,
      shareRoundsCompleted: [],
      identifyRoundIndex: 0,
      preloadList: [
        'slidesIdentify.png',
        'sheetsIdentify.png',
        'formsIdentify.png',
        'docsIdentify.png',
      ],
    };
  },
  mounted() {
    window.addEventListener('resize', this.rerenderGame);
    this.$refs.simonHeader.start();
    this.$refs.simonHeader.hideElapsedTime = true;
    this.chooseNewType();
  },
  computed: {
    isIE11() {
      const ua = navigator.userAgent;
      const isIe = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
      return isIe;
    },
  },
  methods: {
    chooseNewType(type = -1) {
      if (this.completedTasks >= 3) {
        this.completeSimonSays();
        return;
      }
      if (type === -1) {
        this.gameTypeId = this.gameTypeId + 1;
        if (this.gameTypeId >= 3) {
          this.gameTypeId = 0;
        }
      } else {
        this.gameTypeId = type;
      }
      if (this.currentType === Object.keys(simonData)[this.gameTypeId]) {
        this.chooseNewType();
        return;
      }
      this.currentType = Object.keys(simonData)[this.gameTypeId];
      this.currentGameData = Object.values(simonData)[this.gameTypeId];
      this.draggableValueShare.resetInitialPos = true;
      this.draggableValue.resetInitialPos = true;
      this.score = 0;
      switch (this.currentType) {
        case 'Identify':
          this.loadIdentifyGame();
          break;
        case 'Upload':
          this.loadUploadGame();
          break;
        case 'Share':
          this.loadShareGame();
          break;
        default:
          break;
      }
      setTimeout(() => this.resetInitialPosValue(), 300);
    },
    loadGameTransition() {
      this.$refs.simonTimer.reset();
      this.score = 0;
      const simonContainers = document.getElementsByClassName('simonGameContainer');
      simonContainers.forEach((container) => {
        setTimeout(() => this.slideIn(), 800);
        container.classList.add('slideOutTransition');
        container.classList.add('opacity0');
      });
      setTimeout(() => this.chooseNewType(), 500);
    },
    loadIdentifyGameTransition() {
      const identifyImage = document.getElementById('identifyFade');
      setTimeout(() => this.slideInIdentify(), 500);
      identifyImage.classList.add('slideOutTransitionIdentify');
      identifyImage.classList.add('opacity0');
      this.$refs.identifyImage.src = 'about:blank';
      setTimeout(() => {
        this.$refs.identifyImage.src = this.getImageUrl(this.identifyGameRound.file);
      }, 0);
      setTimeout(() => this.shuffleIdentifyButtons(), 390);
      setTimeout(() => this.loadIdentifyGame(), 390);
    },
    loadIdentifyGameTransitionGer() {
      const identifyImage = document.getElementById('identifyFade');
      setTimeout(() => this.slideInIdentify(), 500);
      identifyImage.classList.add('slideOutTransitionIdentify');
      identifyImage.classList.add('opacity0');
      this.$refs.identifyImage.src = 'about:blank';
      setTimeout(() => {
        this.$refs.identifyImage.src = this.getImageUrl(this.identifyGameRound.file);
      }, 0);
      setTimeout(() => this.shuffleIdentifyButtonsGer(), 390);
      setTimeout(() => this.loadIdentifyGame(), 390);
    },
    slideInIdentify() {
      const identifyImage = document.getElementById('identifyFade');
      identifyImage.classList.remove('slideOutTransitionIdentify');
      identifyImage.classList.add('slideInTransitionIdentify');
      setTimeout(() => this.resetSlideTransitionIdentify(), 600);
    },
    resetSlideTransitionIdentify() {
      const identifyImage = document.getElementById('identifyFade');
      identifyImage.classList.remove('slideOutTransitionIdentify');
      identifyImage.classList.remove('slideInTransitionIdentify');
      identifyImage.classList.remove('opacity0');
    },
    loadIdentifyGame() {
      this.identifyGameRound = Object.values(this.currentGameData)[this.identifyRoundIndex];
      this.identifyRoundIndex += 1;
      this.$refs.simonTimer.simonTime = 6000;
      this.$refs.simonTimer.reset();
      this.$refs.simonTimer.start();
      this.$refs.identifyImage.src = this.getImageUrl(this.identifyGameRound.file);
    },
    checkIdentified(selection) {
      if (selection === this.identifyGameRound.text) {
        this.score += 1;
        this.$refs.simonTimer.stop();
        if (this.score >= 4) {
          this.completedTasks += 1;
          this.$refs.simonTimer.score += 1;
          this.showCorrect();
        } else {
          this.loadIdentifyGameTransition();
        }
      } else {
        this.completedTasks += 1;
        this.$refs.simonTimer.score += 1;
        this.showIncorrect();
      }
    },
    checkIdentifiedGer(selection) {
      if (selection === this.identifyGameRound.germanText) {
        this.score += 1;
        this.$refs.simonTimer.stop();
        if (this.score >= 4) {
          this.completedTasks += 1;
          this.$refs.simonTimer.score += 1;
          this.showCorrect();
        } else {
          this.loadIdentifyGameTransitionGer();
        }
      } else {
        this.completedTasks += 1;
        this.$refs.simonTimer.score += 1;
        this.showIncorrect();
      }
    },
    shuffleIdentifyButtons() {
      let currentIndex = this.identifyButtons.length;
      let temporaryValue;
      let randomIndex;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = this.identifyButtons[currentIndex];
        this.identifyButtons[currentIndex] = this.identifyButtons[randomIndex];
        this.identifyButtons[randomIndex] = temporaryValue;
      }
      this.$forceUpdate();
    },
    shuffleIdentifyButtonsGer() {
      let currentIndex = this.identifyButtonsGer.length;
      let temporaryValue;
      let randomIndex;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = this.identifyButtonsGer[currentIndex];
        this.identifyButtonsGer[currentIndex] = this.identifyButtonsGer[randomIndex];
        this.identifyButtonsGer[randomIndex] = temporaryValue;
      }
      this.$forceUpdate();
    },
    loadUploadGame() {
      this.uploadIcons.slides = true;
      this.uploadIcons.sheets = true;
      this.uploadIcons.video = true;
      this.uploadIcons.docs = true;
      this.uploadIcons.forms = true;
      this.uploadIcons.photo = true;
      this.$refs.simonTimer.simonTime = 6000;
      this.$refs.simonTimer.reset();
      this.$refs.simonTimer.start();
    },
    disableDrag() {
      this.uploadIcons.slides = false;
      this.uploadIcons.sheets = false;
      this.uploadIcons.video = false;
      this.uploadIcons.docs = false;
      this.uploadIcons.forms = false;
      this.uploadIcons.photo = false;
      this.shareIcons.slides = false;
      this.shareIcons.docs = false;
      this.shareIcons.forms = false;
      this.shareIcons.sheets = false;
    },
    onUploadDragStart(positionDiff, absolutePosition, event) {
      event.target.classList.remove('draggableStatic');
    },
    onShareDragStart(positionDiff, absolutePosition, event) {
      if (!this.isIE11) {
        event.target.classList.remove('draggableStatic');
      }
    },
    onUploadDragFinish(positionDiff, absolutePosition, event) {
      event.target.classList.add('draggableStatic');
      this.draggableValue.resetInitialPos = true;
      setTimeout(() => this.resetInitialPosValue(), 100);
    },
    onShareDragFinish(positionDiff, absolutePosition, event) {
      if (!this.isIE11) {
        event.target.classList.add('draggableStatic');
        this.draggableValueShare.resetInitialPos = true;
        setTimeout(() => this.resetInitialPosValue(), 100);
      }
    },
    elementReleased(event) {
      if (this.$refs.simonTimer.time === 0) {
        return;
      }
      event.preventDefault();
      const draggedElementRect = event.target.getBoundingClientRect();
      const goalElementRect = this.$refs.dragGoal.getBoundingClientRect();
      const overlap = !(draggedElementRect.right < goalElementRect.left
        || draggedElementRect.left > goalElementRect.right
        || draggedElementRect.bottom < goalElementRect.top
        || draggedElementRect.top > goalElementRect.bottom);
      if (overlap) {
        this.uploadIcons[event.target.id] = false;
        event.target.classList.add('draggableItemRemove');
        const dragGoal = document.getElementById('dragGoalOverlay');
        dragGoal.classList.remove(`dragScore${this.score}`);
        this.score += 1;
        dragGoal.classList.add(`dragScore${this.score}`);
        if (this.score >= 5) {
          this.completedTasks += 1;
          this.$refs.simonTimer.score += 1;
          this.$refs.simonTimer.stop();
          setTimeout(() => this.showCorrect(), 400);
        }
      }
    },
    shareElementReleased(event) {
      if (!this.isIE11) {
        if (this.$refs.simonTimer.time === 0) {
          return;
        }
        event.preventDefault();
        const neededIconName = this.shareGameRound.name;
        const neededIcon = this.$refs[neededIconName].getBoundingClientRect();
        const overlap = this.checkShareOverlap(neededIcon);
        this.draggableValueShare.resetInitialPos = true;
        setTimeout(() => this.resetInitialPosValue(), 300);
        if (overlap) {
          this.score += 1;
          this.shareIcons[neededIconName] = false;
          this.shareRoundsCompleted.push(neededIconName);
          this.$refs[neededIconName].classList.add('hiddenIcon');
          if (this.score >= 4) {
            this.shareRoundsCompleted = [];
            this.completedTasks += 1;
            this.$refs.simonTimer.score += 1;
            this.showCorrect();
          } else {
            this.loadShareGame();
          }
        } else {
          const sheetsRect = this.$refs.sheets ? this.$refs.sheets.getBoundingClientRect() : null;
          const formsRect = this.$refs.forms ? this.$refs.forms.getBoundingClientRect() : null;
          const docsRect = this.$refs.docs ? this.$refs.docs.getBoundingClientRect() : null;
          const slidesRect = this.$refs.slides ? this.$refs.slides.getBoundingClientRect() : null;

          const checkSheets = this.checkShareOverlap(sheetsRect);
          const checkForms = this.checkShareOverlap(formsRect);
          const checkDocs = this.checkShareOverlap(docsRect);
          const checkSlides = this.checkShareOverlap(slidesRect);

          if (checkSheets || checkForms || checkDocs || checkSlides) {
            this.mistakes += 1;
            this.showIncorrect(false);
          }
        }
      }
    },
    checkShareOverlap(checkIcon) {
      if (checkIcon === null) {
        return false;
      }
      const goalElementRect = this.$refs.shareDragGoal.getBoundingClientRect();
      return !(checkIcon.right < goalElementRect.left
        || checkIcon.left > goalElementRect.right
        || checkIcon.bottom < goalElementRect.top
        || checkIcon.top > goalElementRect.bottom);
    },
    resetInitialPosValue() {
      this.draggableValueShare.resetInitialPos = false;
      this.draggableValue.resetInitialPos = false;
    },
    resetStopDragging() {
      this.draggableValueShare.stopDragging = false;
      this.draggableValue.stopDragging = false;
    },
    shareGameIE11Click(event, value) {
      if (this.isIE11) {
        const neededIconName = this.shareGameRound.name;
        if (value === this.shareGameRound.name) {
          this.score += 1;
          this.shareIcons[neededIconName] = false;
          this.shareRoundsCompleted.push(neededIconName);
          this.$refs[neededIconName].classList.add('hiddenIcon');
          if (this.score >= 4) {
            this.shareRoundsCompleted = [];
            this.completedTasks += 1;
            this.$refs.simonTimer.score += 1;
            this.showCorrect();
          } else {
            this.loadShareGame();
          }
        } else {
          this.mistakes += 1;
          this.showIncorrect(false);
        }
      }
    },
    loadShareGame() {
      if (this.score === 0) {
        this.shareIcons.slides = true;
        this.shareIcons.docs = true;
        this.shareIcons.forms = true;
        this.shareIcons.sheets = true;
      }
      const numberOfShareItems = Object.keys(this.currentGameData).length;
      const randomIndex = Math.floor(Math.random() * Math.floor(numberOfShareItems));
      this.shareGameRound = Object.values(this.currentGameData)[randomIndex];
      if (this.shareRoundsCompleted.find(round => round === this.shareGameRound.name)) {
        this.loadShareGame();
        return;
      }
      this.$nextTick(() => this.frenchFormat());
      this.$nextTick(() => this.germanFormat());
      this.$refs.simonTimer.simonTime = 6000;
      this.$refs.simonTimer.reset();
      this.$refs.simonTimer.start();
    },
    frenchFormat() {
      if (this.isFrench()) {
        const shareText = document.getElementById('shareText');
        shareText.classList.add('frenchShareText');
      }
    },
    germanFormat() {
      if (this.isGerman()) {
        const shareText = document.getElementById('shareText');
        shareText.classList.add('germanShareText');
      }
    },
    getImageUrl(filename) {
      const images = require.context('../assets/images/puzzles', false, /\.png$/);
      return images(`./${filename}`);
    },
    timeUp() {
      this.mistakes += 1;
      this.draggableValue.resetInitialPos = true;
      this.draggableValueShare.resetInitialPos = true;
      this.draggableValueShare.stopDragging = true;
      this.draggableValue.stopDragging = true;
      this.disableDrag();
      setTimeout(() => this.resetStopDragging(), 0);
      setTimeout(() => this.resetInitialPosValue(), 0);
      this.completedTasks += 1;
      this.$refs.simonTimer.score += 1;
      this.showIncorrect(true);
    },
    completeSimonSays() {
      switch (this.mistakes) {
        case 0:
          this.finalScore = 100;
          break;
        case 1:
          this.finalScore = 50;
          break;
        default:
          this.finalScore = 25;
          break;
      }
      this.$emit('completeSimon', this.finalScore);
    },
    giveUp() {
      this.$emit('completeSimon', 0);
    },
    showIncorrect(skip = false) {
      this.showIncorrectIcon = true;
      setTimeout(() => this.hideIncorrectIcon(skip), 400);
    },
    showCorrect() {
      this.showCorrectIcon = true;
      setTimeout(() => this.hideCorrectIcon(), 400);
    },
    hideIncorrectIcon(skip) {
      this.showIncorrectIcon = false;
      if (skip) {
        this.loadGameTransition();
      }
    },
    hideCorrectIcon() {
      this.showCorrectIcon = false;
      this.loadGameTransition();
    },
    isFrench() {
      return (this.language === 'french');
    },
    isGerman() {
      return (this.language === 'german');
    },
    rerenderGame() {
      const oldType = this.currentType;
      this.currentType = '';
      setTimeout(() => this.rerenderGameType(oldType), 0);
    },
    rerenderGameType(type) {
      this.currentType = type;
      this.$nextTick(() => this.frenchFormat());
      this.$nextTick(() => this.germanFormat());
    },
    slideIn() {
      const simonContainers = document.getElementsByClassName('simonGameContainer');
      simonContainers.forEach((container) => {
        container.classList.remove('slideOutTransition');
        container.classList.add('slideInTransition');
        setTimeout(() => this.resetSlideTransition(), 600);
      });
    },
    resetSlideTransition() {
      const simonContainers = document.getElementsByClassName('simonGameContainer');
      simonContainers.forEach((container) => {
        container.classList.remove('slideOutTransition');
        container.classList.remove('slideInTransition');
        container.classList.remove('opacity0');
      });
    },
  },
};
</script>

<style scoped lang="scss">
    #simonHeader {
      height: 40px;
      width: 600px;
      margin: 0 auto;
      position: relative;
      top: 3px;
    }
    #simonTimer {
      position: relative;
      left: 195px;
      top: -30px;
      width: 600px;
      margin: 0 auto;
    }
    #simonSaysContainer {
      width: 600px;
      height: 450px;
      padding-top: 15px;
      padding-bottom: 40px;
      margin: 0 auto;
      background-color: white;
      border-radius: 8px;
      position: relative;
      top: -15px;
    }
    .currentTypeTitle {
      width: 280px;
      margin: 0 auto;
      text-align: center;
    }
    .simonInstruction {
      width: 100%;
      text-align: center;
      padding-bottom: 20px;
      padding-top: 8px;
    }
    #dragGoal {
      position: absolute !important;
      top: 100px !important;
      left: 190px !important;
      padding: 40px;
      margin-left: 10px;
      margin-top: 55px;
      border: 1px solid lightgrey;
      border-radius: 10px;
      width: 126px;
      height: 110px;
    }
    #dragGoalOverlay {
      position: absolute !important;
      top: 100px !important;
      left: 195px !important;
      margin-left: 5px;
      padding: 40px;
      margin-top: 55px;
      border: 1px solid lightgrey;
      border-radius: 10px;
      width: 126px;
      height: 110px;
    }
    .dragScore0 {
      -webkit-mask-image: linear-gradient(to bottom, transparent 80%, black 0%);
      mask-image: linear-gradient(to bottom, transparent 80%, black 0%);
    }
    .dragScore1 {
      -webkit-mask-image: linear-gradient(to bottom, transparent 65%, black 0%);
      mask-image: linear-gradient(to bottom, transparent 65%, black 0%);
    }
    .dragScore2 {
      -webkit-mask-image: linear-gradient(to bottom, transparent 55%, black 0%);
      mask-image: linear-gradient(to bottom, transparent 55%, black 0%);
    }
    .dragScore3 {
      -webkit-mask-image: linear-gradient(to bottom, transparent 45%, black 0%);
      mask-image: linear-gradient(to bottom, transparent 45%, black 0%);
    }
    .dragScore4 {
      -webkit-mask-image: linear-gradient(to bottom, transparent 35%, black 0%);
      mask-image: linear-gradient(to bottom, transparent 35%, black 0%);
    }
    .dragScore5 {
      -webkit-mask-image: linear-gradient(to bottom, transparent 20%, black 0%);
      mask-image: linear-gradient(to bottom, transparent 20%, black 0%);
    }
    .dragScore6 {
      -webkit-mask-image: linear-gradient(to bottom, transparent 20%, black 0%);
      mask-image: linear-gradient(to bottom, transparent 20%, black 0%);
    }
    #identifyImage {
      height: 250px;
      width: 250px;
      position: relative;
      left: 50%;
      margin-left: -125px;
    }
    #identifyButtonContainer {
      display: flex;
      flex-direction: row;
      width: 350px;
      margin: 0 auto;
    }
    .identifyBtn {
      background-color: grey;
      padding: 11px;
      width: 100px;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 30px;
      border-radius: 8px;
      margin: 5px;
      color: white;
    }
    .identifyBtn:hover {
      background: #4885ED 0% 0% no-repeat padding-box;;
      cursor: pointer;
    }
    .draggableItem {
      z-index: 1000;
      width: 63px;
    }
    .draggableItemGer {
      z-index: 1000;
      margin-left: -19px;
      width: 104px;
    }
    .draggableItemShare {
      z-index: 1000;
      width: 63px;
    }
    .draggableItemShareGer {
      z-index: 1000;
      margin-left: -19px;
      width: 104px;
    }
    .draggableItemRemove {
      width: 0px;
      height: 0px;
      transition: 0.3s ease opacity;
      display: none;
      opacity: 0%;
    }
    .testingNav {
      padding: 5px;
    }
    .uploadItemCol {
      display: flex;
      flex-direction: column;
      width: 200px;
    }
    .uploadItemCol .imageContainer {
      margin: 0 auto;
      padding: 8px;
    }
    .uploadItemContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    #shareContainer {
      width: 800px;
      margin: 0 auto;
      justify-content: center;
      height: 300px;
    }
    .shareItemCol {
      display: flex;
      flex-direction: column;
      width: 200px;
      position: relative;
      left: -3px;
    }
    .shareItemCol .imageContainer {
      margin: 0 auto;
      padding: 8px;
    }
    .speech-bubble {
      position: relative;
      top: -270px;
      left: 50px;
      padding: 30px;
      background: #eaeaea;
      border-radius: .4em;
    }
    .speech-bubble:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 70%;
      width: 0;
      height: 0;
      border: 28px solid transparent;
      border-top-color: #eaeaea;
      border-bottom: 0;
      border-right: 0;
      margin-left: -14px;
      margin-bottom: -28px;
    }
    #shareUser {
      position: relative;
      height: 150px;
      width: 150px;
      top: -400px;
      right: -225px;
    }
    #girl {
      height: 350px;
      width: 350px;
      position: absolute !important;
      top: 18px !important;
      left: 15px !important;
    }
    .draggableStatic {
      top: unset;
      left: unset;
      position: static !important;
    }
    .draggableStaticShare {
      top: unset;
      left: unset;
      position: static !important;
    }
    #shareText {
      position: absolute !important;
      width: 120px;
      display: inline-block;
      top: 82px !important;
      left: 72px !important;
      text-align: center;
    }
    .frenchShareText, .germanShareText {
      margin-top: -16px;
    }
    .imageContainer {
      justify-content: center;
      height: 87px;
      width: 63px;
    }
    #emailContainer {
      padding-top: 20px;
      width: 500px;
      margin: 0 auto;
      border-bottom: 1px solid black;
    }
    .gmailIcons {
      float: left;
    }
    .gmailIconStar {
      padding-left: 1px;
      padding-right: 1px;
      height: 14px;
      width: 14px;
    }
    .hiddenIcon {
      display: none;
    }
    .gmailIconBox {
      padding-right: 6px;
      height: 11px;
      width: 11px;
      position: relative;
      bottom: 1px;
    }
    .gmailIconBookmark {
      padding-left: 6px;
      height: 12px;
      width: 12px;
    }
    .emailFrom {
      font-weight: bold;
      min-width: 80px;
      float: left;
      text-align: left;
      padding-left: 15px;
    }
    .emailSubject {
      font-weight: bold;
      text-align: left;
      display: block;
      position: relative;
      left: 10px;
    }
    .emailTime {
      font-weight: bold;
      float: right;
      position: relative;
      bottom: 45px;
      right: 5px;
    }
    #incorrectCorrect {
      border-radius: 10px;
      width: 200px;
      height: 200px;
      background-color: #ffffff;
      position: absolute;
      top: 47%;
      left: 50%;
      margin-top: -90px;
      margin-left: -112px;
      box-shadow: 10px 10px 8px lightgrey;
      border: 1px solid lightgrey;
    }
    #incorrectCorrectPane {
      z-index: 9;
      width: 300px;
      height: 300px;
      position: fixed;
    }
    #simonCorrectIcon {
      width: 150px;
      height: 150px;
      position: absolute !important;
      left: 50% !important;
      margin-left: -75px !important;
      top: 25px !important;
      z-index: 8;
    }
    #simonIncorrectIcon {
      width: 150px;
      height: 150px;
      position: absolute !important;
      left: 50% !important;
      margin-left: -75px !important;
      top: 25px !important;
      z-index: 8;
    }
    .opacity0 {
      opacity: 0;
    }
    .slideOutTransition {
      transition: all .5s;
      transform: translateX(-200px);
    }
    .slideInTransition {
      transition: all .5s;
      opacity: 1;
      transform: translateX(-400px);
      position: relative;
      right: -400px;
    }
    .slideInTransitionIdentify {
      transition: all .5s;
      opacity: 1;
      position: relative;
    }
    .slideOutTransitionIdentify {
      transition: all .5s;
    }
    .preloaded {
      position: absolute;
      top: 47%;
      left: 50%;
      width: 5px;
      height: 5px;
      z-index: -1;
    }
</style>
