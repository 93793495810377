import AdminContainer from '../views/Admin/AdminContainer.vue';

{
    path: '/admin/:currentPage?',
    name: 'admin-container',
    component: AdminContainer,
    props: true,
  },

<template>
  <div class="default-container">
    <div>
      <div class="chromebook">
        <img class="image" src="@/assets/images/chromebook_logo_crop.png">
      </div>
      <div class="large-title" v-html="this.translate('title')"></div>
      <div class="description" v-html="this.translate('description')"></div>
      <div class="inputs">
        <input :class="{incomplete: !user.firstName && submitted}"
          v-model="user.firstName" :placeholder="this.translate('ph1')" type="text">
        <input v-if="!hasNoLastName" :class="{'incomplete': !user.lastName && submitted}"
          v-model="user.lastName" :placeholder="this.translate('ph2')" type="text">
        <input v-if="!hasNoStoreNumber" :class="{incomplete: !user.storeNumber && submitted}"
          v-model="user.storeNumber" :type="hasStoreName ? 'text' : 'number'"
          :placeholder="hasStoreName ? this.translate('ph4') : this.translate('ph3')">
      </div>
      <div @click="nextPage" class="button grey">{{this.translate('button')}}</div>
    </div>
    <div class="volume">
      <VolumeIcon class="volume-icon"/>
      <div class="description" v-html="this.translate('sound')"></div>
    </div>
    <div class="splash-image">
      <img src="@/assets/images/chromes_lobby.png">
    </div>
    <div class="leg-copy" v-html="this.translateLegal"></div>
  </div>
</template>

<script>
import Vue from 'vue';
import VolumeIcon from '@/assets/images/icon_volume.svg';

export default {
  name: 'WelcomePage',
  props: {
    userProp: Object,
    language: String,
  },
  components: {
    VolumeIcon,
  },

  data() {
    return {
      user: this.userProp,
      submitted: false,
      text: {
        legalCopy: {
          english: 'The reason for collecting this is to populate the leaderboard so you can see how you can compare. Your personal information will be deleted after one calendar year, and will be shared with the following third parties: Google and Blueprint. If you wish to remove your information at any point please contact <span class="blue">sherlockchromes@chromebook.training.</span>',
          french: 'Nous avons collecté les informations suivantes : votre prénom, le revendeur et l’identifiant magasin. Elles seront utilisées pour établir le classement du jeu Sherlock Chromes afin que vous puissiez vous situer par rapport aux autres vendeurs. Vos informations personnelles seront supprimées après une année calendaire et seront partagées avec les tiers suivants : Google et Blueprint. Si vous souhaitez que vos informations soient supprimées, veuillez contacter <span class="blue">sherlockchromes@chromebook.training.</span>',
          german: 'Wir haben folgende Informationen erfasst: Vorname, Nachname. Wir sammeln diese Daten, um sie später in die Bestenliste einzutragen, damit du siehst, wie du dich geschlagen hast. Deine personenbezogenen Daten werden nach einem Jahr gelöscht und mit folgenden Dritten geteilt: Google und Blueprint. Du kannst deine Daten jederzeit entfernen. Wende dich dazu einfach an <span class="blue">sherlockchromes@chromebook.training.</span>',
        },
        title: {
          english: 'The Adventures of Sherlock Chromes: <br><span class="blue">The Red Diamond</span>',
          french: 'Les Aventures de Sherlock Chromes : <br><span class="blue">Le Diamant Rouge</span>',
          german: 'Die Abenteuer von Sherlock Chromes: <br><span class="blue">Der rote Diamant',
        },
        description: {
          english: 'The mystery awaits. Fill out the info below to get started.',
          french: 'Le mystère vous attend . Remplissez les champs ci-dessous pour commencer.',
          german: 'Es gilt ein Rätsel zu lösen. Fülle das Formular unten aus und leg los.',
        },
        sound: {
          english: 'For the best experience, turn up your volume or use headphones.',
          french: 'Pour une expérience optimale, augmentez le volume ou utilisez des écouteurs.',
          german: 'Am meisten Spaß macht es, wenn du deine Musik etwas lauter machst oder Kopfhörer benutzt.',
        },
        ph1: {
          english: 'First name',
          french: 'Prénom',
          german: 'Vorname',
        },
        ph2: {
          english: 'Last name',
          french: 'Nom de famille',
          german: 'Nachname',
        },
        ph3: { // store number
          english: 'Store number',
          french: 'Nom de la boutique',
          german: 'Store-Nummer',
        },
        ph4: { // store name
          english: 'Store name',
          french: 'Nom de la boutique',
          german: 'Store-Name',
        },
        button: {
          english: 'Get started',
          french: 'Pour commencer',
          german: 'Loslegen',
        },
      },
    };
  },
  mounted() {
    if (this.hasNoStoreNumber) Vue.set(this.user, 'storeNumber', -1);
    if (this.hasNoLastName) Vue.set(this.user, 'lastName', '-1');
  },
  computed: {
    translateLegal() {
      if (this.french) {
        return this.translate('legalCopy');
      }
      if (this.german) {
        return this.translate('legalCopy');
      }
      return `We have collected the following information: ${this.legCopyInfo} ${this.translate('legalCopy')}`;
    },
    legCopyInfo() {
      const pathArray = this.$route.path.split('/')[2].toLowerCase();
      switch (pathArray) {
        case 'bestbuy':
        case 'bestbuyca':
        case 'dcp':
          return 'your first name, last name, store number, and retailer.';
        case 'jbhifi':
        case 'aus':
          return 'your first name, last name, store name, and retailer.';
        case 'elkjop':
        case 'mediamarktmsh':
          return 'your first name, store name, and retailer.';
        default:
          return 'your first name, last name, and retailer.';
      }
    },
    hasStoreName() {
      const pathArray = this.$route.path.split('/')[2].toLowerCase();
      switch (pathArray) {
        case 'fnacdarty':
        case 'leclerc':
        case 'elkjop':
        case 'mediamarktmsh':
        case 'boulanger':
        case 'jbhifi':
        case 'aus':
          return true;
        default:
          return false;
      }
    },
    hasNoLastName() {
      const pathArray = this.$route.path.split('/')[2].toLowerCase();
      switch (pathArray) {
        case 'fnacdarty':
        case 'boulanger':
        case 'leclerc':
        case 'elkjop':
        case 'mediamarktmsh':
          return true;
        default:
          return false;
      }
    },
    hasNoStoreNumber() {
      const pathArray = this.$route.path.split('/')[2].toLowerCase();
      switch (pathArray) {
        case 'google':
        case 'experience':
        case 'mediamarktmsd':
        case 'events':
        case 'de':
          return true;
        default:
          return false;
      }
    },
    french() {
      return (this.language === 'french');
    },
    german() {
      return (this.language === 'german');
    },
    isGoogle() {
      return (this.$route.path.split('/')[2] === 'google');
    },
    isFnacdarty() {
      return (this.$route.path.split('/')[2] === 'fnacdarty');
    },
  },
  methods: {
    translate(key) {
      if (this.french) return this.text[key].french;
      if (this.german) return this.text[key].german;
      return this.text[key].english;
    },
    nextPage() {
      if (this.user.firstName && this.user.lastName && this.user.storeNumber) {
        this.$emit('updateUser', this.user);
        this.$emit('nextPage');
      } else {
        Vue.set(this, 'submitted', true);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.default-container {
  display: flex;
  max-width: 1100px !important;
  top: 40% !important;

  .volume {
    position: absolute;
    display: flex;
    align-items: center;
    top: 100%;
    left: 0;
    height: 35px;
    .description {
      padding: 0 !important;
      margin: 0 !important;
    }
    .volume-icon {
      width: 25px;
      margin-right: 15px;
    }
  }

  .leg-copy {
    position: absolute;
    top: calc(100% + 55px);
    left: 0;
    font-size: 14px;
    line-height: 18px;
    color: #757575;
  }

  .splash-image {
    width: 500px;
    align-self: center;
    flex: none;
    img {
      max-width: 100%;
    }
  }
  .large-title {
    padding-top: 25px;
    width: 550px;
    font-size: 58px !important;
    line-height: 62px !important;
    span {
      color: #4885ED;
    }
  }

  .description {
    padding-top: 19px;
    margin-bottom: 25px;
  }

  .inputs {
    display: flex;
    flex-direction: column;
    width: 339px;
    input {
      border: 1px solid rgba(117, 117, 117, 0.5);
      font-size: 14px;
      margin: 4px 0;
      border-radius: 6px;
      background: white;
      height: 43px;
      padding: 0 0 0 16px;
      letter-spacing: 0px;
      color: #757575;
      outline: none;
    }
    .incomplete {
      border: 1px solid #DB3236;
      border-radius: 6px;
    }

    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::placeholder {
      color: #757575;
      opacity: 0.5;
    }

    input:focus {
      border: 1px solid #4885ED;
      border-radius: 6px;
    }
  }

  .button {
    margin-top: 25px;
    margin-bottom: 16px;
    line-height: 52px;
    display: inline-block !important;
    padding: 0 20px;
    position: relative;
    left: 0
  }
}
</style>
