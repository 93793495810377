<template>
    <div id="minigameContainer" ref="mgContainer" v-bind:class="classObject">
       <!-- <div id="testNav">
         <div class="navItem" @click="minigameType = 'memoryMatch'">Memory Match</div>
         <div class="navItem" @click="minigameType = 'simonSays'">Simon Says</div>
         <div class="navItem" @click="minigameType = 'slidingPuzzle'">Sliding Puzzle</div>
         <div class="navItem" @click="minigameType = 'docDetective'">Doc Detective</div>
       </div> -->
       <div id='bannerContainer' v-if="minigameType !== false && !minigameComplete">
         <img id="bannerImage" src="../assets/images/icons/chromebook_banner.png"/>
       </div>
       <div v-if="minigameType === 'memoryMatch'
       && minigameComplete === false && showMinigameIntro === false">
        <MemoryBoard ref="memoryBoard" :language="this.language"
        @completeMemory="completeMemory" :numberOfCards="20"/>
       </div>
       <div v-else-if="minigameType === 'simonSays'
       && minigameComplete === false && showMinigameIntro === false">
        <SimonSays ref="simonSays" :language="this.language"
        @completeSimon="completeSimon"/>
       </div>
       <div v-else-if="minigameType === 'slidingPuzzle'
       && minigameComplete === false && showMinigameIntro === false">
        <SlidingPuzzle ref="slidingPuzzle" :language="this.language"
        @completePuzzle="completePuzzle"/>
       </div>
       <div v-else-if="minigameType === 'docDetective'
       && minigameComplete === false && showMinigameIntro === false">
        <DocDetective ref="docDetective" :language="this.language"
        @completeDetective="completeDetective"/>
       </div>
       <div id="minigameIntroContainer" v-if="showMinigameIntro">
         <div id="minigameTitle">
           {{
             isFrench() ? "Comment jouer" : ( isGerman() ? "Spielregeln" : "How to play:" )
            }}
           <span id="minigameName">{{getMinigameTitle()}}</span>
         </div>
         <div id="minigameIntroText" v-html="getMinigameIntroText()"></div>
         <div id="startGameButton" @click="startGame()">
          {{
            isFrench() ? "Démarrer le jeu" : ( isGerman() ? "Spiel starten" : "Start game" )
          }}
          </div>
       </div>
       <div id="minigameCompleteContainer" v-if="minigameComplete">
          <div id="youDidIt">{{ getYouDidItMessage() }}</div>
          <div id="completeScore">
            {{
              isFrench() ? "Votre score:" : ( isGerman() ? "Deine Punktzahl" : "Your score:" )
            }}
            <span id="gameScore">{{getFinishedScore()}} points</span>
          </div>
          <div id="backToRoom" @click="backToRoom()">
            {{
              isFrench() ? "Retour à la pièce" :
              ( isGerman() ? "Zurück ins Zimmer" : "Back to room" )
            }}
          </div>
       </div>
    </div>
</template>

<script>
import MemoryBoard from '@/components/MemoryBoard.vue';
import SimonSays from '@/components/SimonSays.vue';
import SlidingPuzzle from '@/components/SlidingPuzzle.vue';
import DocDetective from '@/components/DocDetective.vue';
import minigameData from '../assets/data/minigameData.json';

export default {
  name: 'MinigameContainer',
  components: {
    MemoryBoard,
    SimonSays,
    SlidingPuzzle,
    DocDetective,
  },
  props: {
    minigameType: String,
    language: String,
  },
  computed: {
    classObject() {
      return {
        background1: this.minigameType === 'memoryMatch' && this.minigameComplete === false && this.showMinigameIntro === false,
        background2: this.minigameType === 'simonSays' && this.minigameComplete === false && this.showMinigameIntro === false,
        background3: this.minigameType === 'slidingPuzzle' && this.minigameComplete === false && this.showMinigameIntro === false,
        background4: this.minigameType === 'docDetective' && this.minigameComplete === false && this.showMinigameIntro === false,
      };
    },
  },
  data() {
    return {
      minigameComplete: false,
      simonScore: -1,
      puzzleScore: -1,
      memoryScore: -1,
      detectiveScore: -1,
      showMinigameIntro: true,
      minigameMusic: null,
    };
  },
  methods: {
    getMinigameTitle() {
      if (this.isFrench()) {
        return minigameData[this.minigameType].frenchTitle;
      }
      if (this.isGerman()) {
        return minigameData[this.minigameType].germanTitle;
      }
      return minigameData[this.minigameType].title;
    },
    getMinigameIntroText() {
      if (this.isFrench()) {
        return minigameData[this.minigameType].frenchIntro;
      }
      if (this.isGerman()) {
        return minigameData[this.minigameType].germanIntro;
      }
      return minigameData[this.minigameType].introText;
    },
    getMinigameOutroText() {
      return minigameData[this.minigameType].outroText;
    },
    startGame() {
      this.showMinigameIntro = false;
    },
    completeSimon(simonScore) {
      this.minigameComplete = true;
      this.simonScore = simonScore;
    },
    completePuzzle(puzzleScore) {
      this.minigameComplete = true;
      this.puzzleScore = puzzleScore;
    },
    completeMemory(memoryScore) {
      this.memoryScore = memoryScore;
      this.minigameComplete = true;
    },
    completeDetective(detectiveScore) {
      this.detectiveScore = detectiveScore;
      this.minigameComplete = true;
    },
    backToRoom() {
      let gid = null;
      switch (this.minigameType) {
        case 'memoryMatch':
          gid = 5; break;
        case 'slidingPuzzle':
          gid = 4; break;
        case 'simonSays':
          gid = 3; break;
        case 'docDetective':
          gid = 6; break;
        default:
          gid = null; break;
      }
      this.$emit('gameCompleted', {
        id: gid,
        gameType: this.minigameType,
        score: this.getFinishedScore(),
      });
      this.minigameComplete = false;
    },
    getFinishedScore() {
      switch (this.minigameType) {
        case 'memoryMatch':
          return this.memoryScore;
        case 'slidingPuzzle':
          return this.puzzleScore;
        case 'simonSays':
          return this.simonScore;
        case 'docDetective':
          return this.detectiveScore;
        default:
          return 0;
      }
    },
    handleScroll() {
      if (this.minigameType === 'simonSays') {
        this.$refs.simonSays.rerenderGame();
      }
    },
    getYouDidItMessage() {
      if (this.getFinishedScore() > 0) {
        if (this.isFrench()) {
          return 'Vous avez réussi !';
        }
        if (this.isGerman()) {
          return 'Geschafft!';
        }
        return 'You did it!';
      }
      if (this.isFrench()) {
        return 'Bien tenté !';
      }
      if (this.isGerman()) {
        return 'Netter Versuch!';
      }
      return 'Good try!';
    },
    isFrench() {
      return this.language === 'french';
    },
    isGerman() {
      return this.language === 'german';
    },
  },
  mounted() {
    this.$refs.mgContainer.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.$refs.mgContainer.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
<style scoped>
.navItem {
  color: white;
  margin-left: 20px;
  margin-right: 20px;
}
#bannerImage {
  width: 220px;
  height: 43px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 auto;
  position: relative;
  left: 50%;
  margin-left: -110px;
}
#bannerContainer {
  background-color: #ffffff;
  background-image: none;
}
#minigameContainer {
  height: 100%;
  width: 100vw;
  padding: 0px;
  margin: 0px;
  background-color: #ffffff;
  overflow: hidden;
  z-index: 3;
  position: absolute;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
.background1 {
  background-image: url(../assets/images/backgrounds/BG-01.jpg);
  background-size: auto;
  background-repeat: repeat;
}
.background2 {
  background-image: url(../assets/images/backgrounds/BG-02.jpg);
  background-size: auto;
  background-repeat: repeat;
}
.background3 {
  background-image: url(../assets/images/backgrounds/BG-03.jpg);
  background-size: auto;
  background-repeat: repeat;
}
.background4 {
  background-image: url(../assets/images/backgrounds/BG-04.jpg);
  background-size: auto;
  background-repeat: repeat;
}
#minigameCompleteContainer {
  width: 500px;
  text-align: center;
  margin: 0 auto;
  margin-top: 17%;
}
#youDidIt {
  color: grey;
  font-weight: bold;
  font-size: 52px;
}
#minigameOutroText {
  width: 500px;
  margin: 0 auto;
  padding-top: 20px;
  text-align: left;
}
#completeScore {
  font-size: 24px;
  padding-top: 20px;
  padding-bottom: 30px;
  color: #757575;
}
#backToRoom {
  border-radius: 6px;
  background-color: #757575;
  color: #ffffff;
  padding: 18px;
  width: 100px;
  margin: 0 auto;
}
#backToRoom:hover {
  background: #4885ED 0% 0% no-repeat padding-box;;
  cursor: pointer;
}
#minigameIntroContainer {
  padding-top: 20px;
  width: 450px;
  margin-right: -5px;
  margin: 0 auto;
  color: #757575;
}
#minigameTitle {
  font-size: 28px;
  font-weight: bold;
  text-align: left;
}
#minigameName {
  color: #4885ED;
}
#minigameIntroText {
  font-size: 16px;
  padding-top: 20px;
  padding-bottom: 30px;
}
#startGameButton {
  border-radius: 6px;
  background-color: #757575;
  color: white;
  padding: 16px;
  width: 111px;
  font-size: 16px;
  margin: 0 auto;
  text-align: center;
}
#startGameButton:hover {
  background: #4885ED 0% 0% no-repeat padding-box;;
  cursor: pointer;
}
#gameScore {
  color: #4885ED;
  font-weight: bold;
}
</style>
