<template>
  <div />
</template>

<script>
export default {
  mounted() {
    this.$emit('nextPage');
  },
};
</script>
