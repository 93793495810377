<template>
  <div class="exit-modal">
    <div class="background"></div>
    <div class="return-to-lobby">
      <div class="title">
        {{translate('title1')}} <span class="red">{{translate('title2')}}</span>
      </div>
      <div class="description">
        {{translate('description')}}
      </div>
      <div class="buttons">
        <div @click="$emit('closeExitModal', false)" class="button white">
          {{ french ? 'Non' : ( german ? 'Nein' : 'No' ) }}
        </div>
        <div @click="$emit('closeExitModal', true)" class="button grey">
          {{ french ? 'Oui' : ( german ? 'Ja' : 'Yes' ) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExitModal',
  props: {
    msg: String,
    language: String,
  },
  data() {
    return {
      text: {
        title1: {
          english: 'Are you sure you want to',
          french: 'Êtes-vous sûr de vouloir',
          german: 'Möchtest du wirklich',
        },
        title2: {
          english: 'return to lobby?',
          french: 'retourner dans le hall ?',
          german: 'in die Eingangshalle zurückkehren?',
        },
        description: {
          english: 'Your progress will not be saved.',
          french: 'Votre progression ne sera pas enregistrée',
          german: 'Dein Fortschritt wird nicht gespeichert.',
        },
      },
    };
  },
  computed: {
    french() {
      return (this.language === 'french');
    },
    german() {
      return (this.language === 'german');
    },
  },
  methods: {
    translate(key) {
      if (this.french) return this.text[key].french;
      if (this.german) return this.text[key].german;
      return this.text[key].english;
    },
  },
};
</script>

<style scoped lang="scss">
.exit-modal {
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  .background {
    background-color: #000000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
  .return-to-lobby {
    background-color: white;
    width: 732px;
    height: 231px;
    opacity: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    box-shadow: 0px 4px 13px #0000000D;
    border: 1px solid #9A9FA44D;
    .title {
      text-align: center;
      margin-top: 30px;
      font: bold 32px 'Google Sans';
      letter-spacing: -0.48px;
      color: #757575;
      opacity: 1;
    }

    .description {
      text-align: center;
      font: 18px 'Google Sans';
      letter-spacing: 0px;
      margin-top: 20px;
      color: #757575;
      opacity: 1;
    }

    .buttons {
      display: flex;
      justify-content: center;
      margin-top: 15px;
      .button {
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        opacity: 1;
        letter-spacing: 0px;
        cursor: pointer;
      }

      .grey {
        background: #757575 0% 0% no-repeat padding-box;
        color: #FFFFFF;
        width: 76px;
      }

      .grey:hover {
        background: #4885ED 0% 0% no-repeat padding-box;;
      }

      .white {
        border: 1px solid #D7D7D7;
        margin-right: 20px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        color: #757575;
        width: 72px;
      }
    }

  }
}
</style>
