<template>
  <div class="quiz-container">
    <div class="header">
      <img class="image" src="@/assets/images/chromebook_logo_crop.png">
    </div>
    <div v-if="!answered" class="title">
      {{currentQuestion.title ? currentQuestion.title : defaultTitle }}
    </div>
    <div v-else>
      <div v-if="result === true" class="title right">
        {{isGerman ? "Richtig!" : "Correct!"}}
      </div>
      <div v-else-if="result === false" class="title wrong">
        {{isGerman ? "Falsch!" : "Incorrect!"}}
      </div>
    </div>
    <div class="question">{{currentQuestion.question}}</div>
    <div class="answers"
      v-for="(answer,index) in currentQuestion.answers"
      :key="index" :class="{selected: selection === index && answered}">
      <div class="bubble-container">
        <BubbleSelected v-if="selection === index" class="bubble"/>
        <Bubble v-else  @click="selectAnswer(index)" class="bubble"/>
      </div>
      <div class="answer" v-html="answer"></div>
    </div>
    <div v-if="answered" class="additional-info">
      <div class="incorrect" v-if="result === false">
        <div class="correct-answer">
          {{isFrench ? 'Bonne réponse' : (isGerman ? 'Richtige Antwort' : 'Correct answer')}}
        </div>
        <div class="answers">
          <div class="bubble-container">
            <BubbleSelected class="bubble"/>
          </div>
            <div class="answer">{{currentQuestion.answers[currentQuestion.correct]}}</div>
        </div>
      </div>
      <div class="info-text">
        {{(result === false && currentQuestion.incorrectText) ?
        currentQuestion.incorrectText : currentQuestion.info}}
      </div>
    </div>
    <div v-if="result === null" @click="submit" class="button grey">
      {{isFrench ? 'Soumettre' : (isGerman ? 'Absenden' : 'Submit')}}
    </div>
    <div v-else @click="nextQuestion" class="button grey">
      {{isFrench ? 'Continuer' : (isGerman ? 'Weiter' : 'Continue')}}
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Bubble from '@/assets/images/quiz_bubble_neutral.svg';
import BubbleSelected from '@/assets/images/quiz_bubble_selected.svg';
// import CorrectMark from '@/assets/images/check.svg';
// import IncorrectMark from '@/assets/images/close.svg';

export default {
  name: 'QuizComponent',
  props: {
    quizNum: Number,
    language: String,
  },
  components: {
    Bubble,
    BubbleSelected,
    // CorrectMark,
    // IncorrectMark,
  },
  data() {
    return {
      score: 0,
      selection: null,
      result: null,
      answered: false,
      questionNumber: 0,
      rating: 5,
      questions: [
        {
          question: 'What is required to set up  the Chromebook?',
          answers: [
            'Chromebook, Wi-Fi, Google Account',
            'Chromebook, Google Account, 7 Digit Password',
            'Chromebook, Google Account, Face Detection Photo',
          ],
          correct: 0,
          info: 'All you need to set up a Chromebook is a Chromebook, Wi-Fi, Google Account',
        },
        {
          question: 'Which security protection is built-in with every Chromebook?',
          answers: [
            'Verified Boot',
            'Sandboxing',
            'Automatic updates',
            'Data Encryption',
            'All of the above',
          ],
          correct: 4,
          info: 'Every Chromebook comes with built-in security protection so you’re safe from the moment you open a new Chromebook. Chromebook comes with four layers of security: Verified Boot, Sandboxing, Automatic updates, and Data Encryption.',
        },
        {
          question: 'Which Google service can you use to  watch, work, listen, create, and play on your Chromebook?',
          answers: [
            'Google Maps',
            'Google Play Store',
            'Google Sheets',
          ],
          correct: 1,
          info: 'e Play Store, you can watch, work, listen, create, and play on your Chromebook. Get the same familiar experience that you love on your smartphone, with the same benefits of offline access to content and the added benefit of a larger screen.',
        },
        {
          question: 'What is the useful app called that helps you switch from an old device to a new Chromebook?',
          answers: [
            'Backup & Switch',
            'Switch & Sync',
            'Backup & Sync',
            'Make the switch',
          ],
          correct: 2,
          info: 'Backup and Sync is an app for Mac and PC that backs up files and photos safely in Google Drive and Google Photos, so they’re no longer trapped on your computer and other devices. Just choose the folders you want to back up, and we’ll take care of the rest.',
        },
        {
          question: 'What feature(s) set premium Chromebooks apart from entry-level devices?',
          answers: [
            'Improved design and formfactor',
            'Top Intel processors built in',
            'Larger storage size',
            'Higher RAM',
            'All of the Above',
          ],
          correct: 4,
          info: 'With improved design and formfactor, top Intel processors built in, larger storage size, and higher RAM, enhanced Chromebook Pro users get to enjoy enhanced speed, storage, and design. Now there’s even more to your Chromebook!',
        },
        {
          question: 'How many free GBs of cloud storage in Google Drive does everyone receive with their Google Account?',
          answers: [
            '0GB',
            '5GB',
            '10GB',
            '15GB',
          ],
          correct: 3,
          info: 'In addition to the local storage on your Chromebook, all Google Accounts come with 15 GB free Google Drive storage. And as a special offer get Google One with 100 GB of storage and other exclusive benefits for 12 months when you purchase a new Chromebook.',
        },
        {
          question: 'Switching from a non-Chromebook device to a Chromebook is easy! What are the 3 simples steps to successfully transferring your storage from your old device (photos/videos, files, etc…) to your new Chromebook?',
          answers: [
            '1) Download Backup and Sync and sign in with a Google Account. Upload all files from the old device to be safely stored in Google Drive 2) Sign in to your new Chromebook using the same Google Account 3) Sign in to your Chrome browser on your old device',
            '1) Sign in to your Chrome browser on your old device 2) Download Backup and Sync and sign in with a Google Account. Upload all files from the old device to be safely stored in Google Drive 3) Sign in to your new Chromebook using the same Google Account',
            '1) Sign in to your Chrome browser on your old device 2) Sign in to your new Chromebook using the same Google Account 3) Download Backup and Sync and sign in with a Google Account. Upload all files from the old device to be safely stored in Google Drive',
          ],
          correct: 1,
          info: 'Switching from a non-Chromebook device to a Chromebook is as easy as 1, 2, 3 1) Sign in to your Chrome browser on your old device 2) Download Backup and Sync and sign in with a Google Account. Upload all files from the old device to be safely stored in Google Drive 3) Sign in to your new Chromebook using the same Google Account ',
        },
      ],
      frenchQuestions: [
        {
          question: 'Que faut-il pour configurer le Chromebook ?',
          answers: [
            'Chromebook, Wi-Fi, compte Google',
            'Chromebook, compte Google , Mot de passe à 7 chiffres',
            'Chromebook, compte Google , Reconnaissance faciale',
          ],
          correct: 0,
          info: 'Tout ce dont vous avez besoin pour configurer un Chromebook est un Chromebook, Wi-Fi, et un compte Google',
        },
        {
          question: 'Quelle système de sécurité est intégré à chaque Chromebook ?',
          answers: [
            'Verified Boot',
            'Sandboxing',
            'Mises à jour automatiques',
            'Chiffrement des données',
            'Toutes ces réponses',
          ],
          correct: 4,
          info: 'Chaque Chromebook est doté d\'une protection de sécurité intégrée, de sorte que vous soyez en sécurité dès l\'instant où vous ouvrez un nouveau Chromebook. Chromebook est livré avec quatre couches de sécurité : le Verified Boot, le sandboxing, les mises à jour automatiques et le chiffrement des données',
        },
        {
          question: 'Quel service Google pouvez-vous utiliser pour trouver vos applications préférées sur Chromebook ?',
          answers: [
            'Google Maps',
            'Google Play Store',
            'Google Sheets',
          ],
          correct: 1,
          info: 'Avec le Google Play Store, vous pouvez regarder, travailler, écouter, créer et jouer sur votre Chromebook. Profitez de la même expérience familière que vous aimez sur votre smartphone, avec les mêmes avantages d\'un accès hors ligne au contenu et l\'avantage supplémentaire d\'un écran plus grand.',
        },
        {
          question: 'Quel est le nom de l\'application utile qui vous aide à passer d\'un ancien appareil à un nouveau Chromebook ?',
          answers: [
            'Sauvegarde et transfert',
            'Transfert et synchronisation',
            'Sauvegarde et synchronisation',
            'Effectuer le changement',
          ],
          correct: 2,
          info: 'Sauvegarde et synchronisation est une application pour Mac et PC qui sauvegarde les fichiers et les photos en toute sécurité dans Google Drive et Google Photos. Il vous suffit de choisir les dossiers que vous souhaitez sauvegarder, et nous nous occupons du reste.',
        },
        {
          question: 'Quelles sont les caractéristiques qui distinguent les chromebooks Premium des appareils d\'entrée de gamme ?',
          answers: [
            'Amélioration de l’esthétique',
            'Intégration des meilleurs processeurs Intel',
            'Un espace de stockage plus importante',
            'Une capacité RAM plus élevée',
            'Tout ce qui précède',
          ],
          correct: 4,
          info: 'Grâce à une amélioration de l’esthétique, des processeurs Intel de pointe intégrés, une taille de stockage plus importante et une RAM plus importante, les utilisateurs de Chromebook Premium bénéficient d\'une vitesse, d\'un stockage et d\'une conception améliorés. Maintenant, votre Chromebook a encore plus d\'atouts !',
        },
        {
          question: 'Combien de Go de stockage cloud gratuit dans Google Drive chacun reçoit-il avec son compte Google ?',
          answers: [
            '0GB',
            '5GB',
            '10GB',
            '15GB',
          ],
          correct: 3,
          info: 'En plus du stockage local sur votre Chromebook, tous les comptes Google sont fournis avec 15 Go de stockage gratuit sur Google Drive. Et, à titre d\'offre spéciale, bénéficiez de Google One avec 100 Go de stockage et d\'autres avantages exclusifs pendant 12 mois lorsque vous achetez un nouveau Chromebook.',
        },
        {
          question: 'Passer de votre ordinateur actuel  à un Chromebook est facile ! Quelles sont les 3 étapes simples pour réussir le transfert de vos donnée de votre ancien appareil (photos/vidéos, fichiers, etc...) vers votre nouveau Chromebook ?',
          answers: [
            '1) Téléchargez Sauvegarde et synchronisation et connectez-vous avec votre compte Google. Téléchargez tous les fichiers de l\'ancien appareil pour les stocker en toute sécurité dans Google Drive 2) Connectez-vous à votre nouveau Chromebook en utilisant le même compte Google 3) Connectez-vous à votre navigateur Chrome sur votre ancien appareil',
            '1) Connectez-vous à votre navigateur Chrome sur votre ancien appareil 2) Téléchargez Sauvegarde et synchronisation et connectez-vous avec un compte Google. Téléchargez tous les fichiers de l\'ancien appareil pour les stocker en toute sécurité dans Google Drive 3) Connectez-vous à votre nouveau Chromebook en utilisant le même compte Google',
            '1) Connectez-vous à votre navigateur Chrome sur votre ancien appareil 2) Connectez-vous à votre nouveau Chromebook en utilisant le même compte Google 3) Téléchargez Sauvegarde et synchronisation  et connectez-vous avec un compte Google. Téléchargez tous les fichiers de l\'ancien appareil pour qu\'ils soient stockés en toute sécurité dans Google Drive',
          ],
          correct: 1,
          info: 'Passer de votre ordinateur actuel  à un Chromebook est facile : 1) Connectez-vous à votre navigateur Chrome sur votre ancien appareil 2) Téléchargez Sauvegarde et synchronisation et connectez-vous avec un compte Google. Téléchargez tous les fichiers de l\'ancien appareil pour les stocker en toute sécurité dans Google Drive 3) Connectez-vous à votre nouveau Chromebook en utilisant le même compte Google',
        },
        {
          question: 'De nombreuses applications et fonctions de Chromebook peuvent fonctionner hors connexion',
          answers: [
            'Faux',
            'Vrai',
          ],
          correct: 1,
          info: 'Les clients peuvent rester productifs et se divertir partout. Google Docs, Sheets, and Slides, Gmail, Netflix, et bien d\'autres applications ont tous des capacités hors ligne. Il vous suffit de changer quelques paramètres.',
        },
      ],
      germanQuestions: [
        {
          question: 'Was braucht man, um ein Chromebook einzurichten?',
          answers: [
            'Chromebook, WLAN, Google-Konto',
            'Chromebook, Google-Konto, 7-stelliges Passwort',
            'Chromebook, Google-Konto, Foto für Gesichtserkennung',
          ],
          correct: 0,
          info: 'Alles, was du zum Einrichten eines Chromebooks brauchst, sind ein Chromebook, WLAN und ein Google-Konto',
        },
        {
          question: 'Welche Sicherheitsfunktionen sind in allen Chromebooks integriert?',
          answers: [
            'Verifzierter Bootmodus',
            'Sandboxing',
            'Automatische Updates',
            'Datenverschlüsselung',
            'Alle oben genannten',
          ],
          correct: 4,
          info: 'In jedes Chromebook sind Sicherheitsfunktionen integriert, damit du ab dem ersten Öffnen eines neuen Chromebooks geschützt bist. Chromebooks verfügen über vier Sicherheitsebene.: Verifizierter Bootmodus, Sandboxing, Automatische Updates und Datenverschlüsselung.',
        },
        {
          question: 'Mithilfe von welchem Google-Dienst kannst du auf deinem Chromebook Inhalte anschauen, anhören, erstellen, arbeiten und spielen?',
          answers: [
            'Google Maps',
            'Google Play Store',
            'Google Tabellen',
          ],
          correct: 1,
          info: 'Mit dem Google Play Store kann man auf Chromebooks Inhalte ansehen, bearbeiten, anhören, erstellen und Spiele spielen. Mit Chromebooks greift man auf eine vertraute Benutzeroberfläche zu, die man von seinem Android Smartphone gewohnt ist, hat die gleichen Vorteile  des Offline-Zugriffs und kann darüber hinaus den zusätzlichen Vorteil eines größeren Bildschirms genießen.',
        },
        {
          question: 'Wie heißt die nützliche App, die dir beim Umstieg von einem alten Gerät auf ein neues Chromebook hilft?',
          answers: [
            'Backup & Switch',
            'Switch & Sync',
            'Backup & Sync',
            'Make the switch',
          ],
          correct: 2,
          info: 'Backup and Sync ist eine App für Mac und PC, die Dateien und Fotos auf Google Drive und Google Fotos sichert, damit sie nicht mehr auf deinem Computer und anderen Geräten festhängen. Wähle einfach die Ordner, die du sichern möchtest und wir kümmern uns um den Rest.',
        },
        {
          question: 'Welche Funktionen unterscheiden Premium Chromebooks von Einsteiger-Geräten?',
          answers: [
            'Verbesserter Design und Formfaktor',
            'Integrierte Top-Intel-Prozessoren',
            'Mehr Speicherplatz',
            'Mehr Arbeitsspeicher',
            'Alle oben genannten',
          ],
          correct: 4,
          info: 'Überarbeitete Designs und Formfaktoren, integrierte Top-Intel-Prozessoren, mehr Speicherplatz und erweiterter Arbeitsspeicher – die Nutzer von Chromebook Pro können mehr Speed, Speicher und Design genießen. Und dein Chromebook kann jetzt sogar noch mehr!',
        },
        {
          question: 'Über wie viel GB kostenlosen Cloud-Speicher auf Google Drive darf sich jeder Nutzer mit seinem Google-Konto erfreuen?',
          answers: [
            '0 GB',
            '5 GB',
            '10 GB',
            '15 GB',
          ],
          correct: 3,
          info: 'Zusätzlich zum lokalen Speicher auf dem Chromebook bietet jedes Google-Konto 15 GB kostenlosen Google Drive-Speicher. Und oben drauf gibt es aktuell Google One, mit 100 GB Speicherplatz und anderen exklusiven Vorteile,  beim Kauf eines neuen Chromebooks für 12 Monate gratis mit dazu.',
        },
        {
          question: 'Der Umstieg von einem anderen Gerät auf ein Chromebook ist ganz einfach! Mit welchen 3 einfachen Schritten kannst du auf deinem alten Gerät gespeicherte Daten (Fotos/Videos, Dateien usw.) auf dein neues Chromebook übertragen?',
          answers: [
            '1) Backup and Sync herunterladen und mit einem Google-Konto anmelden. Alle Dateien von dem alten Gerät zur Sicherung in Google Drive hochladen. 2) Mit demselben Google-Konto bei deinem neuen Chromebook anmelden. 3) Auf deinem alten Gerät bei deinem Chrome-Browser anmelden.',
            '1) Auf deinem alten Gerät bei deinem Chrome-Browser anmelden. 2) Backup and Sync herunterladen und mit einem Google-Konto anmelden. Alle Dateien von dem alten Gerät zur Sicherung in Google Drive hochladen. 3) Mit demselben Google-Konto bei deinem neuen Chromebook anmelden.',
            '1) Auf deinem alten Gerät bei deinem Chrome-Browser anmelden. 2) Mit demselben Google-Konto bei deinem neuen Chromebook anmelden. 3) Backup and Sync herunterladen und mit einem Google-Konto anmelden. Alle Dateien von dem alten Gerät zur Sicherung in Google Drive hochladen.',
          ],
          correct: 1,
          info: 'Der Umstieg von einem anderen Gerät auf ein Chromebook ist superleicht. 1) Auf deinem alten Gerät bei deinem Chrome-Browser anmelden. 2) Backup and Sync herunterladen und mit einem Google-Konto anmelden. Alle Dateien von dem alten Gerät zur Sicherung in Google Drive hochladen. 3) Mit demselben Google-Konto bei deinem neuen Chromebook anmelden.',
        },
      ],
      payload: {},
    };
  },
  methods: {
    nextQuestion() {
      let length;
      if (this.isFrench) {
        length = this.frenchQuestions.length - 1;
      }
      if (this.isGerman) {
        length = this.germanQuestions.length - 1;
      }
      length = this.questions.length - 1;
      if (this.questionNumber === length) {
        this.$emit('nextPage');
      } else {
        Vue.set(this, 'selection', null);
        Vue.set(this, 'result', null);
        Vue.set(this, 'answered', false);
        Vue.set(this, 'questionNumber', this.questionNumber + 1);
      }
    },
    submit() {
      // Answer Score
      Vue.set(this, 'answered', true);
      if (this.selection === this.currentQuestion.correct) {
        Vue.set(this, 'result', true);
        Vue.set(this, 'score', this.score + 10);
      } else {
        Vue.set(this, 'result', false);
      }

      // Axios request from app.vue
      this.payload.question = this.questionNumber + 1;
      this.payload.answer = this.selection;
      this.$emit('addQuizQuestion', this.payload);

      // Check for quiz end
      let length;
      if (this.isFrench) {
        length = this.frenchQuestions.length - 1;
      }
      if (this.isGerman) {
        length = this.germanQuestions.length - 1;
      }
      length = this.questions.length - 1;
      if (this.questionNumber === length) {
        this.$emit('addQuizScore', this.score);
      }
    },
    selectAnswer(index) {
      if (!this.answered) {
        Vue.set(this, 'selection', index);
      }
    },
  },
  computed: {
    isFrench() {
      return this.language === 'french';
    },
    isGerman() {
      return this.language === 'german';
    },
    currentQuestion() {
      if (this.isFrench) {
        return this.frenchQuestions[this.questionNumber];
      }
      if (this.isGerman) {
        return this.germanQuestions[this.questionNumber];
      }
      return this.questions[this.questionNumber];
    },
    defaultTitle() {
      if (this.isFrench) {
        return 'Vérification des Connaissances';
      }
      if (this.isGerman) {
        return 'Überprüfen Sie Ihr Wissen';
      }
      return 'Knowledge Check';
    },
  },
};
</script>

<style scoped lang="scss">
.quiz-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: flex-start;
  max-width: 700px !important;
  margin: auto;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  padding: 30px;
  color: #757575;

  .header {
    margin: 20px 0 50px 0;
  }

  .title {
    height: 41px;
    text-align: center;
    font: bold 32px 'Google Sans';
    letter-spacing: -0.48px;
    opacity: 1;
    padding-bottom: 20px;
  }

  .right {
    color: #3CBA54;
  }

  .wrong {
    color: #DB3236;
  }

  .selected {
    .selectedInner {
      stroke: #757575 !important;
    }
    .selectedRing {
      fill: #757575 !important;
    }
  }

  .additional-info {
    width: 100%;
    .info-text {
      background: #7575751A;
      padding: 20px;
      letter-spacing: 0.16px;
      font: 16px 'Google Sans';
      color: #202124;
      margin-bottom: 20px;
    }
    .incorrect {
      display: flex;
      flex-direction: column;
      .correct-answer {
        margin-bottom: 10px;
      }
      .answers {
        display: flex;
        width: 100%;
        align-items: flex-start;
        margin-bottom: 10px;
      }
      .bubble-container {
        .bubble {
          .selectedInner {
            stroke: #757575;
          }
          .selectedRing {
            fill: #757575;
          }
        }

        &:hover .hover {
          opacity: 0 !important;
        }
      }
    }
  }

  .question {
    margin-bottom: 25px;
    font-size: 18px;
  }

  .answers {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 24px;

    .bubble-container {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      .bubble {
        width: 100%;
        height: 100%;
        position: relative;
        top: -15px;
      }

      &:hover .hover {
        opacity: 0.1;
      }
    }

    .answer {
      width: 100%;
    }
  }

  .button {
    height: 52px;
    display: inline-block !important;
    line-height: 52px;
    padding: 0 25px;
    border-radius: 6px;
    opacity: 1;
    letter-spacing: 0px;
    cursor: pointer;
  }

  .grey {
    background: #757575 0% 0% no-repeat padding-box;
    color: #FFFFFF;
  }

  .grey:hover {
    background: #4885ED 0% 0% no-repeat padding-box;;
  }
}
</style>
