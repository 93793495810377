<template>
    <div id="docDetectiveContainer">
        <MinigameHeader @giveUp="giveUp()" id="detectiveHeader"
        ref="detectiveHeader" :language="this.language"/>
        <div id="docGameContainer">
          <div id="goBackButton" v-if="currentFolder.id !== 0 || showFile"
          @click="moveUpFolder()">{{ isFrench() ? "Retour" :
           (isGerman() ? "Geh Zurück" : "Go back" )}}</div>
          <div id="fileStructure" v-if="!this.showFile">
            <div id="foldersLabel" v-if="currentFolder.folders.length > 0">
              {{ isFrench() ? "Dossiers" : (isGerman() ? "Ordner" : "Folders") }}
            </div>
            <div class="foldersContainer">
              <div class="folder" v-for="folder in currentFolder.folders"
              :key="folder.id" @click="openFolder(folder.id)">
                <div>
                  <img class="folderLogo" src="../assets/images/icons/folder_icon.png"/>
                  <span class="folderName">
                    {{
                      isFrench() ? folder.frenchName :
                      (isGerman() ? folder.germanName : folder.name)
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="fileContainer" v-if="currentFolder.files.length > 0">
              <div id="filesLabel">
                {{ isFrench() ? "Fichiers" : (isGerman() ? "Dateien" : "Files" )}}
              </div>
              <div class="file" v-for="file in currentFolder.files"
              :key="file.id" @click="openFile(file.id)">
                <div>
                  <img class="fileLogo" src="../assets/images/icons/logo_docs.png"/>
                  <div class="folderName">
                    {{isFrench() ? file.frenchFileName :
                    (isGerman() ? file.germanFileName : file.fileName)}}
                  </div>
                </div>
              </div>
            </div>
            <img v-if="currentFolder.files.length === 0 && currentFolder.folders.length === 0"
            id="emptyFolder" :src="getDocImage('empty_folder')"/>
          </div>
          <div id="viewFile" v-if="showFile && currentFile">
            <div>
            <h3 id="fileName"> {{isFrench() ? currentFile.frenchFileName
              : (isGerman() ? currentFile.germanFileName : currentFile.fileName)}} </h3>
            <img id="tabIcons" src="../assets/images/docDetective/icons.png"/>
            </div>
            <img id="tabs" :src="getDocImage('tabs')"/>
            <img id="lastEdit" @click="showSidebar()"
            :src="getDocImage('last_edit_btn2')"/>
            <div id="fileContent">
              <div id="fileInner">
                <img id="imgFileInner" :src="getFileImgSrc()"/>
                <div id="docSidebar" ref="sidebar">
                  <img id="versionHistoryBar" :src="getDocImage('version_history')"/>
                  <img id="version1" :src="getDocImage('228_btn1')"/>
                  <img id="version2" :src="getDocImage('242_btn1')"/>
                  <img id="version3" :src="getDocImage('352_btn2')"/>
                  <div id="restoreVersionBtn" @click="restoreVersion()">
                    {{isFrench() ? "Restaurer cette version" :
                     (isGerman() ? "Stellen Sie diese Version wieder her"
                     : "Restore this version")}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import MinigameHeader from '@/components/MinigameHeader.vue';
import docDetectiveData from '../assets/data/docDetective.json';

export default {
  name: 'DocDetective',
  components: {
    MinigameHeader,
  },
  props: {
    language: String,
  },
  data() {
    return {
      currentFolder: docDetectiveData,
      previousFolder: docDetectiveData,
      allFolders: docDetectiveData,
      path: [0],
      showFile: false,
      currentFile: null,
      showVersions: false,
    };
  },
  mounted() {
    this.$refs.detectiveHeader.start();
  },
  methods: {
    openFolder(id) {
      this.previousFolder = this.currentFolder;
      this.currentFolder = this.currentFolder.folders.find(folder => folder.id === id);
    },
    moveUpFolder() {
      if (this.showFile) {
        this.showFile = false;
        return;
      }
      this.currentFolder = this.previousFolder;
      this.previousFolder = this.allFolders;
    },
    openFile(fileId) {
      this.currentFile = this.currentFolder.files.find(file => file.id === fileId);
      this.showFile = true;
    },
    getDocImage(fileName) {
      let adjustedFileName = fileName;
      if (this.isFrench()) {
        adjustedFileName = `fr_${fileName}`;
      }
      if (this.isGerman()) {
        adjustedFileName = `ger_${fileName}`;
      }
      const images = require.context('../assets/images/docDetective', false, /\.png$/);
      return images(`./${adjustedFileName}.png`);
    },
    getFileImgSrc() {
      const images = require.context('../assets/images/docDetective', false, /\.png$/);
      if (this.isFrench()) {
        if (this.showVersions) {
          return images('./fr_true_gem_edited.png');
        }
        return images('./fr_true_gem_page.png');
      }
      if (this.isGerman()) {
        if (this.showVersions) {
          return images('./ger_true_gem_edited_v2.png');
        }
        return images('./ger_true_gem_page.png');
      }
      if (this.showVersions) {
        return images('./true_gem_edited.png');
      }
      return images('./true_gem_page.png');
    },
    showSidebar() {
      this.showVersions = true;
      const lastEdit = document.getElementById('lastEdit');
      const sidebar = document.getElementById('docSidebar');
      if (sidebar.classList.contains('sidebarOpen')) {
        lastEdit.src = this.getLastEditSrc(2);
        sidebar.classList.remove('sidebarOpen');
        this.showVersions = false;
        return;
      }
      lastEdit.src = this.getLastEditSrc(1);
      sidebar.classList.add('sidebarOpen');
      if (this.isFrench()) {
        const versionBar = document.getElementById('versionHistoryBar');
        versionBar.classList.add('frenchVersionBar');
        const restoreVersionBtn = document.getElementById('restoreVersionBtn');
        restoreVersionBtn.classList.add('restoreVersionFrench');
      }
      // if (this.isGerman()) {
      //   const versionBar = document.getElementById('versionHistoryBar');
      //   versionBar.classList.add('frenchVersionBar');
      //   const restoreVersionBtn = document.getElementById('restoreVersionBtn');
      //   restoreVersionBtn.classList.add('restoreVersionFrench');
      // }
    },
    getLastEditSrc(index) {
      const images = require.context('../assets/images/docDetective', false, /\.png$/);
      return images(`./last_edit_btn${index}.png`);
    },
    restoreVersion() {
      this.completeDocDetective();
    },
    isFrench() {
      return (this.language === 'french');
    },
    isGerman() {
      return (this.language === 'german');
    },
    completeDocDetective() {
      this.$refs.detectiveHeader.stop();
      const elapsedTime = this.$refs.detectiveHeader.getTimeElapsed();
      let finalScore;
      if (elapsedTime < 60) {
        finalScore = 100;
      } else if (elapsedTime < 120) {
        finalScore = 50;
      } else {
        finalScore = 25;
      }
      this.$emit('completeDetective', finalScore);
    },
    giveUp() {
      this.$emit('completeDetective', 0);
    },
  },
};
</script>

<style scoped lang="scss">
  #docGameContainer {
    border-radius: 10px;
    width: 850px;
    margin: 0 auto;
    justify-content: center;
    background-color: white;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  #detectiveHeader {
  width: 850px;
  margin: 0 auto;
  }
  #goBackButton {
    background-color: grey;
    padding: 12px;
    width: 85px;
    text-align: center;
    border-radius: 6px;
    color: white;
    position: relative;
    top: 13px;
    left: 18px;
  }
  #goBackButton:hover {
    background: #4885ED 0% 0% no-repeat padding-box;;
    cursor: pointer;
  }
  #fileStructure {
    width: 800px;
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  /deep/ .hiddenContent {
    visibility: hidden;
    height: 0px;
  }
  /deep/ .versionHistory {
    color: red;
  }
  .foldersContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: white;
    margin-left: 8px;
    margin-right: 8px;
    padding-bottom: 5px;
  }
  .folder {
    display: flex;
    flex: 1 1 200px;
    max-width: 200px;
    padding-left: 5px;
    padding-right: 25px;
    padding-top: 4px;
    padding-bottom: 6px;
    margin-bottom: 10px;
    margin-right: 10px;
    border: 1px solid lightgray;
    border-radius: 6px;
  }
  .folder:hover {
    cursor: pointer;
  }
  #filesLabel {
    padding-top: 5px;
    clear: both;
    width: 100%;
    text-align: left;
    padding-bottom: 10px;
  }
  #foldersLabel {
    padding-top: 5px;
    clear: both;
    width: 100%;
    text-align: left;
    padding-bottom: 10px;
  }
  .fileContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-bottom: 20px;
    background-color: white;
  }
  .file {
    display: flex;
    flex: 1 1 150px;
    max-width: 150px;
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    text-align: center;
  }
  .file:hover {
    cursor: pointer;
  }
  .folderLogo {
    height: 18px;
    width: 18px;
    padding-right: 5px;
    padding-left: 5px;
    position: relative;
    top: 2px;
  }
  .folderName {
    width: 175px;
    height: 30px;
  }
  .fileLogo {
    height: 50px;
    width: 50px;
  }
  #moveUpFolderButton {
    padding: 10px;
    width: 150px;
    background-color: lightgrey;
    border-radius: 8px;
  }
  #fileName {
    padding-left: 20px;
    font-size: 22px;
    color: #757575;
    display: inline;
    line-height: 68px;
    position: relative;
    bottom: -6px;
  }
  #tabIcons {
    position: relative;
    top: 8px;
    left: 15px;
  }
  #tabs {
    padding-left: 20px;
  }
  #lastEdit {
    padding-left: 75px;
  }
  #lastEdit:hover {
    cursor: pointer;
  }
  #docSidebar {
    width: 0;
    transition: 0.5s;
    overflow-x: hidden;
    z-index: 6;
    float: right;
    position: relative;
    top: -524px;
    right: -70px;
    height: 100%;
    border-left: 2.5px solid #D9DADB;
  }
  .sidebarOpen {
    width: 275px !important;
    height: 560px !important;
    overflow: hidden;
  }
  #fileInner {
    max-height: 500px;
    overflow: none;
  }
  #imgFileInner {
    width: 100%;
  }
  #versionHistoryBar {
    margin-top: 10px;
  }
  #version1 {
    position: relative;
    top: -463px;
    width: 276px;
    left: -1px;
  }
  #version2 {
    position: relative;
    top: -467px;
    width: 276px;
    left: -1px;
  }
  #version3 {
    position: relative;
    top: -471px;
    width: 276px;
    left: -1px;
  }
  #restoreVersionBtn {
    position: relative;
    top: -365px;
    left: 47px;
    background-color: grey;
    padding: 12px;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 160px;
    text-align: center;
    border-radius: 6px;
    color: white;
  }
  .restoreVersionFrench, .restoreVersionGerman {
    width: 170px !important;
    position: relative !important;
    left: 42px !important;
  }
  #restoreVersionBtn:hover {
    background: #4885ED 0% 0% no-repeat padding-box;;
    cursor: pointer;
  }
  #fileContent {
    margin: 20px;
    margin-top: 10px;
    margin-bottom: 25px;
    border: 1px solid grey;
    border-radius: 3px;
    padding: 10px;
    padding-left: 55px;
    padding-right: 70px;
    padding-top: 30px;
    max-height: 520px;
    overflow-y: hidden;
  }
  .docDetectiveModal {
    padding: 25px;
    border-radius: 8px;
    width: 400px;
    position: absolute;
    left: 50%;
    margin-left: -210px;
    top: 38%;
    margin-top: -75px;
    padding-bottom: 0px;
    background-color: white;
    box-shadow: 2px 4px 4px grey;
    border: 1px solid #9A9FA44D;
  }
  .docModalContinue {
    background-color: grey;
    padding: 13px;
    width: 80px;
    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 30px;
    border-radius: 8px;
    color: white;
  }
  .docModalContinue:hover {
    background: #4885ED 0% 0% no-repeat padding-box;;
    cursor: pointer;
  }
  .frenchVersionBar, .germanVersionBar {
    position: relative;
    top: -15px;
    border-left: .75px solid #D9DADB;
  }
  #versionHistoryButton{
    color: #71A3F6;
    position: relative;
    left: 620px;
    top: -18px;
  }
  #emptyFolder {
    width: 250px;
    height: 250px;
    padding: 30px;
    position: relative;
    left: 242px;
  }
  #versionHistoryButton:hover {
    cursor: pointer;
  }
</style>
