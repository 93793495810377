<template>
  <div class="admin-container">
    <AdminHeader v-on:navigate="navigate" :manager="manager" :isMain="isMain"/>
    <component v-bind:is="currentComponent"
      :isMain="isMain"
      :userAuth="token"
      :resetPassword="resetPassword"
      v-on:loginAlt="loginManager"
      v-on:navigate="navigate"
      v-on:login="login"
    ></component>
  </div>
</template>

<script>
import Vue from 'vue';
import AdminHeader from '@/components/Admin/AdminHeader.vue';
import AdminLogin from '@/components/Admin/AdminLogin.vue';
import AdminDownload from '@/components/Admin/AdminDownload.vue';
import AdminLeaderboard from '@/components/Admin/AdminLeaderboard.vue';
import AdminPassword from '@/components/Admin/AdminPassword.vue';
import AdminManager from '@/components/Admin/AdminManager.vue';

export default {
  name: 'AdminContainer',
  components: {
    AdminHeader,
    AdminLogin,
    AdminDownload,
    AdminLeaderboard,
    AdminPassword,
    AdminManager,
  },
  data() {
    return {
      currentComponent: AdminLogin,
      token: null,
      manager: false,
      resetPassword: false,
    };
  },
  computed: {
    isLoggedIn() {
      return (this.token !== null);
    },
    isMain() {
      switch (this.currentComponent) {
        case AdminLogin:
        case AdminPassword:
          return true;
        default:
          return false;
      }
    },
  },
  mounted() {
    if (this.$route.query.code) {
      Vue.set(this, 'resetPassword', true);
      Vue.set(this, 'currentComponent', AdminPassword);
    }
  },
  methods: {
    navigate(value) {
      switch (value) {
        case 'download':
          Vue.set(this, 'currentComponent', AdminDownload);
          break;
        case 'leaderboard':
          Vue.set(this, 'currentComponent', AdminLeaderboard);
          break;
        case 'password':
          Vue.set(this, 'currentComponent', AdminPassword);
          break;
        case 'admin':
          Vue.set(this, 'currentComponent', AdminManager);
          break;
        default:
          Vue.set(this, 'token', null);
          Vue.set(this, 'currentComponent', AdminLogin);
          break;
      }
    },
    loginManager(token) {
      Vue.set(this, 'token', token);
      Vue.set(this, 'manager', true);
      this.navigate('admin');
    },
    login(token) {
      Vue.set(this, 'token', token);
      this.navigate('download');
    },
  },
};
</script>

<style lang="scss">
.vs__dropdown-toggle {
  height: 48px;
  border: 1px solid rgba(117, 117, 117, 0.5);
  border-radius: 6px;
  padding-left: 6px;
}
.vs__selected {
  line-height: 38px !important;
  color: #4885ED;
}
.vs__search {
  color: rgb(117, 117, 117);
  ::placeholder {
    color: rgba(117, 117, 117, 0.5);
  }
}
.vs__dropdown-menu {
  box-shadow: none;
  border: 1px solid rgba(117, 117, 117, 0.5);
  li {
    color: #757575;
  }
}
.vs__dropdown-option--highlight {
  background-color: rgba(72, 133, 237, 0.5);
}
</style>

<style lang="scss">
.admin-container {
  width: 100%;
  height: 100%;
  .title {
    font-weight: bold;
    color: #757575;
    font-size: 32px;
  }
  .button {
    height: 52px;
    display: inline-block;
    line-height: 52px;
    border-radius: 6px;
    letter-spacing: 0px;
    padding: 0 26px;
    cursor: pointer;
  }

  .grey {
    background-color: #757575;
    color: white;
    &:hover {
      background: #4885ED 0% 0% no-repeat padding-box;;
    }
  }

  .white {
    border: 1px solid #D7D7D7;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: #757575;
  }

  .description {
    text-align: left;
    font: 16px 'Google Sans';
    letter-spacing: 0px;
    color: #757575;
    opacity: 1;
  }

  .input {
    border: 1px solid rgba(117, 117, 117, 0.5);
    width: 300px;
    font-size: 14px;
    margin: 4px 0;
    border-radius: 6px;
    background: white;
    height: 43px;
    padding: 0 0 0 16px;
    letter-spacing: 0px;
    color: #757575;
    outline: none;
  }
  .incomplete {
    border: 1px solid #DB3236;
    border-radius: 6px;
  }

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::placeholder {
    color: #757575;
    opacity: 0.5;
  }

  .input:focus {
    border: 1px solid #4885ED;
    border-radius: 6px;
  }
}
</style>
