<template>
  <div class="admin-leaderboard">
    <!-- Delete Player Modal -->
    <div class="cover" v-if="selPlayer">
      <div class="remove-modal">
        <div class="subtitle">Are you sure you want to remove this stat?</div>
          <div class="player">
            <div class="placement"><b>{{formatPlacement(selPlayer.index)}}</b></div>
            <div class="first-name">{{selPlayer.firstName}}</div>
            <div v-if="!hasNoLastName" class="last-name">
                {{formatLastName(selPlayer.lastName)}}
            </div>
            <div v-if="!hasNoStoreNumber" class="store-number">{{selPlayer.storeNumber}}</div>
            <div class="score">{{formatScore(selPlayer.score)}}</div>
          </div>
          <div class="buttons">
            <div class="button white" @click="selPlayer = null">No</div>
            <div class="button grey" @click="deletePlayer">Yes</div>
          </div>
      </div>
    </div>
    <!-- Leaderboard Component -->
    <div class="title">Edit leaderboard</div>
    <div class="url">
      <div class="description">URL</div>
      <vSelect class="style-chooser" v-on:input="loadLeaderboard"
        v-model="url" :searchable="false" :options="options"
      />
    </div>
    <div class="scroll-container">
      <div class="headers" v-if="leaderboard && leaderboard.length">
        <div class="first-name">First</div>
        <div v-if="!hasNoLastName" class="last-name">Last</div>
        <div v-if="!hasNoStoreNumber" class="store-number">Store</div>
        <div class="score">Score</div>
      </div>
      <div class="leaderboard-container" v-if="leaderboard && leaderboard.length">
        <div class="player" v-for="(player, index) in leaderboard" :key="index">
          <div class="placement"><b>{{formatPlacement(index)}}</b></div>
          <div class="first-name">{{player.firstName}}</div>
          <div v-if="!hasNoLastName" class="last-name">{{formatLastName(player.lastName)}}</div>
          <div v-if="!hasNoStoreNumber" class="store-number">{{player.storeNumber}}</div>
          <div class="score">{{formatScore(player.score)}}</div>
          <div class="remove" @click="deletePopup(index)">Remove</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'AdminLeaderboard',
  components: {
    vSelect,
  },
  props: { userAuth: String },
  data() {
    return {
      url: '',
      selected: 0,
      selPlayer: null,
      options: [
        {
          label: 'https://adventuresofsherlockchromes.com/c/24g',
          code: '24g',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/bestbuy',
          code: 'bestbuy',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/google',
          code: 'google',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/fnacdarty',
          code: 'fnacdarty',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/leclerc',
          code: 'leclerc',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/bestbuyca',
          code: 'bestbuyca',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/boulanger',
          code: 'boulanger',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/elkjop',
          code: 'elkjop',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/mediamarktmsh',
          code: 'mediamarktmsh',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/dcp',
          code: 'dcp',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/jbhifi',
          code: 'jbhifi',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/aus',
          code: 'aus',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/experience',
          code: 'experience',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/events',
          code: 'events',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/mediamarktmsd',
          code: 'mediamarktmsd',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/de',
          code: 'de',
        },
      ],
      leaderboard: null,
      company: null,
    };
  },
  computed: {
    hasNoLastName() {
      switch (this.company) {
        case 'fnacdarty':
        case 'boulanger':
        case 'leclerc':
        case 'elkjop':
        case 'mediamarktmsh':
          return true;
        default:
          return false;
      }
    },
    hasNoStoreNumber() {
      switch (this.company) {
        case 'google':
        case 'experience':
        case 'mediamarktmsd':
        case 'events':
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    loadLeaderboard(obj) {
      Vue.set(this, 'company', obj.code);
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_API}admin/score?company=${this.company}`,
        headers: { Authorization: this.userAuth },
      })
        .then((response) => {
          Vue.set(this, 'leaderboard', response.data.data);
        })
        .catch((error) => {
          if (error.response.status === 401) this.$emit('navigate', 'login');
        });
    },
    deletePopup(index) {
      Vue.set(this, 'selPlayer', this.leaderboard[index]);
      Vue.set(this.selPlayer, 'index', index);
    },
    deletePlayer() {
      axios({
        method: 'delete',
        url: `${process.env.VUE_APP_API}users/${this.selPlayer.id}`,
        headers: { Authorization: this.userAuth },
      })
        .then(() => {
          this.leaderboard.splice(this.selPlayer.index, 1);
          Vue.set(this, 'selPlayer', null);
        })
        .catch((error) => {
          if (error.response.status === 401) this.$emit('navigate', 'login');
        });
    },
    formatLastName(lastName) {
      if (lastName !== '') return lastName;
      return '';
    },
    formatPlacement(placement) {
      return (`0${placement + 1}`.slice(-2));
    },
    formatScore(score) {
      return score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
};
</script>

<style scoped lang="scss">
.admin-leaderboard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  .cover {
    position: absolute;
    height: 9999px;
    z-index: 2;
    width: 100%;
    top: 0;
    left: 0;
    background-color:rgba(0, 0, 0, 0.75);
    .remove-modal {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 500px;
      z-index: 1;
      color: #757575;
      padding: 30px 50px;
      border: 1px solid rgba(154, 159, 164, 0.3);
      background-color: white;
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .subtitle {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 10px;
      }
      .player {
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: 1px solid rgba(215, 215, 215, 1);
        height: 45px;
        width: 370px;
        margin-bottom: 20px;
      }
      .grey {
        margin-left: 15px;
      }
    }
  }
  .scroll-container {
    width: 480px;
    height: 400px;
    margin-top: 30px;
    overflow-y: auto;
    .first-name, .last-name {
      width: 75px;
    }
    .store-number, .score {
       width: 60px;
    }
    .headers {
      display: flex;
      color: #757575;
      font-weight: bold;
      margin-bottom: 5px;
      .first-name { margin-left: 62px; }
      .last-name, .store-number, .score {
        margin-left: 15px;
      }
    }
    .leaderboard-container {
      width: 463px;
      display: flex;
      flex-direction: column;
      // .selected {
      //   color: white !important;
      //   background-color: #4885ED !important;
      //   border: 1px solid #4885ED !important;
      // }
      .player {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        border: 2px solid rgba(215, 215, 215, 1);
        color: #757575;
        .remove {
          color: rgba(219, 50, 54, 1);
          font-size: 14px;
          margin-left: auto;
          margin-right: 15px;
        }
        .placement {
          width: 30px;
        }
        div {
          margin-left: 15px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .player:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  .description {
    margin-bottom: 4px;
  }
  .url {
    margin-top: 24px;
    .style-chooser {
      width: 543px;
    }
  }
}
</style>
