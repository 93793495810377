<template>
  <div class="performance-modal">
    <div class="background"></div>
    <div class="popup">
      <div class="description">{{getText}}</div>
      <div class="button grey" @click="$emit('closePerformanceModal')">
        {{ isFrench ? 'Continuer' : 'Continue' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PerformanceModal',
  props: {
    language: String,
  },
  data() {
    return {
      english: 'This game can only be played on a computer.',
      french: 'Ce jeu ne peut être joué que sur un ordinateur.',
      german: 'Dieses Spiel kann nur an einem Computer gespielt werden.',
    };
  },
  methods: {
    toLink() {
      window.open('https://www.google.com/chromebook/switch/');
    },
    resetGame() {
      this.$emit('resetGame');
    },
  },
  computed: {
    getText() {
      if (this.isFrench) return this.french;
      return this.english;
    },
    isFrench() {
      return (this.language === 'french');
    },
    isGerman() {
      return (this.language === 'german');
    },
  },
};
</script>

<style scoped lang="scss">
.performance-modal {
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;

  .popup {
    width: 80%;
    max-width: 575px;
    display: flex;
    padding: 30px 30px;
    align-items: center;
    flex-direction: column;
    background-color: white;
    opacity: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    box-shadow: 0px 4px 13px #0000000D;
    border: 1px solid #9A9FA44D;

    .description {
      text-align: left;
      font: 18px 'Google Sans';
      letter-spacing: 0px;
      color: #757575;
      opacity: 1;
      margin-bottom: 35px;
    }

    .button {
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      opacity: 1;
      padding: 0 20px;
      letter-spacing: 0px;
      cursor: pointer;
    }

    .grey {
      background: #757575 0% 0% no-repeat padding-box;
      color: #FFFFFF;
      width: 76px;
    }

    .grey:hover {
      background: #4885ED 0% 0% no-repeat padding-box;;
    }
  }

  .background {
    background-color: #000000;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }
}
</style>
