<template>
  <div class="admin-manager">
    <div class="add">
      <input v-model="email" class="input" placeholder="john.doe@gmail.com" type="text">
      <div class="button grey" @click="addAdmin">Add</div>
    </div>
    <div class="scroll-container">
      <div class="container" v-if="admins && admins.length">
        <div class="admin" v-for="(admin, index) in admins" :key="index">
          <div class="description">{{ admin.email }}</div>
          <div class="remove" @click="removePlayer(index)">Remove</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';

export default {
  name: 'AdminDownload',
  components: {},
  props: { userAuth: String },
  data() {
    return {
      admins: null,
      email: null,
    };
  },
  computed: {},
  mounted() {
    this.getAdmins();
  },
  methods: {
    removePlayer(index) {
      axios({
        method: 'delete',
        url: `${process.env.VUE_APP_API}admin/`,
        data: {
          id: this.admins[index].id,
        },
        headers: { Authorization: this.userAuth },
      })
        .then(() => { this.getAdmins(); })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) this.$emit('navigate', 'login');
        });
    },
    addAdmin() {
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_API}admin/`,
        data: {
          email: this.email,
        },
        headers: { Authorization: this.userAuth },
      })
        .then(() => { this.getAdmins(); })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) this.$emit('navigate', 'login');
        });
    },
    getAdmins() {
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_API}admin/`,
        headers: { Authorization: this.userAuth },
      })
        .then((response) => {
          console.log(response);
          Vue.set(this, 'admins', response.data.data);
        })
        .catch((error) => {
          if (error.response.status === 401) this.$emit('navigate', 'login');
        });
    },
  },
};
</script>

<style scoped lang="scss">
.admin-manager {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  .add {
    input {
      margin-right: 15px;
    }
  }
  .scroll-container {
    width: 440px;
    height: 400px;
    margin-top: 20px;
    overflow-y: auto;
    .container {
      width: 423px;
      margin: auto;
      display: flex;
      flex-direction: column;
      .admin {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        height: 48px;
        border: 2px solid rgba(215, 215, 215, 1);
        color: #757575;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        .description {
          width: 250px;
        }
        .remove {
          color: rgba(219, 50, 54, 1);
        }
      }
    }
  }
}
</style>
