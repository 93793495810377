<template>
  <div class="default-container">
    <div ref="storySlide" id="storySlide">
      <div class="content-container" :class="{flipped: slideCount % 2 !== 0}">
        <div class="illustration-container">
          <img class="illustration" :key="slideCount" :src="currentImage">
        </div>
        <div class="text">
          <div v-for="(paragraph, index) in currentParagraph"
            :key="index" class="description" >
            {{paragraph}}
          </div>
        </div>
      </div>
    </div>
    <div class="dot-container">
      <div class="dot"
        :class="{selected: slideCount === index - 1}"
        v-for="index in slides.length" :key="index"
      ></div>
    </div>
    <div class="buttons">
      <div @click="previousSlide" v-if="slideCount != 0"
        :disabled='transitioning' class="button white">
        {{french ? 'Retour' : (german ? 'Zurück' : 'Back')}}
      </div>
      <div @click="nextSlide" class="button grey" :disabled='transitioning'
        :class="{'summary' : lastSlide}">
        {{this.continueText}}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'StoryConclusion',
  components: {
  },
  props: {
    msg: String,
    language: String,
  },
  data() {
    return {
      slideCount: 0,
      transitioning: false,
      slides: [
        { // ============= PAGE 1 =============
          imagePNG: '/img/Story26.png',
          title: 'Story Conclusion',
          paragraphs: [
            'When Sherlock Chromes opens the thumb drive on Margaret’s Chromebook, there is only a single JPEG stored on the device.',
            'Chromes opens the JPEG to find a scan of a note that reads, “Do a barrel roll. It’s going down.”',
          ],
          frenchParas: [
            'Lorsque Sherlock Chromes ouvre la clé USB sur le Chromebook de Margaret, un seul fichier JPEG est stocké sur l’appareil.',
            'Chromes ouvre le JPEG pour trouver une copie d’une note qui dit : « Fais rouler le tonneau. ça va descendre. »',
          ],
          germanParas: [
            'Als Sherlock Chromes das USB-Laufwerk auf Margarets Chromebook öffnet, findet er darauf nur eine einzige JPEG-Datei.',
            'Chromes öffnet die JPEG-Datei und findet eine eingescannte Notiz mit folgendem Inhalt: „Rolle das Fass. Dort geht\'s abwärts.“',
          ],
        },
        { // ============= PAGE 2 =============
          imagePNG: '/img/Story27.png',
          title: 'Story Conclusion',
          paragraphs: [
            'Chromes looks toward the wine barrel where the Chromebook was hidden.',
            '“Well,” mutters Chromes, “Here goes nothing.”',
            'He turns the empty barrel on its side and rolls it away to reveal a hidden trap door underneath.',
            'Chromes quickly squats to open the door. “It’s going down indeed...”',
          ],
          frenchParas: [
            'Chromes se retourne vers le tonneau de vin où le Chromebook était caché.',
            '« Bon », marmonne Chromes, « Allons-y. »',
            'Il tourne le tonneau vide sur le côté et le fait rouler pour accéder à une trappe cachée en dessous.',
            'Chromes s’accroupit rapidement pour ouvrir la porte. « Il descend en effet. »',
          ],
          germanParas: [
            'Chromes sieht zu dem Weinfass hinüber, in dem das Chromebook versteckt war.',
            '„Mmmhh“, grummelt Chromes, „Das klappt doch nie ...“.',
            'Er kippt das leere Fass auf die Seite und rollt es beiseite. Darunter kommt eine verborgene Falltür zum Vorschein.',
            'Chromes bückt sich, um die Tür zu öffnen. „Es geht tatsächlich abwärts ...“',
          ],
        },
        { // ============= PAGE 3 =============
          imagePNG: '/img/Story28.png',
          title: 'Story Conclusion',
          paragraphs: [
            'Suddenly, the trap door bursts open and a wild nest of hair explodes from the ground.',
            '“Jiminy Cricket!” exclaims the ball of hair and jewelry. “It’s dark down there! I was one hour away from going absolutely mad.”',
          ],
          frenchParas: [
            'Soudain, la trappe s’ouvre et un nid de poils sauvages s’écroule au sol.',
            '« Bon sang ! » s’exclame la boule de cheveux et de bijoux. « Il fait sombre en bas ! J’étais à deux doigts de devenir complètement folle. »',
          ],
          germanParas: [
            'Plötzlich fliegt die Falltür auf und ein wild zerzauster Haarschopf schießt aus dem Boden.',
            '„Du meine Güte!“ entfährt es dem Haar- und Schmuckknäuel. „Ist das dunkel da unten! Ich wäre fast verrückt geworden. Viel hat nicht gefehlt.“',
          ],
        },
        { // ============= PAGE 4 =============
          imagePNG: '/img/Story29.png',
          title: 'Story Conclusion',
          paragraphs: [
            'Sherlock Chromes helps what appears to be a rather disheveled and slightly disturbed Molly Diamond out of the trap door.',
            'She quickly recounts the entire tale - Margaret’s plan to forge the diamond certificate, Molly’s refusal to play along, and finally Margaret’s ploy to lock her in the cellar when she discovered Molly had been trying to contact the jeweler...',
          ],
          frenchParas: [
            'Sherlock Chromes aide ce qui semble être une Molly Diamond plutôt ébouriffée et légèrement perturbée à sortir de la trappe.',
            'Elle raconte rapidement toute l’histoire : le plan de Margaret pour falsifier le certificat de diamant, le refus de Molly de jouer le jeu, et enfin le stratagème de Margaret pour l’enfermer dans la cave quand elle a découvert que Molly avait essayé de contacter le bijoutier...',
          ],
          germanParas: [
            'Sherlock Chromes hilft der, wie es scheint, ziemlich zerzausten und leicht verwirrten Molly Diamond zur Falltür hinaus.',
            'Sie erzählt die ganze Geschichte im Schnelldurchlauf: Margarets Plan, das Echtheitszertifikat für den Diamanten zu fälschen, Mollys Weigerung dabei mitzumachen und schließlich Margarets List, sie im Keller einzusperren, als sie herausfand, dass Molly versucht hatte, den Juwelier zu kontaktieren ...',
          ],
        },
        { // ============= PAGE 5 =============
          imagePNG: '/img/Story30.png',
          title: 'Story Conclusion',
          paragraphs: [
            '“It’s par for the course with my dear sister”, chatters Molly. “I hope we can finally put all of this forgery tomfoolery to rest.”',
            '“I’ll make sure of it,” replies Chromes.',
          ],
          frenchParas: [
            '« Il fallait s’y attendre de la part de ma chère sœur », affirme Molly. « J’espère que nous pourrons enfin mettre un terme à cette histoire de contrefaçon. »',
            '« Je m’en assurerai », répond Chromes.',
          ],
          germanParas: [
            '„Das ist typisch für meine werte Schwester“, platzt es aus Molly heraus. „Ich hoffe, wir können diesen ganzen Unsinn mit der Fälschung schnell zu einem Ende bringen.“',
            '„Dafür werde ich sorgen“, gibt Chromes zurück.',
          ],
        },
        { // ============= PAGE 6 =============
          imagePNG: '/img/Story31.png',
          title: 'Story Conclusion',
          paragraphs: [
            'Chromes says goodbye to Molly and heads uptown to share what he’s discovered with the authorities.',
            'As he walks away, he observes quietly to himself, “Looks like the old saying isn’t so true after all, diamonds aren’t always a girl’s best friend.”',
          ],
          frenchParas: [
            'Chromes dit au revoir à Molly et se rend en ville pour partager ce qu’il a découvert avec les autorités',
            'En s’éloignant, il se dit tranquillement à lui-même : « On dirait que le vieux dicton n’est pas si vrai après tout, les diamants ne sont pas toujours les meilleurs amis des femmes ».',
          ],
          germanParas: [
            'Er verabschiedet sich von Molly und macht sich auf den Weg in die Stadt, um die Behörden über seine Erkenntnisse zu informieren.',
            'Im davon gehen sagt er leise zu sich selbst, „Offensichtlich stimmt die alte Redewendung doch nicht ganz, Diamanten sind nicht die besten Freunde für ein Mädchen.“',
          ],
        },
      ],
    };
  },
  methods: {
    previousSlide() {
      if (this.transitioning) {
        return;
      }
      setTimeout(() => this.slideInPrev(), 450);
      const slideContent = document.getElementById('storySlide');
      slideContent.classList.add('slideOutTransitionPrev');
      slideContent.classList.add('opacity0');
      this.transitioning = true;
    },
    nextSlide() {
      if (this.slideCount === this.slides.length - 1) {
        if (this.french) this.$emit('addQuizScore', 0);
        this.$emit('nextPage');
        this.$emit('muteAll');
      } else {
        if (this.transitioning) {
          return;
        }
        const slideContent = document.getElementById('storySlide');
        setTimeout(() => this.slideIn(), 450);
        this.transitioning = true;
        slideContent.classList.add('slideOutTransition');
        slideContent.classList.add('opacity0');
      }
    },
    slideIn() {
      Vue.set(this, 'slideCount', this.slideCount + 1);
      this.$emit('playVoiceover', `voiceover_conclusion${this.slideCount + 1}`);
      const slideContent = document.getElementById('storySlide');
      slideContent.classList.remove('slideOutTransition');
      slideContent.classList.add('slideInTransition');
      setTimeout(() => this.resetSlideTransition(), 400);
    },
    slideInPrev() {
      Vue.set(this, 'slideCount', this.slideCount - 1);
      this.$emit('playVoiceover', `voiceover_conclusion${this.slideCount + 1}`);
      const slideContent = document.getElementById('storySlide');
      slideContent.classList.remove('slideOutTransitionPrev');
      slideContent.classList.add('slideInTransitionPrev');
      setTimeout(() => this.resetSlideTransition(), 400);
    },
    resetSlideTransition() {
      const slideContent = document.getElementById('storySlide');
      this.transitioning = false;
      slideContent.classList.remove('slideOutTransition');
      slideContent.classList.remove('slideInTransition');
      slideContent.classList.remove('slideOutTransitionPrev');
      slideContent.classList.remove('slideInTransitionPrev');
      slideContent.classList.remove('opacity0');
    },
    playVoiceover() {
      this.$emit('playVoiceover', `voiceover_conclusion${this.slideCount + 1}`);
    },
  },
  computed: {
    continueText() {
      if (this.french) return (this.lastSlide ? 'Voir le résumé du jeu' : 'Continuer');
      if (this.german) return (this.lastSlide ? 'Spielübersicht' : 'Weiter');
      return (this.lastSlide ? 'View game summary' : 'Continue');
    },

    french() {
      return (this.language === 'french');
    },
    german() {
      return (this.language === 'german');
    },

    currentImage() {
      if (this.french && this.currentSlide.frenchImage) {
        return this.currentSlide.frenchImage;
      }
      if (this.german && this.currentSlide.germanImage) {
        return this.currentSlide.germanImage;
      }
      return this.currentSlide.imagePNG;
    },

    currentParagraph() {
      if (this.french) return this.currentSlide.frenchParas;
      if (this.german) return this.currentSlide.germanParas;
      return this.currentSlide.paragraphs;
    },

    lastSlide() {
      return (this.slideCount === this.slides.length - 1);
    },

    currentSlide() {
      return this.slides[this.slideCount];
    },
  },
  mounted() {
    this.playVoiceover();
  },
};
</script>

<style scoped lang="scss">
.opacity0 {
  opacity: 0;
}
.slideOutTransition {
  transition: all .4s;
  transform: translateX(-200px);
}
.slideInTransition {
  transition: all .4s;
  opacity: 1;
  transform: translateX(-400px);
  position: relative;
  right: -400px;
}
.slideInTransitionPrev {
  transition: all .4s;
  opacity: 1;
  transform: translateX(400px);
  position: relative;
  left: -400px;
}
.slideOutTransitionPrev {
  transition: all .4s;
  transform: translateX(200px);
}

.default-container {
  max-width: 1150px !important;
  max-height: 750px;

  *::-ms-backdrop, #storySlide {
    transform: translate(-50%, 0);
    position: relative;
    left: 50%;
  }

  .flipped {
    flex-direction: row-reverse !important;
  }

  .dot-container {
    display: flex;
    justify-content: center;
    .selected {
      background-color: #4885ED !important;
    }
    .dot {
      width: 10px;
      margin: 0 5px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba(117, 117, 117, 0.5);
    }
  }
  .content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .illustration-container {
      height: 400px;
      width: 500px;
      .illustration {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
      }
    }
    .text {
      width: 451px;
      .title {
        margin-bottom: 20px;
      }
      .description {
        margin-bottom: 15px;
      }
    }
  }

  .buttons {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    .summary {
      width: 202px !important;
    }
    .grey {
      width: 118px;
    }
    .white {
      margin-right: 20px;
      width: 86px;
    }
  }
}
</style>
