<template>
  <div class="admin-header">
    <div v-if="isMain" class="header-login">
      <img class="image" src="@/assets/images/chromebook_logo_crop.png">
      <div class="title">Sherlock Chromes <span class="blue">Admin</span></div>
    </div>
    <div v-else class="header-main">
      <div class="left">
        <img class="image" src="@/assets/images/logo_chrome.png">
        <div class="title">Sherlock Chromes <span class="blue">Admin</span></div>
      </div>
      <div class="right">
        <div class="link" @click="$emit('navigate', 'download')">Reports</div>
        <div class="link" @click="$emit('navigate', 'leaderboard')">Leaderboard</div>
        <div v-if="manager" class="link" @click="$emit('navigate', 'admin')">Manager</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AdminHeader',
  props: {
    isMain: Boolean,
    manager: Boolean,
  },
  components: {},
  data() {
    return {
      temp: 'example',
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="scss">
.header-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .image {
    width: 150px;
    margin: 30px 0 36px;
  }
}
.header-main {
  padding: 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  .left {
    display: flex;
    align-items: center;
    .image {
      width: 35px;
      margin-right: 15px;
    }
    .title {
      font-size: 20px !important;
    }
  }
  .right {
    display: flex;
    .link {
      color: #757575;
    }
    .link:not(:last-child) {
      margin-right: 40px;
    }
  }
}
</style>
