<template>
  <div id="app">
    <AudioManager :language="language" ref="sherlockAudio"/>
    <component
      :userProp="user"
      :score="score"
      :minigamesScores="minigameScores"
      :time="time"
      :leaderboard="leaderboard"
      :language="language"
      :section="section"
      v-on:startGame="startGame"
      v-on:surveyComplete="postSurvey"
      v-on:resetGame="resetGame"
      v-on:updateUser="updateUser"
      v-on:updateScore="updateScore"
      v-on:addQuizQuestion='addQuizQuestion'
      v-on:addQuizScore="addQuizScore"
      v-on:nextPage="incrementSlide"
      v-on:lastPage="decrementSlide"
      v-bind:is="slides[currentSlide]"
      @muteAll="muteAllAudio"
      @muteVoiceover="muteVoiceover"
      @playVoiceover="playVoiceover"
      @playSong="playSong"
    ></component>
    <PerformanceModal
      :language="language"
      v-if="showPerformanceModal"
      v-on:closePerformanceModal="closePerformanceModal"
    />
    <ExitModal
      :language="language"
      v-if="showExitModal"
      v-on:closeExitModal="closeExitModal"/>
    <HomeButton v-if="currentSlide !== 0" class="home-button" @click="goToHome"/>
    <div v-if="currentSlide !== 0" class="mute-button" @click="toggleVolume">
      <UnmuteVolumeIcon class="unmuteIcon" v-if="!volumeMuted"/>
      <MuteVolumeIcon class="muteIcon" v-if="volumeMuted"/>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import QuizComponent from '@/components/QuizComponent.vue';
import PerformanceModal from '@/components/PerformanceModal.vue';
import AdminContainer from '@/components/Admin/AdminContainer.vue';
import ErrorComponent from '@/components/ErrorComponent.vue';
import WelcomePage from '@/components/WelcomePage.vue';
import StoryIntroduction from '@/components/StoryIntroduction.vue';
import PanoramaPage from '@/components/PanoramaPage.vue';
import StoryConclusion from '@/components/StoryConclusion.vue';
import LeaderBoard from '@/components/LeaderBoard.vue';
import ExitModal from '@/components/ExitModal.vue';
import SurveyComponent from '@/components/SurveyComponent.vue';
import AudioManager from '@/components/AudioManager.vue';
import Blank from '@/components/Blank.vue';
import HomeButton from '@/assets/images/home.svg';
import MuteVolumeIcon from '@/assets/images/mute_volume.svg';
import UnmuteVolumeIcon from '@/assets/images/unmute_volume.svg';

export default {
  name: 'app',
  components: {
    AdminContainer,
    HomeButton,
    WelcomePage,
    QuizComponent,
    ErrorComponent,
    StoryIntroduction,
    PanoramaPage,
    StoryConclusion,
    LeaderBoard,
    ExitModal,
    PerformanceModal,
    SurveyComponent,
    AudioManager,
    MuteVolumeIcon,
    UnmuteVolumeIcon,
    Blank,
  },
  data() {
    return {
      language: 'english',
      slides: [
        WelcomePage,
        SurveyComponent,
        StoryIntroduction,
        PanoramaPage,
        StoryConclusion,
        QuizComponent,
        LeaderBoard,
        SurveyComponent,
        Blank,
        SurveyComponent,
      ],
      timer: undefined,
      time: 0, // seconds
      currentSlide: 0,
      score: 0,
      section: 'beginning', // used for surveys
      minigameScores: [],
      showExitModal: false,
      showPerformanceModal: false,
      user: {
        firstName: null,
        lastName: null,
        storeNumber: null,
      },
      userAuth: null,
      company: null,
      companies: [
        '24g',
        'bestbuy',
        'google',
        'fnacdarty',
        'bestbuyca',
        'boulanger',
        'elkjop',
        'mediamarktmsh',
        'dcp',
        'jbhifi',
        'leclerc',
        'aus',
        'experience',
        'events',
        'mediamarktmsd',
        'de',
      ],
      leaderboard: null,
      volumeMuted: false,
      preloadList: [
        '/img/Story2.png',
        '/img/Story3.png',
        '/img/Story4.png',
        '/img/Story5.png',
        '/img/Story5-french.png',
        '/img/Story5-german.png',
        '/img/Study.png',
        '/img/Living.png',
        '/img/Cellar.png',
        '/img/CellarDark.png',
      ],
    };
  },
  created() {
    if (this.isValidRoute) {
      Vue.set(this, 'company', this.$route.path.split('/')[2].toLowerCase());
      this.toggleLanguage();
      const imageArray = [];
      for (let i = 0; i < this.preloadList.length; i += 1) {
        imageArray[i] = new Image();
        imageArray[i].src = this.preloadList[i];
      }
    } else if (this.isAdminRoute) {
      Vue.set(this.slides, 0, AdminContainer);
    } else {
      Vue.set(this.slides, 0, ErrorComponent);
    }
    if (this.isMobile) {
      Vue.set(this, 'showPerformanceModal', true);
    }
  },
  methods: {
    toggleLanguage() {
      switch (this.company) {
        case 'fnacdarty':
        case 'boulanger':
        case 'leclerc':
          Vue.set(this, 'language', 'french');
          break;
        case 'de':
          Vue.set(this, 'language', 'german');
          break;
        default:
          break;
      }
    },
    startGame() {
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_API}tracking`,
        headers: { Authorization: this.userAuth },
        data: {
          room: 'study',
          action: 'game',
          value: 'start',
        },
      });
    },
    endGame() {
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_API}tracking`,
        headers: { Authorization: this.userAuth },
        data: {
          room: 'cellar',
          action: 'game',
          value: 'end',
        },
      });
    },
    postSurvey(value) {
      let url = '';
      switch (this.section) {
        case 'beginning':
          url = 'ratingBefore';
          this.section = 'ending';
          break;
        case 'ending':
          url = 'ratingAfter';
          this.section = 'tutorial';
          break;
        case 'tutorial':
          url = 'trainingRating';
          break;
        default:
          break;
      }
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_API}users/${url}`,
        headers: { Authorization: this.userAuth },
        data: { rating: value },
      });
    },
    resetGame() {
      this.muteAllAudio();
      Object.assign(this.$data, this.$options.data());
      Vue.set(this, 'company', this.$route.path.split('/')[2].toLowerCase());
      this.toggleLanguage();
      Vue.set(this, 'currentSlide', 0);
      this.section = 'beginning';
    },
    startTimer() {
      this.timer = setInterval(() => {
        Vue.set(this, 'time', this.time + 1);
      }, 1000); // every second
    },
    stop() {
      clearInterval(this.timer);
    },
    closePerformanceModal() {
      Vue.set(this, 'showPerformanceModal', false);
    },
    closeExitModal(toHome) {
      Vue.set(this, 'showExitModal', false);
      if (toHome) {
        this.muteAllAudio();
        Vue.set(this, 'currentSlide', 0);
      }
    },
    updateScore(score, indScores) {
      Vue.set(this, 'score', score + this.getTimeScore);
      Vue.set(this, 'minigameScores', indScores);
    },
    addQuizQuestion(payload) {
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_API}users/question`,
        data: {
          question: payload.question,
          answer: payload.answer,
        },
        headers: { Authorization: this.userAuth },
      })
        .then(() => { this.getScores(); })
        .catch((error) => { console.log(error); });
    },
    addQuizScore(gameScore) {
      if (this.language !== 'french' && this.language !== 'german') {
        this.minigameScores.push({ id: 7, gameType: 'quiz', score: gameScore });
      }
      this.minigameScores.push({ id: 8, gameType: 'time', score: this.getTimeScore });
      Vue.set(this, 'score', this.score + gameScore);
      this.postScore();
      this.sortMinigames();
      this.endGame();
    },
    updateUser(user) {
      Vue.set(this, 'user', user);
      this.postUser();
    },
    getScores() {
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_API}users/scoreData`,
        headers: { Authorization: this.userAuth },
      })
        .then((response) => {
          Vue.set(this, 'leaderboard', response.data.data);
        })
        .catch((error) => { console.log(error); });
    },
    postScore() {
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_API}users/score`,
        data: {
          score: this.score,
          time: this.time,
        },
        headers: { Authorization: this.userAuth },
      })
        .then(() => { this.getScores(); })
        .catch((error) => { console.log(error); });
    },
    postUser() {
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_API}users/auth`,
        data: {
          email: '',
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          storeNumber: this.user.storeNumber.toString(),
          company: this.company,
        },
      })
        .then((response) => {
          Vue.set(this, 'userAuth', response.data.data.token);
        })
        .catch((error) => { console.log(error); });
    },
    incrementSlide() {
      if (this.currentSlide !== this.slides.length - 1) {
        Vue.set(this, 'currentSlide', this.currentSlide + 1);
        if (this.currentSlide === 2) this.startTimer();
        if (this.currentSlide === 3) this.stop();
      }
    },
    sortMinigames() {
      const scoreArray = this.minigameScores;
      scoreArray.sort((a, b) => ((a.id > b.id) ? 1 : -1));
      Vue.set(this, 'minigameScores', scoreArray);
    },
    decrementSlide() {
      if (this.currentSlide > 0) {
        Vue.set(this, 'currentSlide', this.currentSlide - 1);
      }
    },
    muteAllAudio() {
      this.$refs.sherlockAudio.pauseAudio();
    },
    muteVoiceover() {
      this.$refs.sherlockAudio.pauseVoiceover();
    },
    toggleVolume() {
      this.volumeMuted = !this.volumeMuted;
      this.$refs.sherlockAudio.toggleMute();
    },
    playSong(trackName) {
      this.$refs.sherlockAudio.playSong(trackName, true);
    },
    playVoiceover(voiceoverName) {
      this.$refs.sherlockAudio.playVoiceover(voiceoverName);
    },
    goToHome() {
      this.section = 'beginning';
      Vue.set(this, 'showExitModal', true);
      clearInterval(this.timer);
      Vue.set(this, 'time', 0);
    },
  },
  computed: {
    isMobile() {
      if (window.innerWidth <= 962) return true;
      return false;
    },
    isValidRoute() {
      const pathArray = this.$route.path.split('/');
      return (pathArray[1] === 'c' && this.companies.includes(pathArray[2].toLowerCase()));
    },
    isAdminRoute() {
      return (this.$route.path === '/admin');
    },
    getTimeScore() {
      if (this.time < 600) return 100;
      if (this.time < 900) return 75;
      return 50;
    },
  },
};
</script>


<style lang="scss">
@import './assets/fonts/fonts.css';

#app, html, body {
  position: absolute;
  width: 100%;
  height: 100%;
  // overflow: hidden;
  margin: 0;
  font-family: 'Google Sans';

  ::-webkit-scrollbar-track {
    display: none;
  }
  ::-webkit-scrollbar {
    width: 12px;
    background-color: #FFF;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #D7D7D7;
  }

  .default-container {
    width: 60%;
    max-width: 1000px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;

    .title {
      text-align: left;
      font: bold 32px 'Google Sans';
      letter-spacing: -0.48px;
      color: #757575;
      opacity: 1;
    }

    .large-title {
      text-align: left;
      font: bold 75px 'Google Sans';
      letter-spacing: -1.13px;
      opacity: 1;
      color: #757575;
    }

    .description {
      text-align: left;
      font: 18px 'Google Sans';
      letter-spacing: 0px;
      color: #757575;
      opacity: 1;
    }

    .button {
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      opacity: 1;
      letter-spacing: 0px;
      cursor: pointer;
    }

    .grey {
      background: #757575 0% 0% no-repeat padding-box;
      color: #FFFFFF;
    }

    .grey:hover {
      background: #4885ED 0% 0% no-repeat padding-box;;
    }

    .white {
      border: 1px solid #D7D7D7;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      color: #757575;
    }
  }

  .home-button{
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
    top: 50px;
    left: 50px;
  }

  .muteIcon, .unmuteIcon{
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 50px;
    top: 50px;
    left: 110px;
  }

  .volumeIcon {
    width: 50px;
    height: 50px;
    z-index: 1;
  }

  .closeIcon {
    width: 40px;
    height: 40px;
    z-index: 2;
    position: relative;
    top: -48px;
    left: 5px;
  }

  .home-button:active {
    path {
      fill: #4885ed;
    }
  }
}
</style>
