<template>
  <div>
    <!-- Point Breakdown -->
    <div class="default-container" v-if="breakdown">
      <div class="large-title">{{translate('title')}}</div>
      <div class="title">
        {{translate('score')}} <span>{{formatScore(score)}} {{translate('points')}}
      </span></div>
      <div class="title"><b>{{translate('breakdown')}}</b></div>
      <div class="point-container">
        <div class="game" v-for="(game, index) in minigamesScores" :key="index">
          <div>{{formatTitle(game.gameType)}}</div>
          <div>{{game.score}} PTS</div>
        </div>
      </div>
      <div class="button grey" @click="breakdown = false">
        {{ french ? 'Continuer' : (german ? 'Zurück' : 'Continue') }}
      </div>
    </div>
    <!-- Leaderboard -->
    <div class="default-container" v-else>
      <div class="large-title">{{translate('board')}}</div>
      <div class="scroll-container">
        <div class="leaderboard-container">
          <div class="player" :class="{'selected' : selected === index}"
            v-for="(player, index) in leaderboard.scores" :key="index">
            <div class="placement">{{formatPlacement(index)}}</div>
            <div class="name">{{player.firstName}} {{formatLastName(player.lastName)}}</div>
            <div class="score">{{formatScore(player.score)}} PTS</div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <div class="button white" @click="breakdown = true">
          {{ french ? 'Retour' : (german ? 'Benötigte Zeit' : 'Back') }}
        </div>
        <div class="button grey" @click="$emit('nextPage')">
          {{ french ? 'Continuer' : (german ? 'Zurück' : 'Continue') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'LeaderBoard',
  props: {
    score: Number,
    minigamesScores: Array,
    time: Number,
    leaderboard: Object,
    language: String,
  },
  data() {
    return {
      breakdown: true,
      highscores: [],
      text: {
        title: {
          english: 'Congratulations!',
          french: 'Félicitations !',
          german: 'Glückwunsch!',
        },
        score: {
          english: 'Your score:',
          french: 'Votre score:',
          german: 'Deine Punktzahl:',
        },
        points: {
          english: 'points',
          french: 'points',
          german: 'Punkte',
        },
        breakdown: {
          english: 'Score breakdown:',
          french: 'Répartition des points :',
          german: 'Punktzahl im Einzelnen:',
        },
        board: {
          english: 'Leaderboard',
          french: 'Classement',
          german: 'Bestenliste',
        },
      },
    };
  },
  methods: {
    translate(key) {
      if (this.french) return this.text[key].french;
      if (this.german) return this.text[key].german;
      return this.text[key].english;
    },
    getScores() {
      axios({
        method: 'get',
        url: 'https://dev-api-2637-1-2.24preview.com/users/scoreReport',
      })
        .then((response) => {
          this.formatResponse(response);
        })
        .catch((error) => { console.log(error); });
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = `0${time % 60}`.slice(-2);
      return `${minutes}:${seconds}`;
    },
    formatLastName(lastName) {
      if (this.hasNoLastName) return '';
      return lastName;
    },
    formatTitle(type) {
      if (this.french) {
        switch (type) {
          case 'memoryMatch':
            return 'Mini jeu n° 3 : Jeu de mémoire';
          case 'simonSays':
            return 'Mini jeu n° 1 : Jacques a dit';
          case 'docDetective':
            return 'Mini jeu n° 4 : Inspecteur Doc';
          case 'slidingPuzzle':
            return 'Mini jeu n° 2 : Puzzle coulissant';
          case 'quiz':
            return 'Knowledge Checks';
          case 'time':
            return `Temps : ${this.formatTime(this.time)}`;
          default:
            return '';
        }
      } else if (this.german) {
        switch (type) {
          case 'memoryMatch':
            return 'Minispiel 3 : Memory';
          case 'simonSays':
            return 'Minispiel 1 : Simon sagt';
          case 'docDetective':
            return 'Minispiel 4 : Detektiv Doc';
          case 'slidingPuzzle':
            return 'Minispiel 2 : Puzzle';
          case 'quiz':
            return 'Knowledge Checks';
          case 'time':
            return `Zeit : ${this.formatTime(this.time)}`;
          default:
            return '';
        }
      } else {
        switch (type) {
          case 'memoryMatch':
            return 'Mini Game #3: Memory Match';
          case 'simonSays':
            return 'Mini Game #1: Simon Says';
          case 'docDetective':
            return 'Mini Game #4: Doc Detective';
          case 'slidingPuzzle':
            return 'Mini Game #2: Sliding Puzzle';
          case 'quiz':
            return 'Knowledge Checks';
          case 'time':
            return `Time: ${this.formatTime(this.time)}`;
          default:
            return '';
        }
      }
    },
    formatResponse(response) {
      const tempArray = response.data.split(/,|\n/);
      for (let i = 4; i < tempArray.length; i += 4) {
        const player = {};
        player.firstName = tempArray[i].replace(/"/g, '');
        player.lastName = tempArray[i + 1].replace(/"/g, '');
        player.storeNumber = tempArray[i + 2].replace(/"/g, '');
        player.score = tempArray[i + 3].replace(/"/g, '');
        this.highscores.push(player);
      }
    },
    formatPlacement(placement) {
      return (`0${placement + 1}`.slice(-2));
    },
    formatScore(score) {
      return score.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },
  computed: {
    hasNoLastName() {
      const pathArray = this.$route.path.split('/')[2].toLowerCase();
      switch (pathArray) {
        case 'fnacdarty':
        case 'boulanger':
        case 'leclerc':
        case 'elkjop':
        case 'mediamarktmsh':
          return true;
        default:
          return false;
      }
    },
    selected() {
      return (this.leaderboard.rank - 1 || null);
    },
    french() {
      return (this.language === 'french');
    },
    german() {
      return (this.language === 'german');
    },
    isFnacdarty() {
      return (this.$route.path.split('/')[2] === 'fnacdarty');
    },
  },
};
</script>

<style scoped lang="scss">
.default-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .large-title {
    font-size: 60px !important;
  }
  .scroll-container {
    width: 400px;
    height: 370px;
    margin-top: 20px;
    overflow-y: auto;
    .leaderboard-container {
      width: 380px;
      display: flex;
      flex-direction: column;
      .selected {
        color: white !important;
        background-color: #4885ED !important;
        border: 1px solid #4885ED !important;
      }
      .player {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        border: 1px solid #757575;
        font-weight: bold;
        color: #757575;
        .placement {
          width: 35px;
          margin-left: 20px;
        }
        .name {
          margin-left: 30px;
        }
        .score {
          margin-left: auto;
          margin-right: 20px;
        }
      }
      .player:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
  .point-container {
    width: 500px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .game {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 10px;
      color: white;
      font: bold 18px 'Google Sans';
      height: 45px;
      margin: 2px 0;
    }
    .game:nth-child(1), .game:nth-child(5), .game:nth-child(9) {
      background-color: #3CBA54;
    }
    .game:nth-child(2), .game:nth-child(6), .game:nth-child(10) {
      background-color: #4885ED;
    }
    .game:nth-child(3), .game:nth-child(7), .game:nth-child(10) {
      background-color: #DB3236
    }
    .game:nth-child(4), .game:nth-child(8), .game:nth-child(11) {
      background-color: #F4C20D;
    }
  }

  .buttons {
    .white {
      width: 108px;
    }
    display: flex;
    width: 260px;
    justify-content: space-between;
  }

  .title {
    font-weight: normal !important;
    margin-top: 5px;
    span {
      color: #4885ED;
      font-weight: bold;
    }
  }
  .button {
    margin-top: 50px;
    width: 128px;
  }
}
</style>
