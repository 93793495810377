<template>
  <div class="default-container">
    <div ref="storySlide" id="storySlide">
      <div class="content-container" :class="{'flipped': slideCount % 2 !== 0}">
        <div class="illustration-container">
          <img class="illustration" :key="slideCount" :src="currentImage">
        </div>
        <div class="text">
          <div v-for="(paragraph, index) in currentParagraph"
            :key="index" class="description" >
            {{paragraph}}
          </div>
        </div>
      </div>
    </div>
    <div class="dot-container">
      <div class="dot"
        :class="{selected: slideCount === index - 1}"
        v-for="index in slides.length" :key="index"
      ></div>
    </div>
    <div class="buttons">
      <div @click="previousSlide" :disabled='transitioning'
      v-if="slideCount != 0" class="button white">
        {{french ? 'Retour' : (german ? 'Zurück' : 'Back')}}
      </div>
      <div @click="nextSlide" :disabled='transitioning'
      class="button grey" :class="{'begin' : lastSlide}">
        {{this.continueText}}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'StoryIntroduction',
  components: {
  },
  props: {
    msg: String,
    language: String,
  },
  data() {
    return {
      slideCount: 0,
      transitioning: false,
      slides: [
        { // ============= PAGE 1 =============
          imagePNG: '/img/Story2.png',
          title: 'Story Introduction',
          englishParas: [
            'Detective Sherlock Chromes gets a call from the town jeweler, asking him to come into the shop to look into a case.',
            'When he arrives, the jeweler informs him that infamous twin diamond collectors, Molly and Margaret Diamond, have made a groundbreaking new discovery.',
          ],
          frenchParas: [
            'L’inspecteur Sherlock Chromes reçoit un appel du bijoutier du quartier, lui demandant de venir à la boutique pour élucider une affaire.',
            'À son arrivée, le bijoutier l’informe que les célèbres jumelles Molly et Margaret Diamond, collectionneuses de diamants, ont fait une nouvelle découverte révolutionnaire.',
          ],
          germanParas: [
            'Der Detektiv, Sherlock Chromes, erhält einen Anruf vom Juwelier der Stadt. Dieser bittet ihn, in sein Juweliergeschäft zu kommen und sich einen Fall anzusehen.',
            'Bei seinem Eintreffen vor Ort informiert ihn der Juwelier, dass die berühmt-berüchtigten Diamantensammler-Zwillinge, Molly und Margaret Diamond, einen sensationellen neuen Fund gemacht haben.',
          ],
        },
        { // ============= PAGE 2 =============
          imagePNG: '/img/Story3.png',
          title: 'Story Introduction',
          englishParas: [
            'Margaret claims to have found the world’s first True Red Diamond, the rarest gem known to humanity. A discovery that, if confirmed, would have a price tag like no one has ever seen.',
            'What’s alarming, however, is that her twin sister, Molly Diamond, is nowhere to be found...',
          ],
          frenchParas: [
            'Margaret prétend avoir trouvé le premier véritable diamant rouge du monde, la plus rare pierre précieuse qui soit connue de l’humanité. Il s’agit d’une découverte qui, si confirmée, aurait un prix sans précédent.',
            'Toutefois, ce qui est inquiétant, c’est que sa sœur jumelle Molly Diamond demeure introuvable...',
          ],
          germanParas: [
            'Margaret behauptet, den weltweit ersten, echten roten Diamanten gefunden zu haben. Ein Fund, der sollte er bestätigt werden, einen schwindelerregenden Preis erzielen würde.',
            'Umso besorgniserregender daher, dass ihre Zwillingsschwester, Molly Diamond, wie vom Erdboden verschluckt ist ...',
          ],
        },
        { // ============= PAGE 3 =============
          imagePNG: '/img/Story4.png',
          title: 'Story Introduction',
          englishParas: [
            'Margaret Diamond swears to the press that Molly’s merely away on holiday, but the jeweler believes there’s foul play afoot.',
            'Earlier that morning, an anonymous letter arrived at his shop.',
            '“The house is empty until sundown. Look under the desk in the study. Everything you need is in the Chromebook.”',
            'Below that was nothing more than an address.',
          ],
          frenchParas: [
            'Margaret Diamond assure devant la presse que Molly est simplement en vacances, mais le bijoutier pense qu’il se trame un meurtre.',
            'Plus tôt ce matin-là, une lettre anonyme a atterri dans sa boutique.',
            '« La maison reste vide jusqu’au coucher du soleil. Regardez sous le bureau, tout ce dont vous avez besoin se trouve dans Chromebook. »',
            'En dessous, il n’y avait rien de plus qu’une adresse.',
          ],
          germanParas: [
            'Margaret Diamond ihrerseits schwört, dass Molly im Urlaub sei, aber dem Juwelier kommt das alles komisch vor.',
            'Denn am Morgen war ein anonymer Brief in seinem Geschäft eingetroffen.',
            '„Bis Sonnenuntergang ist niemand im Haus. Schauen Sie im Arbeitszimmer und unter dem Schreibtisch nach. Alles, was Sie brauchen, ist auf dem Chromebook.“',
            'Darunter nichts weiter als eine Adresse.',
          ],
        },
        { // ============= PAGE 4 =============
          imagePNG: '/img/Story5.png',
          frenchImage: '/img/Story5-french.png',
          germanImage: '/img/Story5-german.png',
          title: 'Story Introduction',
          englishParas: [
            '“Well, well,” mutters Chromes, folding the letter into his trench coat. “There’s certainly something afoot, isn’t there.',
            'He shakes the jeweler’s hand and heads to the door.',
            '“Thanks for the tip, old pal. Now if you’ll excuse me, I’ve got a Chromebook to find.”',
          ],
          frenchParas: [
            '« Tiens, tiens », marmonne Chromes, en pliant la lettre dans son trench-coat. « Il y a certainement quelque chose qui se prépare, n’est-ce pas ? »',
            'Il serre la main du bijoutier et se dirige vers la porte.',
            '« Merci pour le tuyau, mon vieux.. Maintenant, si vous voulez bien m’excuser, j’ai un Chromebook à trouver. »',
          ],
          germanParas: [
            '„Verstehe“, murmelt Chromes, und schiebt den Brief in die Tasche seines Trenchcoats. „Ja, da ist ganz bestimmt etwas im Gange.“',
            'Er gibt dem Juwelier die Hand und geht in Richtung Tür.',
            '„Vielen Dank für den Tipp, alter Freund. Wenn Sie mich jetzt entschuldigen wollen, ich muss ein Chromebook finden.“',
          ],
        },
      ],
    };
  },
  methods: {
    previousSlide() {
      if (this.transitioning) {
        return;
      }
      setTimeout(() => this.slideInPrev(), 450);
      const slideContent = document.getElementById('storySlide');
      slideContent.classList.add('slideOutTransitionPrev');
      slideContent.classList.add('opacity0');
      this.transitioning = true;
    },
    nextSlide() {
      if (this.slideCount === this.slides.length - 1) {
        this.$emit('nextPage');
        this.$emit('muteAll');
      } else {
        if (this.transitioning) {
          return;
        }
        const slideContent = document.getElementById('storySlide');
        setTimeout(() => this.slideIn(), 450);
        this.transitioning = true;
        slideContent.classList.add('slideOutTransition');
        slideContent.classList.add('opacity0');
      }
    },
    slideIn() {
      Vue.set(this, 'slideCount', this.slideCount + 1);
      this.$emit('playVoiceover', `voiceover_intro${this.slideCount + 1}`);
      const slideContent = document.getElementById('storySlide');
      slideContent.classList.remove('slideOutTransition');
      slideContent.classList.add('slideInTransition');
      setTimeout(() => this.resetSlideTransition(), 400);
    },
    slideInPrev() {
      Vue.set(this, 'slideCount', this.slideCount - 1);
      this.$emit('playVoiceover', `voiceover_intro${this.slideCount + 1}`);
      const slideContent = document.getElementById('storySlide');
      slideContent.classList.remove('slideOutTransitionPrev');
      slideContent.classList.add('slideInTransitionPrev');
      setTimeout(() => this.resetSlideTransition(), 400);
    },
    resetSlideTransition() {
      const slideContent = document.getElementById('storySlide');
      this.transitioning = false;
      slideContent.classList.remove('slideOutTransition');
      slideContent.classList.remove('slideInTransition');
      slideContent.classList.remove('slideOutTransitionPrev');
      slideContent.classList.remove('slideInTransitionPrev');
      slideContent.classList.remove('opacity0');
    },
    playVoiceover() {
    },
  },
  computed: {
    currentImage() {
      if (this.french && this.currentSlide.frenchImage) {
        return this.currentSlide.frenchImage;
      }
      if (this.german && this.currentSlide.germanImage) {
        return this.currentSlide.germanImage;
      }
      return this.currentSlide.imagePNG;
    },

    french() {
      return (this.language === 'french');
    },
    german() {
      return (this.language === 'german');
    },

    continueText() {
      if (this.french) return (this.lastSlide ? 'Démarrer le jeu' : 'Continuer');
      if (this.german) return (this.lastSlide ? 'Spiel beginnen' : 'Weiter');
      return (this.lastSlide ? 'Begin game' : 'Continue');
    },

    currentParagraph() {
      if (this.french) return this.currentSlide.frenchParas;
      if (this.german) return this.currentSlide.germanParas;
      return this.currentSlide.englishParas;
    },

    lastSlide() {
      return (this.slideCount === this.slides.length - 1);
    },

    currentSlide() {
      return this.slides[this.slideCount];
    },
  },
  mounted() {
    this.$emit('playVoiceover', 'voiceover_intro1');
    this.$emit('playSong', 'theme');
  },
};
</script>

<style scoped lang="scss">
.default-container {
  max-width: 1150px !important;
  max-height: 750px;

  *::-ms-backdrop, #storySlide {
    transform: translate(-50%, 0);
    position: relative;
    left: 50%;
  }

  .flipped {
    flex-direction: row-reverse !important;
  }

  .dot-container {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    .selected {
      background-color: #4885ED !important;
    }
    .dot {
      width: 10px;
      margin: 0 5px;
      height: 10px;
      border-radius: 50%;
      background-color: rgba(117, 117, 117, 0.5);
    }
  }
  .content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    .illustration-container {
      height: 400px;
      width: 500px;
      .illustration {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
      }
    }
    .text {
      width: 451px;
      .title {
        margin-bottom: 20px;
      }
      .description {
        margin-bottom: 15px;
      }
    }
  }

  .buttons {
    margin-top: 35px;
    display: flex;
    justify-content: center;
    .begin {
      width: 138px !important;
    }
    .grey {
      width: 118px;
    }
    .white {
      margin-right: 20px;
      width: 86px;
    }
  }
  .opacity0 {
    opacity: 0;
  }
  .slideOutTransition {
    transition: all .4s;
    transform: translateX(-200px);
  }
  .slideInTransition {
    transition: all .4s;
    opacity: 1;
    transform: translateX(-400px);
    position: relative;
    right: -400px;
  }
  .slideInTransitionPrev {
    transition: all .4s;
    opacity: 1;
    transform: translateX(400px);
    position: relative;
    left: -400px;
  }
  .slideOutTransitionPrev {
    transition: all .4s;
    transform: translateX(200px);
  }
}
</style>
