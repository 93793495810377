<template>
    <div id="timerContainer">
          <div id="simonProgress">
            <img class="simonBubble" :src="getProgressSource(1)">
            <img class="simonBubble" :src="getProgressSource(2)">
            <img class="simonBubble" :src="getProgressSource(3)">
          </div>
          <div v-if="showTimer" id="simonTimer">
            {{isFrench() ? "Temps restant " :
            (isGerman() ? "Verbleibende Zeit: " : "Time remaining: ")}}
            {{time / 1000}}
            {{
              isFrench() ? "secondes" : ( isGerman() ? "Sekunden" : "Seconds")
            }}
          </div>
    </div>
</template>

<script>
export default {
  name: 'SimonTimer',
  data() {
    return {
      timer: undefined,
      showTimer: false,
      time: 0,
      simonTime: 0,
      score: 0,
    };
  },
  props: {
    language: String,
  },
  methods: {
    start() {
      this.showTimer = true;
      this.timer = setInterval(() => {
        this.time -= 1000;
        if (this.time <= 0) {
          this.$emit('timeUp');
          clearInterval(this.timer);
        }
      }, 1000);
    },
    stop() {
      clearInterval(this.timer);
    },
    reset() {
      this.time = this.simonTime;
      clearInterval(this.timer);
    },
    getProgressSource(goalScore) {
      const images = require.context('../assets/images/icons/status', false, /\.png$/);
      if (this.score >= goalScore) {
        return images('./answer_right.png');
      }
      return images('./answer_wrong.png');
    },
    isFrench() {
      return (this.language === 'french');
    },
    isGerman() {
      return (this.language === 'german');
    },
  },
};
</script>
<style scoped>

 #simonTimer {
   color: #FFFFFF;
   display: block;
   width: 100%;
   margin: 0 auto;
   position: relative;
   top: -10px;
   left: 200px;
 }
 #simonProgress {
   display: flex;
   flex-direction: row;
   padding: 5px;
   background-color: #ffffff;
   border-radius: 6px;
   width: 80px;
   position: relative;
   z-index: 1000;
   top: 65px;
   left: 315px;
 }
 .simonBubble {
   height: 15px;
   width: 15px;
   padding-left: 5px;
   padding-right: 5px;
 }
</style>
