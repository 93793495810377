<template>
  <div class="default-container">
    <div class="large-title">The Adventures of Sherlock Chromes</div>
    <div class="chromes">
      <img src="@/assets/images/chromes_splash.png">
    </div>
    <div class="brought-container">
      <div class="title">Brought to you by</div>
      <div class="chromebook">
        <img class="image" src="@/assets/images/chromebook_logo_crop.png">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorComponent',
};
</script>

<style scoped lang="scss">
.default-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .large-title {
    text-align: center !important;
    font-size: 48px !important;
    line-height: 65px;
    margin-bottom: 20px;
  }
  .brought-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .title {
      text-align: center !important;
      margin: 0 15px 9px 0;
    }
  }
  .chromes {
    width: 400px;
    height: 410px;
    margin-bottom: 30px;
    img {
      max-width: 100%;
    }
  }
}
</style>
