<template>
  <div class="admin-download">
    <div class="title">Download report</div>
    <div class="url">
      <div class="description">URL</div>
      <vSelect class="style-chooser" v-model="url" :searchable="false" :options="options"/>
    </div>
     <!-- <div class="url">
      <div class="description">Report Type</div>
      <vSelect class="style-chooser" v-model="reportType"
      :searchable="false" :options="reportOptions"/>
    </div> -->
    <div class="date-selector">
      <div class="from">
        <div class="description">From</div>
        <Datepicker v-model="from" class="datepicker"/>
      </div>
      <div class="to">
        <div class="description">To</div>
        <Datepicker v-model="to" class="datepicker"/>
      </div>
      <div
        class="button grey"
        @click="getCSVs"
        :disabled="isGenerating"
      >{{ isGenerating ? 'Generating...' : 'Download CSV' }}</div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import axios from 'axios';
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'AdminDownload',
  components: {
    vSelect,
    Datepicker,
  },
  props: { userAuth: String },
  data() {
    return {
      url: '',
      isGenerating: false,
      reportType: {
        label: 'All Reports',
        code: 'all',
      },
      from: null,
      to: null,
      options: [
        {
          label: 'https://adventuresofsherlockchromes.com/c/24g',
          code: '24g',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/bestbuy',
          code: 'bestbuy',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/google',
          code: 'google',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/fnacdarty',
          code: 'fnacdarty',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/bestbuyca',
          code: 'bestbuyca',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/leclerc',
          code: 'leclerc',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/boulanger',
          code: 'boulanger',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/elkjop',
          code: 'elkjop',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/mediamarktmsh',
          code: 'mediamarktmsh',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/dcp',
          code: 'dcp',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/jbhifi',
          code: 'jbhifi',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/aus',
          code: 'aus',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/experience',
          code: 'experience',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/events',
          code: 'events',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/mediamarktmsd',
          code: 'mediamarktmsd',
        },
        {
          label: 'https://adventuresofsherlockchromes.com/c/de',
          code: 'de',
        },
      ],
      // reportOptions: [
      //   {
      //     label: 'All Reports',
      //     code: 'all',
      //   },
      //   {
      //     label: 'Averages Report',
      //     code: 'average',
      //   },
      //   {
      //     label: 'Company Answers',
      //     code: 'cAnswers',
      //   },
      //   {
      //     label: 'Full Users Report',
      //     code: 'user',
      //   },
      //   {
      //     label: 'User Ratings',
      //     code: 'ratings',
      //   },
      //   {
      //     label: 'User Scores',
      //     code: 'scores',
      //   },
      //   {
      //     label: 'User Answers',
      //     code: 'answers',
      //   },
      // ],
      data: [],
      csv: [],
      answers: [
        [
          'Chromebook. Wi-Fi. Google Account',
          'Chromebook. Google Account. 7 Digit Password',
          'Chromebook. Google Account. Face Detection Photo',
        ], [
          'Verified Boot',
          'Sandboxing',
          'Automatic updates',
          'Data Encryption',
          'All of the above',
        ], [
          'Google Maps',
          'Google Play Store',
          'Google Sheets',
        ], [
          'Backup & Switch',
          'Switch & Sync',
          'Backup & Sync',
          'Make the switch',
        ], [
          'Improved design and formfactor',
          'Top Intel processors built in',
          'Larger storage size',
          'Higher RAM',
          'All of the Above',
        ], [
          '0GB',
          '5GB',
          '10GB',
          '15GB',
        ], [
          '1) Download Backup and Sync and sign in with a Google Account. Upload all files from the old device to be safely stored in Google Drive 2) Sign in to your new Chromebook using the same Google Account 3) Sign in to your Chrome browser on your old device',
          '1) Sign in to your Chrome browser on your old device 2) Download Backup and Sync and sign in with a Google Account. Upload all files from the old device to be safely stored in Google Drive 3) Sign in to your new Chromebook using the same Google Account',
          '1) Sign in to your Chrome browser on your old device 2) Sign in to your new Chromebook using the same Google Account 3) Download Backup and Sync and sign in with a Google Account. Upload all files from the old device to be safely stored in Google Drive',
        ],
      ],
      frenchAnswers: [
        [
          'Chromebook. Wi-Fi. compte Google',
          'Chromebook. compte. Google Mot de passe à 7 chiffres',
          'Chromebook. compte. Google Reconnaissance faciale',
        ], [
          'Verified Boot',
          'Sandboxing',
          'Mises à jour automatiques',
          'Chiffrement des données',
          'Toutes ces réponses',
        ], [
          'Google Maps',
          'Google Play Store',
          'Google Sheets',
        ], [
          'Sauvegarde et transfert',
          'Transfert et synchronisation',
          'Sauvegarde et synchronisation',
          'Effectuer le changement',
        ], [
          'Amélioration de l’esthétique',
          'Intégration des meilleurs processeurs Intel',
          'Un espace de stockage plus importante',
          'Une capacité RAM plus élevée',
          'Tout ce qui précède',
        ], [
          '0GB',
          '5GB',
          '10GB',
          '15GB',
        ], [
          '1) Téléchargez Sauvegarde et synchronisation et connectez-vous avec votre compte Google. Téléchargez tous les fichiers de l\'ancien appareil pour les stocker en toute sécurité dans Google Drive 2) Connectez-vous à votre nouveau Chromebook en utilisant le même compte Google 3) Connectez-vous à votre navigateur Chrome sur votre ancien appareil',
          '1) Connectez-vous à votre navigateur Chrome sur votre ancien appareil 2) Téléchargez Sauvegarde et synchronisation et connectez-vous avec un compte Google. Téléchargez tous les fichiers de l\'ancien appareil pour les stocker en toute sécurité dans Google Drive 3) Connectez-vous à votre nouveau Chromebook en utilisant le même compte Google',
          '1) Connectez-vous à votre navigateur Chrome sur votre ancien appareil 2) Connectez-vous à votre nouveau Chromebook en utilisant le même compte Google 3) Téléchargez Sauvegarde et synchronisation  et connectez-vous avec un compte Google. Téléchargez tous les fichiers de l\'ancien appareil pour qu\'ils soient stockés en toute sécurité dans Google Drive',
        ], [
          'Faux',
          'Vrai',
        ],
      ],
      germanAnswers: [
        [
          'Chromebook, WLAN, Google-Konto',
          'Chromebook, Google-Konto, 7-stelliges Passwort',
          'Chromebook, Google-Konto, Foto für Gesichtserkennung',
        ], [
          'Verifzierter Bootmodus',
          'Sandboxing',
          'Automatische Updates',
          'Datenverschlüsselung',
          'Alle oben genannten',
        ], [
          'Google Maps',
          'Google Play Store',
          'Google Tabellen',
        ], [
          'Backup & Switch',
          'Switch & Sync',
          'Backup & Sync',
          'Make the switch',
        ], [
          'Verbesserter Design und Formfaktor',
          'Integrierte Top-Intel-Prozessoren',
          'Mehr Speicherplatz',
          'Mehr Arbeitsspeicher',
          'Alle oben genannten',
        ], [
          '0 GB',
          '5 GB',
          '10 GB',
          '15 GB',
        ], [
          '1) Backup and Sync herunterladen und mit einem Google-Konto anmelden. Alle Dateien von dem alten Gerät zur Sicherung in Google Drive hochladen. 2) Mit demselben Google-Konto bei deinem neuen Chromebook anmelden. 3) Auf deinem alten Gerät bei deinem Chrome-Browser anmelden.',
          '1) Auf deinem alten Gerät bei deinem Chrome-Browser anmelden. 2) Backup and Sync herunterladen und mit einem Google-Konto anmelden. Alle Dateien von dem alten Gerät zur Sicherung in Google Drive hochladen. 3) Mit demselben Google-Konto bei deinem neuen Chromebook anmelden.',
          '1) Auf deinem alten Gerät bei deinem Chrome-Browser anmelden. 2) Mit demselben Google-Konto bei deinem neuen Chromebook anmelden. 3) Backup and Sync herunterladen und mit einem Google-Konto anmelden. Alle Dateien von dem alten Gerät zur Sicherung in Google Drive hochladen.',
        ],
      ],
      averages: [],
      averagesHeader: [],
      finalHeader: ['Retailers', 'Avg Score', 'Avg Time (Seconds)', 'Avg User Rating (NPS Pre)',
        'Avg User Rating (NPS Post)', 'Avg Training Rating', 'Avg Knowledge Check 1', 'Avg Knowledge Check 2',
        'Avg Knowledge Check 3', 'Avg Knowledge Check 4', 'Avg Knowledge Check 5',
        'Avg Knowledge Check 6', 'Avg Knowledge Check 7', 'Avg Knowledge Check 8', 'Avg Knowledge Total',
      ],
      users: 0,
      headerPushed: false,
    };
  },
  computed: {
    isValid() {
      if (this.url && this.reportType) return true;
      return false;
    },
    headers() {
      const header = ['"First Name"', '"Last Name"', '"Store Number"',
        '"Score"', '"Total Time (Seconds)"', '"Rating Before"', '"Rating After"', '"Training Rating"',
      ];
      // switch (this.reportType.code) {
      //   case 'average':
      //     header = [];
      //     break;
      //   case 'cAnswers':
      //     header = ['"q1"', '"q2"', '"q3"', '"q4"', '"q5"', '"q6"', '"q7"', '"q8"',
      //       '"q1Correct"', '"q2Correct"', '"q3Correct"', '"q4Correct"', '"q5Correct"',
      //       '"q6Correct"', '"q7Correct"', '"q8Correct"',
      //     ];
      //     break;
      //   case 'user':
      //     header = ['"First Name"', '"Last Name"', '"Store Number"', '"Score"',
      // '"Total Time (Seconds)"', '"Rating Before"', '"Rating After"', '"Training Rating"'];
      //     break;
      //   case 'ratings':
      //     header = ['"First Name"', '"Last Name"', '"Store Number"',
      // '"Rating Before"', '"Rating After"', '"Training Rating"'];
      //     break;
      //   case 'scores':
      //     header = ['"First Name"', '"Last Name"', '"Store Number"', '"Score"'];
      //     break;
      //   case 'answers':
      //     header = ['"First Name"', '"Last Name"', '"q1"', '"q2"', '"q3"',
      //       '"q4"', '"q5"', '"q6"', '"q7"', '"q8"', '"q1Correct"', '"q2Correct"',
      //       '"q3Correct"', '"q4Correct"', '"q5Correct"',
      // '"q6Correct"', '"q7Correct"', '"q8Correct"',
      //     ];
      //     break;
      //   default:
      //     break;
      // }
      return header;
    },
  },
  methods: {
    formatDate(date, string) {
      if (date === null) return '';
      const d = new Date(date);
      const month = `${d.getMonth() + 1}`;
      const day = `${d.getDate()}`;
      const year = `${string}${d.getFullYear()}`;
      return [year, month, day].join('-');
    },
    formatCSV(data, title) {
      const csv = document.createElement('a');
      csv.href = window.URL.createObjectURL(new Blob([data]));
      csv.setAttribute('download', title);
      csv.click();
      this.csv = [];
      this.data = [];
      this.headerPushed = false;
    },
    addData(data, offset, final) {
      const temp = data.split('\n');
      for (let i = 1; i < temp.length; i += 1) {
        if (offset > 0 && this.data[i - 1]) {
          this.data[i - 1].key = this.data[i - 1].key.concat(temp[0].split(',').slice(offset));
          this.data[i - 1].data = this.data[i - 1].data.concat(temp[i].split(',').slice(offset));
        } else {
          this.data.push({ key: temp[0].split(','), data: temp[i].split(',') });
        }
      }
      if (final) {
        this.csv.push(this.headers.join(','));
        this.formatData(`${this.reportType.label}.csv`);
      }
    },
    async addAverage(data, final, finalAllCompanies, company, csvName) {
      const temp = data.split('\n');
      const filter = ['"First Name"', '"Last Name"', '"Store Number"', '"q1"', '"q2"', '"q3"', '"q4"', '"q5"', '"q6"', '"q7"', '"q8"'];
      const keyIndex = [];
      const finalArray = [];
      const finalCounts = [];
      const finalSums = [];
      let avgData = [];
      if (temp.length > 1) {
        let keyData;
        for (let i = 0; i < temp.length; i += 1) {
          if (i !== 0) avgData.push(temp[i].split(','));
          else keyData = (temp[0].split(','));
        }
        // Get header array to join for first col of csv
        keyData.forEach((key, index) => {
          if (filter.indexOf(key) === -1) {
            keyIndex.push(index);
            if (this.averagesHeader.indexOf(keyData[index]) === -1) {
              this.averagesHeader.push(keyData[index]);
            }
          }
        });
        // return only items that match the header above
        avgData.forEach((dataSet) => {
          finalArray.push(dataSet.filter((item, index) => {
            if (keyIndex.indexOf(index) !== -1) return true; return false;
          }));
        });
        avgData = [];
        finalArray[0].forEach(() => {
          avgData.push(0);
        });
        // Swaps true and false to 0/1 to for use in average correct
        finalArray.forEach((dataSet) => {
          const finalDataSet = [];
          dataSet.forEach((element) => {
            if (element === 'true') finalDataSet.push(1);
            else if (element === 'false') finalDataSet.push(0);
            else if (element === '') finalDataSet.push(undefined);
            else finalDataSet.push(element);
          });
          // Add all cols together
          avgData = avgData.map((num, index) => {
            const numberValue = Number(finalDataSet[index]);
            if (Number.isNaN(numberValue)) return num;
            finalCounts[index] = (finalCounts[index] || 0) + 1;
            finalSums[index] = (finalSums[index] || 0) + numberValue;
            return num + numberValue;
          });
        });
        // divide by number of cols
        avgData = avgData.map((sum, index) => {
          const value = sum / finalCounts[index];
          if (Number.isNaN(value)) return '';
          return value.toFixed(2);
        });
        // add to csv
        avgData.forEach((element) => {
          this.averages.push(element);
        });
      } else {
        console.log(temp);
      }
      if (final) {
        if (!this.headerPushed) {
          this.csv.push(this.finalHeader.join(','));
          this.headerPushed = true;
        }
        this.averages.unshift(company);
        this.csv.push(this.averages.join(','));
        this.averages = [];
      }
      if (final && finalAllCompanies) {
        this.csv = this.csv.join('\n');
        this.formatCSV(this.csv, `${csvName}_Averages.csv`);
        this.csv = [];
        this.averages = [];
        this.averagesHeader = [];
      }
    },
    formatData(title) {
      this.data.forEach((row) => {
        const newRow = [];
        let isFrench = false;
        let regEx = /^"q[1-7]"$/;
        // Checking for french website
        for (let i = 0; i < row.data.length; i += 1) {
          if (/^"q8"$/.test(row.key[i])) {
            if (row.data[i] !== '') {
              isFrench = true;
              regEx = /^"q[1-8]"$/;
            }
          }
        }
        for (let i = 0; i < this.headers.length; i += 1) {
          for (let j = 0; j < row.data.length; j += 1) {
            // test to replace questions
            if (this.headers[i] === row.key[j]) {
              if (regEx.test(row.key[j])) {
                const number = (row.key[j].slice(2, 3));
                newRow.push(isFrench ? this.frenchAnswers[number - 1][row.data[j]]
                  : this.answers[number - 1][row.data[j]]);
              } else if (/^"q8/.test(row.key[j]) && !isFrench) {
                newRow.push('N/A');
              } else {
                newRow.push(row.data[j]);
              }
            }
          }
          if (row.key.indexOf(this.headers[i]) === -1) newRow.push('');
        }
        // Check for completely empty rows
        let pushThis = true;
        if (this.reportType.code !== 'company' && this.reportType.code !== 'cAnswers' && this.reportType.code !== 'user' && this.reportType.code !== 'all') {
          // Partially filled excluded
          newRow.forEach((item) => { if (item === '') pushThis = false; });
        } else {
          // Partially filled included
          pushThis = false;
          newRow.forEach((item) => { if (item !== '') pushThis = true; });
        }
        if (pushThis) this.csv.push(newRow.join(','));
      });
      this.csv = this.csv.join('\n');
      this.formatCSV(this.csv, title);
    },
    async getAllCompanyReports() {
      // Get all totals
      for (let i = 0; i < this.options.length; i += 1) {
        const isLast = i === this.options.length - 1;
        // eslint-disable-next-line no-await-in-loop
        await axios({
          method: 'get',
          url: `${process.env.VUE_APP_API}users/scoreReport?company=${this.options[i].code}
          ${this.formatDate(this.from, '&start=')}${this.formatDate(this.to, '&end=')}`,
        }).then(async (Response) => {
          this.addAverage(Response.data, false, false, 'All Retailers');
          await axios({
            method: 'get',
            url: `${process.env.VUE_APP_API}users/summary?company=${this.options[i].code}
          ${this.formatDate(this.from, '&start=')}${this.formatDate(this.to, '&end=')}`,
          })
            .then(async (ResponseTwo) => {
              this.addAverage(ResponseTwo.data, false, false, 'All_Retailers');
              await axios({
                method: 'get',
                url: `${process.env.VUE_APP_API}users/questionCsv?company=${this.options[i].code}
                ${this.formatDate(this.from, '&start=')}${this.formatDate(this.to, '&end=')}`,
                headers: { Authorization: this.userAuth },
              })
                .then((ResponseThree) => {
                  this.addAverage(ResponseThree.data, true, isLast, this.options[i].code, 'All_Retailers');
                }).catch((error) => { console.log(error); });
            }).catch((error) => { console.log(error); });
        }).catch((error) => { console.log(error); });
      }
      this.isGenerating = false;
    },
    async getCSVs() {
      if (this.isValid && !this.isGenerating) {
        this.isGenerating = true;
        /* ------ CSV ------- */
        // User Scores
        axios({
          method: 'get',
          url: `${process.env.VUE_APP_API}users/scoreReport?company=${this.url.code}
        ${this.formatDate(this.from, '&start=')}${this.formatDate(this.to, '&end=')}`,
        })
          .then((response) => {
            if (this.reportType.code !== 'average') this.addData(response.data);
            if (this.reportType.code === 'average' || this.reportType.code === 'all') this.addAverage(response.data);
            // Company Totals
            axios({
              method: 'get',
              url: `${process.env.VUE_APP_API}users/summary?company=${this.url.code}
            ${this.formatDate(this.from, '&start=')}${this.formatDate(this.to, '&end=')}`,
            })
              .then((responseTwo) => {
                if (this.reportType.code !== 'average') this.addData(responseTwo.data, 3);
                if (this.reportType.code === 'average' || this.reportType.code === 'all') this.addAverage(responseTwo.data);
                // User Questions
                axios({
                  method: 'get',
                  url: `${process.env.VUE_APP_API}users/questionCsv?company=${this.url.code}
                  ${this.formatDate(this.from, '&start=')}${this.formatDate(this.to, '&end=')}`,
                  headers: { Authorization: this.userAuth },
                })
                  .then((responseThree) => {
                    if (this.reportType.code !== 'average') this.addData(responseThree.data, 2, true);
                    if (this.reportType.code === 'average' || this.reportType.code === 'all') this.addAverage(responseThree.data, true, true, this.url.code, this.url.code);
                    this.getAllCompanyReports();
                  })
                  .catch((error) => { console.log(error); });
              })
              .catch((error) => { console.log(error); });
          })
          .catch((error) => { console.log(error); });
      }
    },
  },
};
</script>
<style lang="scss">
.datepicker {
  input {
    height: 52px;
    width: 173px;
    border: 1px solid rgba(117, 117, 117, 0.5);
    border-radius: 6px;
    padding: 0 0 0 16px;
    letter-spacing: 0px;
    color: #757575;
    outline: none !important;
  }
}
</style>

<style scoped lang="scss">
.admin-download {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  .description {
    margin-bottom: 4px;
  }
  .url {
    margin-top: 24px;
    .style-chooser {
      width: 543px;
    }
  }
  .date-selector {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 543px;
    margin-top: 20px;
  }
}
</style>
