<template>
  <div class="admin-password">
    <div v-if="!resetPassword">
      <div class="title">
        Reset Password
      </div>
      <div class="description copy" v-if="successful">
        Please check the email you provided for instructions on resetting your password.
      </div>
      <div class="email">
        <div class="description">Email</div>
        <input v-model="email" :class="{'incomplete': !email && submitted}"
          placeholder="john.doe@email.com" class="input" type="text"
        >
      </div>
      <div class="back-to-login">
        <div class="description blue" @click="$emit('navigate', 'login')">Back to login</div>
        <div class="button grey" @click="submit">Send</div>
      </div>
    </div>
    <!-- password reset enter -->
    <div v-else>
      <div class="title">
        Reset Password
      </div>
      <div class="email">
        <div class="description">New password</div>
        <input v-model="password" :class="{'incomplete': !password && submitted}"
          placeholder="New password" class="input" type="password"
        >
      </div>
      <div class="email">
        <div class="description">Confirm password</div>
        <input v-model="confirmPassword" :class="{'incomplete': !confirmPassword && submitted}"
          placeholder="Confirm password" class="input" type="password"
        >
      </div>
      <div class="back-to-login">
        <div class="button grey" @click="submitReset">Reset</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';

export default {
  name: 'AdminPassword',
  components: {},
  props: { resetPassword: Boolean },
  data() {
    return {
      password: null,
      confirmPassword: null,
      submitted: false,
      successful: false,
      email: null,
    };
  },
  computed: {},
  methods: {
    submitReset() {
      if (this.password === this.confirmPassword) {
        axios({
          method: 'post',
          url: `${process.env.VUE_APP_API}admin/passwordRecovery`,
          data: {
            password: this.password,
            code: this.$route.query.code,
          },
        })
          .then(() => {
            this.$router.push(this.$route.path);
            this.$emit('navigate', 'login');
          })
          .catch((error) => { console.log(error); });
      }
    },
    submit() {
      Vue.set(this, 'submitted', true);
      if (this.email !== '') this.passRecover();
    },
    passRecover() {
      axios({
        method: 'get',
        url: `${process.env.VUE_APP_API}admin/passwordRecovery?email=${this.email}`,
      })
        .then(() => {
          Vue.set(this, 'successful', true);
        })
        .catch((error) => { console.log(error); });
    },
  },
};
</script>

<style scoped lang="scss">
.admin-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 75px;
  .title {
    font-size: 20px;
    width: 318px;
    text-align: left;
    margin-bottom: 10px;
  }
  .copy {
    margin-bottom: 25px;
  }
  .description {
    width: 318px;
  }
  .email {
    width: 318px;
    margin-bottom: 20px;
    .input {
      width: 300px;;
    }
  }
  .back-to-login {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 318px;
    .blue {
      color: #4885ED;
      align-self: flex-start;
    }
  }
}
</style>
