<template>
  <div class="panorama-page">
    <div id="panorama" class="panorama-page"></div>
    <div v-if="!loaded" class="loading" :class="{'firstLoad' : firstLoad }">
      <video muted autoplay="true" loop="true">
        <source src="@/assets/Loading.mp4" type="video/mp4" >
      </video>
    </div>
    <img
      @click="resizeList" :class="{'large-checklist' : largeList}"
      class="chrome-checklist" v-if="listCount > 0"
      :src="require(`../assets/images/checklist_${getChecklist}.png`)"
    />
    <div v-if="showClue || largeList" @click="largeList = false" class="modal"></div>
    <ClueComponent
      v-show="loaded"
      v-if="showClue"
      v-on:closeLanding="closeClue"
      v-on:toConclusion="toConclusion"
      v-on:toMinigame="startMinigame"
      v-on:toNextRoom="toNextRoom"
      :language="language"
      :item="item"
      :repeated="repeated"
    />
    <MinigameContainer
      v-if="showMinigame"
      :language="language"
      :minigameType="currentMinigame"
      v-on:gameCompleted="gameCompleted"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import MinigameContainer from '@/components/MinigameContainer.vue';
import ClueComponent from '@/components/ClueComponent.vue';

export default {
  name: 'PanoramaPage',
  props: {
    language: String,
  },
  data() {
    return {
      pannellum: null,
      viewer: null,
      loaded: false, // default = false
      firstLoad: true,
      score: 0,
      minigameScores: [],
      selectedItems: {},
      orderedItems: {
        desk: 0,
        lamp: 1,
        clock: 1,
        safe: 1,
        note: 4,
        art: 4,
        book: 4,
        speaker: 4,
        barrel: 8,
        breaker: 9,
        drive: 10,
      },
      listCount: 0,
      largeList: false,
      // Clue Data
      repeated: false,
      showClue: true,
      item: 'landing1', // landing1
      itemCount: 0,
      dark: false,
      // Minigame Data
      showMinigame: false,
      currentMinigame: '',
      gameCount: 0,
    };
  },
  components: {
    ClueComponent,
    MinigameContainer,
  },
  methods: {
    resizeList() {
      Vue.set(this, 'largeList', !this.largeList);
    },

    toConclusion() {
      Vue.set(this, 'showClue', false);
      this.$emit('nextPage');
    },

    toNextRoom(screen) {
      if (screen === 'postGame1') {
        this.viewer.loadScene('living');
        Vue.set(this, 'item', 'landing2');
      } else {
        this.viewer.loadScene('cellar');
        Vue.set(this, 'item', 'landing3');
      }
    },

    gameCompleted(results) {
      if (this.currentMinigame === 'docDetective') {
        Vue.set(this, 'listCount', this.listCount + 1);
        this.toggleDark();
      }
      Vue.set(this, 'score', this.score + results.score);
      Vue.set(this, 'showMinigame', false);
      Vue.set(this, 'gameCount', this.gameCount + 1);
      Vue.set(this, 'item', `postGame${this.gameCount}`);
      Vue.set(this, 'showClue', true);
      this.minigameScores.push(results);
      this.$emit('playSong', this.getRoomTrackName);
    },

    selectHotSpot(event, args) {
      if (this.correctOrder(args)) { // correct order
        Vue.set(this, 'item', args);
        if (!this.alreadyVisited(args)) { // first time visit
          Vue.set(this.selectedItems, args, true);
          Vue.set(this, 'itemCount', this.itemCount + 1);
          this.incrementList(args);
          if (args === 'barrel') this.toggleDark();
        } else { // visited again
          this.repeatedMinigame(args);
          Vue.set(this, 'repeated', true);
        }
        Vue.set(this, 'showClue', true);
      } else {
        this.setError();
      }
    },

    incrementList(item) {
      switch (item) {
        case 'desk':
        case 'lamp':
        case 'note':
        case 'barrel':
          Vue.set(this, 'listCount', this.listCount + 1);
          break;
        default:
          break;
      }
    },

    repeatedMinigame(item) {
      switch (item) {
        case 'note':
          Vue.set(this, 'item', 'postGame2');
          break;
        case 'breaker':
          Vue.set(this, 'item', 'postGame4');
          break;
        default:
          break;
      }
    },

    correctOrder(item) {
      return (this.orderedItems[item] <= this.itemCount);
    },

    alreadyVisited(item) {
      return (this.selectedItems[item]);
    },

    closeClue() {
      if (this.item === 'lamp') {
        Vue.set(this, 'item', 'postLamp');
      } else if (this.item === 'breaker') {
        Vue.set(this, 'item', 'postBreaker');
        Vue.set(this, 'listCount', this.listCount + 1);
      } else {
        Vue.set(this, 'repeated', false);
        Vue.set(this, 'showClue', false);
      }
    },

    setError() {
      Vue.set(this, 'item', 'error');
      Vue.set(this, 'showClue', true);
    },

    startMinigame(args) { // after minigame pre-screen
      Vue.set(this, 'currentMinigame', args);
      Vue.set(this, 'showMinigame', true);
      Vue.set(this, 'showClue', false);
      this.$emit('playSong', this.currentMinigame);
    },

    toggleDark() {
      Vue.set(this, 'dark', !this.dark);
      this.viewer.loadScene(
        (!this.dark ? 'cellar' : 'cellarDark'),
        this.viewer.getPitch(),
        this.viewer.getYaw(),
        this.viewer.getHfov(),
      );
    },

    changeScene(event, args) {
      if (args === 'living' && this.itemCount === 4) { // to living
        Vue.set(this, 'item', 'roomEnd1');
        Vue.set(this, 'showClue', true);
      } else if (args === 'cellar' && this.itemCount === 8) { // to cellar
        Vue.set(this, 'item', 'roomEnd2');
        Vue.set(this, 'showClue', true);
      } else { // preemptive
        this.setError();
      }
    },
  },

  computed: {
    french() {
      return (this.language === 'french');
    },
    german() {
      return (this.language === 'german');
    },
    getChecklist() {
      if (this.french) return `${this.listCount}_french`;
      if (this.german) return `${this.listCount}_ger`;
      return this.listCount;
    },
    getRoomTrackName() {
      switch (this.currentMinigame) {
        case 'simonSays':
          return 'living';
        case 'slidingPuzzle':
          return 'cellar';
        case 'memoryMatch':
          return 'cellar';
        case 'docDetective':
          return 'finale';
        default:
          return 'cellar';
      }
    },
  },

  beforeDestroy() { // before leaving the panorama page
    this.$emit('updateScore', this.score, this.minigameScores);
  },

  mounted() {
    this.$emit('startGame');
    this.pannellum = window.pannellum;
    this.viewer = this.pannellum.viewer('panorama', {
      autoLoad: true,
      showControls: false,
      basePath: '/',
      minHfov: 100,
      default: {
        firstScene: 'study',
      },
      scenes: {
        study: {
          hfov: 110,
          pitch: 0,
          yaw: 70,
          type: 'equirectangular',
          panorama: 'img/Study.png',
          hotSpots: [
            { // Desk Hot Spot
              pitch: -15.1,
              yaw: 113.9,
              type: 'info',
              cssClass: 'desk hotspot',
              clickHandlerFunc: this.selectHotSpot,
              clickHandlerArgs: 'desk',
            },
            { // Lamp Hot Spot
              pitch: -14.0,
              yaw: -140.5,
              type: 'info',
              cssClass: 'lamp hotspot',
              clickHandlerFunc: this.selectHotSpot,
              clickHandlerArgs: 'lamp',
            },
            { // Clock Hot Spot
              pitch: -2,
              yaw: -126,
              type: 'info',
              cssClass: 'clock hotspot',
              clickHandlerFunc: this.selectHotSpot,
              clickHandlerArgs: 'clock',
            },
            { // Safe Hot Spot
              pitch: -14.5,
              yaw: -61,
              type: 'info',
              cssClass: 'safe hotspot',
              clickHandlerFunc: this.selectHotSpot,
              clickHandlerArgs: 'safe',
            },
            { // Go To Living
              pitch: -28.5,
              yaw: 68.5,
              type: 'scene',
              clickHandlerFunc: this.changeScene,
              clickHandlerArgs: 'living',
              cssClass: 'scenespot-room livingArrow',
            },
          ],
        },
        living: {
          hfov: 110,
          pitch: 0,
          yaw: 70,
          type: 'equirectangular',
          panorama: 'img/Living.png',
          hotSpots: [
            { // Note Hot Spot
              pitch: -11.5,
              yaw: 79.5,
              type: 'info',
              cssClass: 'note hotspot',
              clickHandlerFunc: this.selectHotSpot,
              clickHandlerArgs: 'note',
            },
            { // Speaker Hot Spot
              pitch: -14.5,
              yaw: 175.5,
              type: 'info',
              cssClass: 'speaker hotspot',
              clickHandlerFunc: this.selectHotSpot,
              clickHandlerArgs: 'speaker',
            },
            { // Art Hot Spot
              pitch: 7,
              yaw: -73,
              type: 'info',
              cssClass: 'art hotspot',
              clickHandlerFunc: this.selectHotSpot,
              clickHandlerArgs: 'art',
            },
            { // Book Hot Spot
              pitch: -19,
              yaw: -20.5,
              type: 'info',
              cssClass: 'book hotspot',
              clickHandlerFunc: this.selectHotSpot,
              clickHandlerArgs: 'book',
            },
            { // Go To Cellar
              pitch: -27,
              yaw: -121.5,
              type: 'scene',
              clickHandlerFunc: this.changeScene,
              clickHandlerArgs: 'cellar',
              cssClass: 'scenespot-room cellarArrow',
            },
          ],
        },
        cellar: {
          hfov: 110,
          pitch: 0,
          yaw: 150,
          type: 'equirectangular',
          panorama: 'img/Cellar.png',
          hotSpots: [
            { // Barrel Hot Spot
              pitch: -20.5,
              yaw: -166.7,
              type: 'info',
              cssClass: 'barrel hotspot',
              clickHandlerFunc: this.selectHotSpot,
              clickHandlerArgs: 'barrel',
            },
            { // Breaker Hot Spot
              pitch: 0,
              yaw: 111.5,
              type: 'info',
              cssClass: 'breaker hotspot',
              clickHandlerFunc: this.selectHotSpot,
              clickHandlerArgs: 'breaker',
            },
            { // Thumb Drive Hot Spot
              pitch: -23.5,
              yaw: 40.5,
              type: 'info',
              cssClass: 'drive hotspot',
              clickHandlerFunc: this.selectHotSpot,
              clickHandlerArgs: 'drive',
            },
          ],
        },
        cellarDark: {
          hfov: 110,
          pitch: 0,
          yaw: 150,
          type: 'equirectangular',
          panorama: 'img/CellarDark.png',
          hotSpots: [
            { // Barrel Hot Spot
              pitch: -20.5,
              yaw: -166.7,
              type: 'info',
              cssClass: 'barrel hotspot',
              clickHandlerFunc: this.selectHotSpot,
              clickHandlerArgs: 'barrel',
            },
            { // Breaker Hot Spot
              pitch: 0,
              yaw: 111.5,
              type: 'info',
              cssClass: 'breaker hotspot',
              clickHandlerFunc: this.selectHotSpot,
              clickHandlerArgs: 'breaker',
            },
            { // Thumb Drive Hot Spot
              pitch: -23.5,
              yaw: 40.5,
              type: 'info',
              cssClass: 'drive hotspot',
              clickHandlerFunc: this.selectHotSpot,
              clickHandlerArgs: 'drive',
            },
          ],
        },
      },
    });

    this.viewer.on('load', () => {
      Vue.set(this, 'loaded', true);
      Vue.set(this, 'firstLoad', false);
      document.querySelectorAll('.livingArrow').forEach((ele) => {
        const x = ele;
        if (this.french) {
          x.style.background = 'url(../img/floor_nav_livingroom_french.png) no-repeat';
        } else if (this.german) {
          x.style.background = 'url(../img/floor_nav_livingroom_ger.png) no-repeat';
        } else {
          x.style.background = 'url(../img/floor_nav_livingroom.png) no-repeat';
        }
      });
      document.querySelectorAll('.cellarArrow').forEach((ele) => {
        const x = ele;
        if (this.french) {
          x.style.background = 'url(../img/floor_nav_cellar_french.png) no-repeat';
        } else if (this.german) {
          x.style.background = 'url(../img/floor_nav_cellar_ger.svg) no-repeat';
        } else {
          x.style.background = 'url(../img/floor_nav_cellar.png) no-repeat';
        }
      });
    });

    this.viewer.on('scenechange', () => { Vue.set(this, 'loaded', false); });
    this.$emit('playSong', 'study');
  },
};
</script>

<style lang="scss">
#appTimer {
  font-size: 24px;
  position: absolute;
  top: 15px;
  left: 35px;
  background-color: #E9A2AD;
  border: 2px solid #D3455B;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 14px;
  padding-right: 14px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
#appTimerContainer {
  height: 80px;
}

.panorama-page {
  width: 100%;
  height: 100%;

  .chrome-checklist {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 200px;
  }

  .large-checklist {
    transform: translate(-50%, -50%);
    z-index: 1;
    top: 50%;
    left: 50%;
    width: 450px;
  }

  .modal {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background-color: black;
    opacity: 0.5;
  }
  .pnlm-about-msg {
    visibility: hidden;
  }

  .firstLoad {
    width: 100% !important;
    height: 100% !important;
  }

  .loading {
    width: 300px;
    height: 200px;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: 9999;
    border-radius: 6px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 13px #0000000D;
    border: 1px solid #9A9FA44D;
    video {
      width: 50px;
      position: inherit;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
  }

  .scenespot-room {
    width: 120px;
    height: 75px;
  }

  // Study Objects
  .safe {
    width: 180px;
    height: 150px;
  }

  .lamp {
    width: 90px;
    height: 160px;
  }

  .clock {
    width: 135px;
    height: 375px;
  }

  .desk {
    width: 400px;
    height: 360px;
  }
  // Living Objects
  .book {
    width: 90px;
    height: 90px;
  }

  .art {
    width: 260px;
    height: 215px;
  }

  .speaker {
    width: 125px;
    height: 310px;
  }

  .note {
    width: 50px;
    height: 50px;
  }

  // Cellar Objects
  .barrel {
    width: 200px;
    height: 200px;
  }

  .breaker {
    width: 215px;
    height: 275px;
  }

  .drive {
    width: 60px;
    height: 60px;
  }

  .ctrl {
    width: 96px;
    height: 96px;
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    box-shadow: 0px 0px 15px #0000001A;
  }

  .ctrl:active {
    path:not(:first-child) {
      fill: #4885ed;
    }
  }

  .position {
    position: absolute;
    width: 137px;
    height: 136px;
    top: 50px;
    right: 127px;

    #pan-up{
      transform: translate(50%, 0%) rotate(270deg);
      top: 0;
      right: 50%;
    }
    #pan-down{
      transform: translate(50%, 0%) rotate(90deg);
      bottom: 0;
      right: 50%;
    }
    #pan-left{
      transform: translate(0%, -50%) rotate(180deg);
      top: 50%;
      left: 0;
    }
    #pan-right{
      transform: translate(0%, -50%);
      top: 50%;
      right: 0;
    }
  }

  .zoom {
    position: absolute;
    height: 112px;
    width: 52px;
    top: 62px;
    right: 49px;

    .plus {
      transform: translate(-50%, 0);
      left: 50%;
      top: 0;
    }
    .minus {
      transform: translate(-50%, 0);
      left: 50%;
      bottom: 0;
    }
  }
}

</style>
