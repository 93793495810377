<template>
    <div id="audioManagerContainer">
    </div>
</template>

<script>
export default {
  name: 'AudioManager',
  props: {
    language: String,
  },
  data() {
    return {
      songAudio: null,
      voiceoverAudio: null,
    };
  },
  computed: {
    french() {
      return (this.language === 'french');
    },
    german() {
      return (this.language === 'german');
    },
  },
  methods: {
    playSong(trackName, isLooped) {
      if (this.songAudio === null) {
        this.songAudio = new Audio();
      }
      this.songAudio.pause();
      this.songAudio.src = `/audio/music/${trackName}.mp3`;
      this.songAudio.loop = isLooped; // Conditionally though
      if (this.songAudio.currentTime !== 0) {
        this.songAudio.currentTime = 0;
      }
      if (trackName === 'theme' || trackName === 'finale') {
        this.songAudio.volume = 0.2;
      } else {
        this.songAudio.volume = 0.5;
      }
      this.songAudio.play();
    },
    playVoiceover(voiceoverName) {
      if (!this.french && !this.german) {
        if (this.voiceoverAudio === null) {
          this.voiceoverAudio = new Audio();
        }
        this.voiceoverAudio.pause();
        this.voiceoverAudio.src = `/audio/voiceover/${voiceoverName}.mp3`;
        if (this.voiceoverAudio.currentTime !== 0) {
          this.voiceoverAudio.currentTime = 0;
        }
        this.voiceoverAudio.play();
      }
    },
    pauseAudio() {
      this.songAudio.pause();
      if (!this.french && !this.german) {
        this.voiceoverAudio.pause();
      }
    },
    pauseVoiceover() {
      if (!this.french && !this.german) {
        this.voiceoverAudio.pause();
      }
    },
    resume() {
      this.songAudio.play();
    },
    toggleMute() {
      this.songAudio.muted = !this.songAudio.muted;
      if (!this.french && !this.german) {
        this.voiceoverAudio.muted = !this.voiceoverAudio.muted;
      }
    },
  },
  mounted() {
  },
};
</script>
<style scoped>

</style>
