<template>
  <div class="default-container">
    <div class="large-title" v-if='section !== "beginning"'>
      <span class="blue">{{translate('ending1')}}</span> {{translate('ending2')}}
    </div>
    <div class="large-title" v-else>
      <span class="blue">{{translate('beginning1')}}</span>
    </div>
    <div v-if="!submitted">
      <div class="description">
        {{
          section === 'beginning' ? translate('questionOne') :
          section === 'tutorial' ? translate('questionTwo') :
          translate('questionThree')
        }}
      </div>
      <div class="selection-container">
        <div class="selection" v-for="index in 10" :key="index" >
          <div class="number">{{index}}</div>
          <div class="bubble-container">
            <BubbleSelected v-if="selection === index" class="bubble"/>
            <Bubble v-else  @click="selectAnswer(index)" class="bubble"/>
          </div>
        </div>
      </div>
      <div @click="submit" class="button grey">
        {{
          french ? 'Envoyer' : ( german ? 'Einreichen' : 'Submit' )
        }}
      </div>
    </div>
    <div v-else class="buttons" >
      <div class="button white" @click="resetGame">
        {{
          french ? 'Réessayer' : ( german ? 'Spiele es nochmal' : 'Try again' )
        }}
      </div>
      <div class="button grey" @click="toLink">
        {{
          french ? 'En savoir plus' : ( german ? 'Lerne mehr' : 'Learn more' )
        }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Bubble from '@/assets/images/quiz_bubble_neutral.svg';
import BubbleSelected from '@/assets/images/quiz_bubble_selected.svg';

export default {
  name: 'SurveyComponent',
  components: {
    Bubble,
    BubbleSelected,
  },
  props: {
    language: String,
    section: String,
  },
  data() {
    return {
      selection: null,
      submitted: false,
      text: {
        beginning1: {
          english: 'Welcome!',
          french: 'Accueillir!',
          german: 'Willkommen!',
        },
        ending1: {
          english: 'Thanks',
          french: 'Merci',
          german: 'Vielen Dank',
        },
        ending2: {
          english: 'for playing!',
          french: 'd’avoir joué !',
          german: 'fürs Spielen !',
        },
        questionOne: {
          english: 'Before we get started please answer this question. On a scale of 1-10, how likely are you to recommend Chromebook',
          french: 'Avant de commencer, veuillez répondre à cette question. Sur une échelle de 1 à 10, quelle est la probabilité que vous recommandiez Chromebook ?',
          german: 'Bevor wir loslegen, beantworte bitte folgende Frage. Auf einer Skala von 1 bis 10, wie wahrscheinlich ist es, dass du Chromebooks weiterempfehlen wirst?',
        },
        questionTwo: {
          english: 'On a scale of 1-10, how likely would you recommend this training to a friend or colleague?',
          french: 'Sur une échelle de 1 à 10, quelle est la probabilité que vous recommandiez cette formation à un ami ou un collègue ?',
          german: 'Auf einer Skala von 1 bis 10, wie wahrscheinlich würdest du dieses Training einem Freund oder Kollegen empfehlen?',
        },
        questionThree: {
          english: 'After completing this training, on a scale of 1-10, how likely are you to recommend Chromebook?',
          french: 'Après avoir suivi cette formation, sur une échelle de 1 à 10, quelle est la probabilité que vous recommandiez Chromebook ?',
          german: 'Auf einer Skala von 1 bis 10, wie wahrscheinlich würdest du im Anschluss an dieses Training Chromebook empfehlen?',
        },
      },
    };
  },
  methods: {
    translate(key) {
      if (this.french) return this.text[key].french;
      if (this.german) return this.text[key].german;
      return this.text[key].english;
    },
    selectAnswer(index) {
      if (!this.answered) {
        Vue.set(this, 'selection', index);
      }
    },
    submit() {
      if (this.selection) {
        Vue.set(this, 'submitted', true);
        this.$emit('surveyComplete', this.selection);
        if (this.section !== 'tutorial') {
          this.$emit('nextPage');
        }
      }
    },
    toLink() {
      if (this.french) {
        window.open('https://www.google.fr/intl/fr_fr/chromebook/switch/');
      } else if (this.german) {
        window.open('https://www.google.de/intl/de_de/chromebook/');
      } else {
        window.open('https://www.google.com/chromebook/switch/');
      }
    },
    resetGame() {
      this.$emit('resetGame');
    },
  },
  computed: {
    french() {
      return (this.language === 'french');
    },
    german() {
      return (this.language === 'german');
    },
  },
};
</script>

<style scoped lang="scss">
.default-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .large-title {
    margin-bottom: 57px;
  }
  .description {
    text-align: center !important;
    margin-bottom: 37px;
  }
  .button {
    width: 108px;
    margin: auto;
  }
  .selection-container {
    display: flex;
    margin-bottom: 25px;
    justify-content: center;
    .selection {
      color: #757575;
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .number {
        margin-right: 10px;
      }
      .bubble-container {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        .bubble {
          width: 100%;
          height: 100%;
          position: relative;
          top: -15px;
        }

        &:hover .hover {
          opacity: 0.1;
        }
      }
    }
  }
  .buttons{
    display: flex;
    justify-content: center;
    .white {
      margin-right: 15px;
      width: 135px !important;
      padding-right: 5px;
      padding-left: 5px;
    }
    .button {
      width: 122px;
    }
  }
}
</style>
