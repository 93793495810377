<template>
<div id="memoryGameContainer">
  <MinigameHeader @giveUp="giveUp()" id="memoryHeader"
  ref="memoryHeader" :language="this.language"/>
  <div id="memoryBoardContainer">
      <!-- <div id="introText"> Memorize and match the cards below. </div> -->
      <div class="cards">
          <MemoryCard
          class="card"
          v-for="cardNumber in numberOfCards"
          :key="cardNumber"
          ref="cards"
          :cardNumber="cardNumber"
          :initialValue="getCardValue(cardNumber)"
          />
      </div>
  </div>
  <div id="matchModal" v-if="showMatchModal">
    <h4 id="modalTitle">{{this.modalTitle}}</h4>
    <div id="matchText">{{this.modalText}}</div>
    <div id="closeModalButton" @click="closeModal()">
      {{
        isFrench() ? "Continuer" : (isGerman() ? "Weiter" : "Continue" )
      }}
    </div>
  </div>
</div>
</template>

<script>
import MemoryCard from '@/components/MemoryCard.vue';
import MinigameHeader from '@/components/MinigameHeader.vue';
import matchData from '../assets/data/matchData.json';

export default {
  name: 'MemoryBoard',
  components: {
    MemoryCard,
    MinigameHeader,
  },
  props: {
    numberOfCards: Number,
    language: String,
  },
  data() {
    return {
      introActive: true,
      cardOrder: [],
      currentCardValue: -1,
      currentCardNumber: -1,
      currentScore: 0,
      showMatchModal: false,
      modalTitle: '',
      modalText: '',
    };
  },
  methods: {
    generateCards() {
    },
    getCardValue(cardNumber) {
      if (this.isEven(cardNumber)) {
        return cardNumber - 1;
      }
      return cardNumber;
    },
    shuffle() {
      const values = [];
      this.$refs.cards.forEach((card) => {
        values.push(card.getValue());
      });
      this.$refs.cards.forEach((card) => {
        const randomIndex = this.getRandomIndex(values.length);
        card.setValue(values[randomIndex]);
        values.splice(randomIndex, 1);
      });
    },
    handleFlip(cardValue, cardNumber) {
      if (this.currentCardValue === cardValue) {
        this.currentScore += 1;
        this.showMatchText(this.currentCardValue);
        const newCard = this.$refs.cards.find(card => card.cardNumber === cardNumber);
        const oldCard = this.$refs.cards.find(card => card.cardNumber === this.currentCardNumber);
        setTimeout(() => this.removeSelected(newCard, oldCard), 800);
        this.currentCardValue = -1;
        this.currentCardNumber = -1;
      } else if (this.currentCardValue !== -1) {
        const newCard = this.$refs.cards.find(card => card.cardNumber === cardNumber);
        const oldCard = this.$refs.cards.find(card => card.cardNumber === this.currentCardNumber);
        setTimeout(() => this.resetSelected(newCard, oldCard), 800);
        this.currentCardValue = -1;
        this.currentCardNumber = -1;
      } else {
        this.currentCardValue = cardValue;
        this.currentCardNumber = cardNumber;
      }
    },
    resetSelected(newCard, oldCard) {
      newCard.unflipCard();
      oldCard.unflipCard();
    },
    removeSelected(newCard, oldCard) {
      newCard.setComplete();
      oldCard.setComplete();
    },
    isEven(value) {
      return (value % 2 === 0);
    },
    isFrench() {
      return (this.language === 'french');
    },
    isGerman() {
      return (this.language === 'german');
    },
    getRandomIndex(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    hideIntro() {
      this.$refs.memoryHeader.start();
      this.introActive = false;
    },
    completeMemoryMatch() {
      this.$refs.memoryHeader.stop();
      const elapsedTime = this.$refs.memoryHeader.getTimeElapsed();
      let finalScore;
      if (elapsedTime < 60) {
        finalScore = 100;
      } else if (elapsedTime < 120) {
        finalScore = 50;
      } else {
        finalScore = 25;
      }
      setTimeout(() => this.$emit('completeMemory', finalScore), 400);
    },
    giveUp() {
      this.$emit('completeMemory', 0);
    },
    showMatchText(cardValue) {
      setTimeout(() => this.setMatchModalText(cardValue), 500);
    },
    closeModal() {
      this.showMatchModal = false;
      this.$refs.memoryHeader.start();
      if (this.currentScore >= this.numberOfCards / 2) {
        this.completeMemoryMatch();
      }
    },
    setMatchModalText(cardValue) {
      if (this.isFrench()) {
        this.modalTitle = matchData[cardValue].frenchTitle;
        this.modalText = matchData[cardValue].frenchText;
        this.showMatchModal = true;
        this.$refs.memoryHeader.stop();
      } else if (this.isGerman()) {
        this.modalTitle = matchData[cardValue].germanTitle;
        this.modalText = matchData[cardValue].germanText;
        this.showMatchModal = true;
        this.$refs.memoryHeader.stop();
      } else {
        this.modalTitle = matchData[cardValue].title;
        this.modalText = matchData[cardValue].text;
        this.showMatchModal = true;
        this.$refs.memoryHeader.stop();
      }
    },
  },
  mounted() {
    this.$refs.memoryHeader.disableGiveUp = true;
    this.currentCardValue = -1;
    this.currentCardNumber = -1;
    this.shuffle();
    let introFlipDelay = 0;
    this.$refs.cards.forEach((card) => {
      introFlipDelay += 300;
      setTimeout(() => card.introFlip(), introFlipDelay);
    });
    setTimeout(() => this.hideIntro(), 13000);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#introText {
  font-size: 24px;
}
#memoryBoardContainer {
  border-radius: 8px;
  width: 450px;
  margin: 0 auto;
  height: 450px;
}
.cards {
  // display: grid;
  // grid-template-columns: repeat(4, 1fr);
  // grid-auto-rows: 115px;
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  flex-wrap: wrap;
  width: 460px;
  height: 580px;
  margin: 10px auto;
  position: relative;
}
.card {
  flex-grow: 0;
  flex-shrink: 0;
  width: 100px;
  height: 100px;
}
#memoryHeader {
  width: 480px;
  margin: 0 auto;
  position: relative;
  left: 10px;
  top: 5px;
}
#matchModal {
  width: 350px;
  margin: 0 auto;
  position: absolute;
  top: 46%;
  left: 50%;
  margin-left: -227px;
  margin-top: -100px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: white;
  border-radius: 8px;
}
#closeModalButton {
    background-color: grey;
    padding: 15px 20px;
    width: 80px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
    border-radius: 8px;
    color: white;
}
#closeModalButton:hover {
  background: #4885ED 0% 0% no-repeat padding-box;;
  cursor: pointer;
}
#matchText {
  width: 380px;
  position: relative;
  left: -15px;
  top: -15px;
  overflow-x: hidden;
  color: #757575;
  margin-bottom: 15px;
}
#modalTitle {
  font-size: 24px;
  color: #757575;
  text-align: center;
}
</style>
