<template>
  <div class="admin-login">
    <div class="email">
      <div class="description">Email</div>
      <input v-model="email" :class="{'incomplete': (!email && submitted) || error}"
        placeholder="john.doe@email.com" class="input" type="text"
      >
    </div>
    <div class="password">
      <div class="description">Password</div>
      <div class="pass-hide-container">
        <input v-model="password" :class="{'incomplete': (!password && submitted) || error}"
          placeholder="••••••••••••••••" class="input"
          :type="showPassword ? 'text' : 'password'"
        >
        <HideIcon class="pass-icon" v-if="showPassword" @click="togglePass"/>
        <ShowIcon class="pass-icon" v-else @click="togglePass"/>
      </div>
    </div>
    <div class="forgot-password">
      <div class="description blue" @click="toPassReset">Forgot password?</div>
      <div class="button grey" @click="submit">Sign In</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import HideIcon from '@/assets/images/icon_hide.svg';
import ShowIcon from '@/assets/images/icon_show.svg';

export default {
  name: 'AdminLogin',
  components: {
    HideIcon,
    ShowIcon,
  },
  data() {
    return {
      email: null,
      password: null,
      submitted: false,
      auth: null,
      showPassword: false,
      error: false,
      superEmail: 'breelle.biondo@24g.com',
    };
  },
  computed: {},
  methods: {
    toPassReset() {
      this.$emit('navigate', 'password');
    },
    togglePass() {
      Vue.set(this, 'showPassword', !this.showPassword);
    },
    submit() {
      Vue.set(this, 'error', false);
      Vue.set(this, 'submitted', true);
      if (this.email !== null && this.password !== null) {
        this.postAuth();
      }
    },
    postAuth() {
      axios({
        method: 'post',
        url: `${process.env.VUE_APP_API}admin/auth`,
        data: {
          email: this.email,
          password: this.password,
        },
      })
        .then((response) => {
          if (this.email === this.superEmail) {
            this.$emit('loginAlt', response.data.data.token);
          } else {
            this.$emit('login', response.data.data.token);
          }
        })
        .catch((error) => {
          Vue.set(this, 'error', true);
          console.log(error);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.admin-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 75px;
  .password {
    margin: 20px 0;
    .pass-hide-container {
      width: 100%;
      position: relative;
      .pass-icon {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: 15px;
        width: 25px;
      }
    }
  }
  .forgot-password {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 318px;
    .blue {
      color: #4885ED;
      align-self: flex-start;
    }
  }
}
</style>
